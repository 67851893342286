export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const USER_LOADED_SUCCESS = 'USER_LOADED_SUCCESS';
export const USER_LOADED_FAIL = 'USER_LOADED_FAIL';
export const AUTHENTICATED_SUCCESS = 'AUTHENTICATED_SUCCESS';
export const AUTHENTICATED_FAIL = 'AUTHENTICATED_FAIL';
export const PASSWORD_RESET_FAIL = 'PASSWORD_RESET_FAIL';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_CONFIRM_FAIL = 'PASSWORD_RESET_CONFIRM_FAIL';
export const PASSWORD_RESET_CONFIRM_SUCCESS = 'PASSWORD_RESET_CONFIRM_SUCCESS';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';
export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS';
export const ACTIVATION_FAIL = 'ACTIVATION_FAIL';
export const GOOGLE_AUTH_SUCCESS = 'GOOGLE_AUTH_SUCCESS';
export const GOOGLE_AUTH_FAIL = 'GOOGLE_AUTH_FAIL';
export const LOGOUT = 'LOGOUT';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAIL = 'FETCH_USERS_FAIL';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';
export const CREATE_GROUP_FAIL = 'CREATE_GROUP_FAIL';
export const ADD_USER_TO_GROUP_SUCCESS = 'ADD_USER_TO_GROUP_SUCCESS';
export const ADD_USER_TO_GROUP_FAIL = 'ADD_USER_TO_GROUP_FAIL';
export const FETCH_GROUPS_SUCCESS = 'FETCH_GROUPS_SUCCESS';
export const FETCH_GROUPS_FAIL = 'FETCH_GROUPS_FAIL';
export const FETCH_MOCK_DATA_SUCCESS = 'FETCH_MOCK_DATA_SUCCESS';
export const FETCH_MOCK_DATA_FAIL = 'FETCH_MOCK_DATA_FAIL';
export const REMOVE_USER_GROUP_SUCCESS = 'REMOVE_USER_GROUP_SUCCESS';
export const REMOVE_USER_GROUP_FAIL = 'REMOVE_USER_GROUP_FAIL';
export const CHECKOUT_SESSION_SUCCESS = 'CHECKOUT_SESSION_SUCCESS';
export const CHECKOUT_SESSION_FAIL = 'CHECKOUT_SESSION_FAIL';
export const GET_CHECKOUT_SESSION_SUCCESS = 'GET_CHECKOUT_SESSION_SUCCESS';
export const GET_CHECKOUT_SESSION_FAIL = 'GET_CHECKOUT_SESSION_FAIL';
export const SET_USER_CUSTOMER_ID_SUCCESS = 'SET_USER_CUSTOMER_ID_SUCCESS';
export const SET_USER_CUSTOMER_ID_FAIL = 'SET_USER_CUSTOMER_ID_FAIL';
export const RETRIEVE_STRIPE_CUSTOMER_INFO_SUCCESS = 'RETRIEVE_STRIPE_CUSTOMER_INFO_SUCCESS';
export const RETRIEVE_STRIPE_CUSTOMER_INFO_FAIL = 'RETRIEVE_STRIPE_CUSTOMER_INFO_FAIL';
export const CREATE_BILLING_PORTAL_SUCCESS = 'CREATE_BILLING_PORTAL_SUCCESS';
export const CREATE_BILLING_PORTAL_FAIL = 'CREATE_BILLING_PORTAL_FAIL';
export const ADD_TO_MAILING_LIST_SUCCESS = 'ADD_TO_MAILING_LIST_SUCCESS';
export const ADD_TO_MAILING_LIST_FAIL = 'ADD_TO_MAILING_LIST_FAIL';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';
export const CHECK_EMAIL_AVAILABILITY_SUCCESS = 'CHECK_EMAIL_AVAILABILITY_SUCCESS';
export const CHECK_EMAIL_AVAILABILITY_FAIL = 'CHECK_EMAIL_AVAILABILITY_FAIL';
export const DELETE_PROFILE_PICTURE_SUCCESS = 'DELETE_PROFILE_PICTURE_SUCCESS';
export const DELETE_PROFILE_PICTURE_FAIL = 'DELETE_PROFILE_PICTURE_FAIL';
export const FETCH_BLOG_POSTS_SUCCESS = 'FETCH_BLOG_POSTS_SUCCESS';
export const FETCH_BLOG_POSTS_FAIL = 'FETCH_BLOG_POSTS_FAIL';
export const FETCH_BLOG_POST_SUCCESS = 'FETCH_BLOG_POST_SUCCESS';
export const FETCH_BLOG_POST_FAIL = 'FETCH_BLOG_POST_FAIL';
export const CREATE_BLOG_POST_SUCCESS = 'CREATE_BLOG_POST_SUCCESS';
export const CREATE_BLOG_POST_FAIL = 'CREATE_BLOG_POST_FAIL';
export const DELETE_BLOG_POST_SUCCESS = 'DELETE_BLOG_POST_SUCCESS';
export const DELETE_BLOG_POST_FAIL = 'DELETE_BLOG_POST_FAIL';