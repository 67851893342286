import React, { memo } from "react";
import { Bar } from "react-chartjs-2";

export const TopGrowthComparison = memo(
  ({ rankingsData, selectedLocationId }) => {
    if (!rankingsData?.population?.data) return null;

    const data = {
      labels: rankingsData.population.data.map((item) => {
        // For neighborhoods, show just the neighborhood name without city/county
        const name = item.name.split(",")[0];
        return name;
      }),
      datasets: [
        {
          label: "Population",
          data: rankingsData.population.data.map((item) =>
            parseFloat(item.value)
          ),
          backgroundColor: rankingsData.population.data.map((item) =>
            item.id.trim() === selectedLocationId.trim()
              ? "rgba(75, 192, 192, 0.8)"
              : "rgba(75, 192, 192, 0.4)"
          ),
          borderColor: rankingsData.population.data.map((item) =>
            item.id.trim() === selectedLocationId.trim()
              ? "rgba(75, 192, 192, 1)"
              : "rgba(75, 192, 192, 0.6)"
          ),
          borderWidth: rankingsData.population.data.map((item) =>
            item.id.trim() === selectedLocationId.trim() ? 2 : 1
          ),
        },
      ],
    };

    return (
      <Bar
        data={data}
        options={{
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: "Population",
              },
            },
            x: {
              ticks: {
                font: {
                  weight: (ctx) => {
                    const index = ctx.index;
                    const locationId =
                      rankingsData.population.data[index]?.id.trim();
                    return locationId === selectedLocationId.trim()
                      ? "bold"
                      : "normal";
                  },
                },
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: (context) => {
                  const value = context.raw;
                  return `Population: ${value.toLocaleString()}`;
                },
              },
            },
            datalabels: {
              display: false, // Explicitly disable data labels
            },
          },
          animation: false,
          maintainAspectRatio: true,
          aspectRatio: 1.5,
        }}
      />
    );
  }
);

export const MedianIncomeComparison = memo(
  ({ rankingsData, selectedLocationId }) => {
    if (!rankingsData?.medianHouseholdIncome?.data) return null;

    const data = {
      labels: rankingsData.medianHouseholdIncome.data.map((item) => {
        // For neighborhoods, show just the neighborhood name without city/county
        const name = item.name.split(",")[0];
        return name;
      }),
      datasets: [
        {
          label: "Median Household Income",
          data: rankingsData.medianHouseholdIncome.data.map((item) =>
            parseFloat(item.value)
          ),
          backgroundColor: rankingsData.medianHouseholdIncome.data.map((item) =>
            item.id.trim() === selectedLocationId.trim()
              ? "rgba(255, 159, 64, 0.8)"
              : "rgba(255, 159, 64, 0.4)"
          ),
          borderColor: rankingsData.medianHouseholdIncome.data.map((item) =>
            item.id.trim() === selectedLocationId.trim()
              ? "rgba(255, 159, 64, 1)"
              : "rgba(255, 159, 64, 0.6)"
          ),
          borderWidth: rankingsData.medianHouseholdIncome.data.map((item) =>
            item.id.trim() === selectedLocationId.trim() ? 2 : 1
          ),
        },
      ],
    };

    return (
      <Bar
        data={data}
        options={{
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: "Median Household Income ($)",
              },
              ticks: {
                callback: (value) => `$${value.toLocaleString()}`,
              },
            },
            x: {
              ticks: {
                font: {
                  weight: (ctx) => {
                    if (!ctx || typeof ctx.index !== "number") return "normal";
                    const index = ctx.index;
                    const data = rankingsData.medianHouseholdIncome.data[index];
                    return data && data.id.trim() === selectedLocationId.trim()
                      ? "bold"
                      : "normal";
                  },
                },
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: (context) => {
                  const value = context.raw;
                  return `Median Household Income: ${
                    rankingsData.medianHouseholdIncome.data[context.dataIndex]
                      .formattedValue
                  }`;
                },
              },
            },
            datalabels: {
            display: false, // Explicitly disable data labels
          },
          },
          animation: false,
          maintainAspectRatio: true,
          aspectRatio: 1.5,
        }}
      />
    );
  }
);

export const DynamicComparisonOverview = ({
  rankingsData,
  selectedLocationId,
}) => {
  if (!rankingsData || !selectedLocationId) {
    return <div>No comparison data available</div>;
  }

  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <style jsx global>{`
        :root {
          --summary-title-size: 1.5vh;
          --summary-item-size: 1.5vh;
          --summary-value-size: 1.5vh;
        }

        @media (min-width: 768px) {
          :root {
            --summary-title-size: 1.5vh;
            --summary-item-size: 1.5vh;
            --summary-value-size: 1.5vh;
          }
        }
      `}</style>

      <div className="grid grid-cols-1 gap-4">
        {/* Population Comparison */}
        <div className="bg-gray-100 p-4 rounded-lg">
          <h3 className="text-xl font-semibold mb-2">Population Comparison</h3>
          <TopGrowthComparison
            rankingsData={rankingsData}
            selectedLocationId={selectedLocationId}
          />
        </div>

        {/* Income Comparison */}
        <div className="bg-gray-100 p-4 rounded-lg">
          <h3 className="text-xl font-semibold mb-2">Income Comparison</h3>
          <MedianIncomeComparison
            rankingsData={rankingsData}
            selectedLocationId={selectedLocationId}
          />
        </div>
      </div>
    </div>
  );
};
// Market mode versions of the comparison charts
export const MarketModeTopGrowthComparison = memo(
  ({ rankingsData, selectedLocationId }) => {
    if (!rankingsData?.population?.data) return null;

    const data = {
      labels: rankingsData.population.data.map((item) => {
        // For neighborhoods, show just the neighborhood name without city/county
        const name = item.name.split(",")[0];
        return name;
      }),
      datasets: [
        {
          data: rankingsData.population.data.map((item) =>
            parseFloat(item.value)
          ),
          backgroundColor: rankingsData.population.data.map((item) =>
            item.id.trim() === selectedLocationId.trim()
              ? "rgba(75, 192, 192, 0.8)"
              : "rgba(75, 192, 192, 0.4)"
          ),
        },
      ],
    };

    return <MarketModeBarChart data={data} selectedId={selectedLocationId} />;
  }
);

export const MarketModeMedianIncomeComparison = memo(
  ({ rankingsData, selectedLocationId }) => {
    if (!rankingsData?.medianHouseholdIncome?.data) return null;

    const data = {
      labels: rankingsData.medianHouseholdIncome.data.map((item) => {
        // For neighborhoods, show just the neighborhood name without city/county
        const name = item.name.split(",")[0];
        return name;
      }),
      datasets: [
        {
          data: rankingsData.medianHouseholdIncome.data.map((item) =>
            parseFloat(item.value)
          ),
          backgroundColor: rankingsData.medianHouseholdIncome.data.map((item) =>
            item.id.trim() === selectedLocationId.trim()
              ? "rgba(255, 159, 64, 0.8)"
              : "rgba(255, 159, 64, 0.4)"
          ),
        },
      ],
    };

    return (
      <MarketModeBarChart
        data={data}
        selectedId={selectedLocationId}
        formatValue={(value) => `$${value.toLocaleString()}`}
      />
    );
  }
);

const MarketModeBarChart = memo(
  ({ data, selectedId, formatValue = (value) => value.toLocaleString() }) => {
    const chartWidth = 400;
    const chartHeight = 200;
    const margins = { top: 20, right: 20, bottom: 50, left: 60 }; // Increased left margin for currency values
    const width = chartWidth - margins.left - margins.right;
    const height = chartHeight - margins.top - margins.bottom;

    const values = data.datasets[0].data;
    const maxValue = Math.max(...values);
    const barWidth = (width / values.length) * 0.8;
    const barSpacing = (width / values.length) * 0.2;

    // Calculate appropriate y-axis ticks
    const getYAxisTicks = () => {
      const step = Math.ceil(maxValue / 4);
      return [0, step, step * 2, step * 3, Math.ceil(maxValue)];
    };

    return (
      <div className="flex flex-col items-center w-full">
        <div
          className="mt-6 flex justify-center"
          style={{
            marginTop: "12px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <svg viewBox={`0 0 ${chartWidth} ${chartHeight}`}>
            <g transform={`translate(${margins.left}, ${margins.top})`}>
              {/* Y-axis */}
              <line x1="0" y1="0" x2="0" y2={height} stroke="#333" />
              {getYAxisTicks().map((tick, i) => (
                <g
                  key={tick}
                  transform={`translate(-10, ${
                    height - (height * tick) / maxValue
                  })`}
                >
                  <line x1="0" x2="10" y1="0" y2="0" stroke="#333" />
                  <text x="-12" y="5" textAnchor="end" fontSize="9">
                    {formatValue(tick)}
                  </text>
                </g>
              ))}

              {/* Bars */}
              {values.map((value, i) => {
                const isSelected = data.labels[i].trim() === selectedId?.trim();
                return (
                  <g
                    key={i}
                    transform={`translate(${i * (barWidth + barSpacing)}, 0)`}
                  >
                    <rect
                      x="0"
                      y={height - (height * value) / maxValue}
                      width={barWidth}
                      height={(height * value) / maxValue}
                      fill={data.datasets[0].backgroundColor[i]}
                    />
                    <text
                      x={barWidth / 2}
                      y={height + 20}
                      textAnchor="middle"
                      transform={`rotate(45, ${barWidth / 2}, ${height + 20})`}
                      fontSize="9"
                      fontWeight={isSelected ? "bold" : "normal"}
                    >
                      {data.labels[i]}
                    </text>
                  </g>
                );
              })}
            </g>
          </svg>
        </div>
      </div>
    );
  }
);
const DynamicNeighborhoodComparisonTab = ({
  ContentBox,
  clickedFeature,
  rankingsData,
}) => {
  if (!rankingsData || !clickedFeature) return null;

  // Helper function to get title based on geo type
  const getComparisonTitle = (metric, geoType) => {
    const locationTypeText =
      geoType === "NEIGHBORHOOD" ? "Neighborhood" : "City";
    return `${locationTypeText} ${metric} Comparison`;
  };

  return (
    <>
      <ContentBox
        title={getComparisonTitle("Population", clickedFeature.geoType)}
        key="TopGrowthComparison"
        subtype="dynamicNeighborhoodComparison"
        contentType="TopGrowthComparison"
      >
        <TopGrowthComparison
          rankingsData={rankingsData}
          selectedLocationId={clickedFeature.id}
        />
      </ContentBox>

      <ContentBox
        title={getComparisonTitle("Income", clickedFeature.geoType)}
        key="MedianIncomeComparison"
        subtype="dynamicNeighborhoodComparison"
        contentType="MedianIncomeComparison"
      >
        <MedianIncomeComparison
          rankingsData={rankingsData}
          selectedLocationId={clickedFeature.id}
        />
      </ContentBox>
    </>
  );
};

export default DynamicNeighborhoodComparisonTab;
