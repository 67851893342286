import React, { useState, useEffect, useRef } from "react";
import "./WatchlistComponent.css";
import { LineChart, ChartsTooltip } from "@mui/x-charts";
import { FaPencilAlt } from "react-icons/fa";
import removeIcon from "../assets/Group 93.png";

// Helper function to parse dates from the format "M/1/YY"
const parseIndexDate = (dateStr) => {
  const [month, day, year] = dateStr.split('/');
  const fullYear = parseInt(year) < 50 ? 2000 + parseInt(year) : 1900 + parseInt(year);
  return new Date(fullYear, parseInt(month) - 1, parseInt(day));
};

// Process index data and filter for last year
const processIndexData = (indexData) => {
  if (!indexData) return [];

  const data = Object.entries(indexData)
    .map(([dateStr, value]) => ({
      date: parseIndexDate(dateStr),
      value: value
    }))
    .sort((a, b) => a.date - b.date);

  // Filter for last year of data
  const lastDate = data[data.length - 1].date;
  const oneYearAgo = new Date(lastDate);
  oneYearAgo.setFullYear(lastDate.getFullYear() - 1);

  return data.filter(item => item.date >= oneYearAgo);
};

const ValuationGraph = ({ locationData, color, isPreview = false }) => {
  const [data, setData] = useState([]);
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (containerRef.current) {
        const { width, height } = containerRef.current.getBoundingClientRect();
        setDimensions({ width, height });
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (locationData?.indexData) {
      const processedData = processIndexData(locationData.indexData);
      setData(processedData);
    }
  }, [locationData]);

  const latestValuation = data.length > 0 ? data[data.length - 1].value : 0;

  const xAxis = {
    data: data.map((item) => item.date.getTime()),
    scaleType: 'time',
    valueFormatter: (value) => new Date(value).toLocaleDateString(),
    hide: true,
  };

  const yAxis = {
    min: Math.min(...data.map(item => item.value)) * 0.95,
    max: Math.max(...data.map(item => item.value)) * 1.05,
    hide: true,
  };

  const series = [{
    data: data.map((item) => item.value),
    area: true,
    showMark: false,
    curve: "linear",
    color: color,
  }];

  return (
    <div
      ref={containerRef}
      className={`valuation-graph ${isPreview ? "valuation-graph--preview" : ""}`}
    >
      <div className={`valuation-label ${isPreview ? "valuation-label--preview" : ""}`}>
        Valuation
      </div>
      <div className={`valuation-value ${isPreview ? "valuation-value--preview" : ""}`}>
        ${latestValuation.toLocaleString()}
      </div>
      {dimensions.width > 0 && dimensions.height > 0 && data.length > 0 && (
        <LineChart
          xAxis={[xAxis]}
          yAxis={[yAxis]}
          series={series}
          width={dimensions.width}
          height={dimensions.height * (isPreview ? 0.4 : 0.65)}
          margin={{ left: 0, right: 0, top: 0, bottom: 0 }}
          sx={{
            "& .MuiLineElement-root": {
              display: "none",
            },
            "& .MuiAreaElement-root": {
              fill: `url(#gradientColor-${color.replace("#", "")})`,
              opacity: 0.8,
            },
          }}
        >
          <defs>
            <linearGradient
              id={`gradientColor-${color.replace("#", "")}`}
              x1="0"
              y1="0"
              x2="0"
              y2="1"
            >
              <stop offset="5%" stopColor={color} stopOpacity={0.8} />
              <stop offset="95%" stopColor={color} stopOpacity={0} />
            </linearGradient>
          </defs>
        </LineChart>
      )}
    </div>
  );
};

const QuickFactSection = ({
  title,
  value,
  isPercentage = false,
  color = "#000",
  isPreview = false,
}) => {
  const formatValue = (val) => {
    if (isPercentage) {
      const numericValue = parseFloat(val);
      if (numericValue < 0) {
        return `(${Math.abs(numericValue).toFixed(2)}%)`;
      }
      return `${numericValue.toFixed(2)}%`;
    }
    return val;
  };

  return (
    <div className="quick-fact-section">
      <h4
        className={`quick-fact-label ${isPreview ? "quick-fact-label--preview" : ""
          }`}
      >
        {title}
      </h4>
      {!isPreview && (
        <div className="quick-fact-value" style={{ color }}>
          {formatValue(value)}
        </div>
      )}
    </div>
  );
};

const WatchlistComponent = ({
  id,
  name = "",
  color = "#1352E2",
  onDelete,
  existingNames = [],
  allLocations = [],
  isPreview = false,
  onEditClick,
  isEmpty = false,
  locationData = null
}) => {
  const [monthlyChange, setMonthlyChange] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (locationData?.indexData) {
      const processedData = processIndexData(locationData.indexData);

      if (processedData.length >= 2) {
        // Get last two months of data
        const lastValue = processedData[processedData.length - 1].value;
        const previousValue = processedData[processedData.length - 2].value;

        // Calculate percentage change
        const monthlyChangeValue = ((lastValue - previousValue) / previousValue) * 100;
        setMonthlyChange(monthlyChangeValue);

        // Set last updated date
        setLastUpdated(processedData[processedData.length - 1].date);
      } else {
        setMonthlyChange(null);
        setLastUpdated(null);
      }
    }
  }, [locationData]);

  const getChangeColor = (change) => {
    if (change > 0) return "#4CAF50";
    if (change < 0) return "#F44336";
    return "#000000";
  };

  const formatLastUpdated = (date) => {
    if (!date) return "";
    return new Intl.DateTimeFormat('en-US', {
      month: 'long',
      year: 'numeric'
    }).format(date);
  };

  if (isEmpty) {
    return (
      <div className="watchlist watchlist--preview watchlist--empty">
        <div className="watchlist__empty-state">
          <span>Add a neighborhood to your watchlist</span>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`watchlist ${isPreview ? "watchlist--preview" : ""}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        className={`watchlist__actions ${!isPreview ? "watchlist__actions--enabled" : ""
          } ${isHovered ? "watchlist__actions--visible" : ""}`}
      >
        <button
          className="watchlist__action-btn watchlist__edit-btn"
          onClick={() => onEditClick(id)}
        >
          <FaPencilAlt />
        </button>
      </div>
      <div className="watchlist__header">
        <div
          className="watchlist__color-indicator"
          style={{ backgroundColor: color }}
        ></div>
        <h3
          className={`watchlist__neighborhood ${isPreview ? "watchlist__neighborhood--preview" : ""
            }`}
        >
          {name}
        </h3>
        {isPreview && id !== "73d3582ea80cdef57cc8f5100dda57a1" && (
          <button
            className={`watchlist__preview-remove ${isHovered ? "watchlist__preview-remove--visible" : ""
              }`}
            onClick={() => onDelete(id)}
          >
            <img
              src={removeIcon}
              alt="Remove"
              className="watchlist__preview-remove-icon"
            />
          </button>
        )}
      </div>
      <ValuationGraph
        locationData={locationData}
        color={color}
        isPreview={isPreview}
      />
      <div
        className={`watchlist__quick-facts ${isPreview ? "watchlist__quick-facts--preview" : ""
          }`}
      >
        <QuickFactSection
          title="Monthly Appreciation Change"
          value={monthlyChange !== null ? monthlyChange : "Loading..."}
          isPercentage={true}
          color={monthlyChange !== null ? getChangeColor(monthlyChange) : "#000"}
          isPreview={isPreview}
        />
        {!isPreview && lastUpdated && (
          <div className="watchlist__last-updated">
            Data updated as of {formatLastUpdated(lastUpdated)}
          </div>
        )}
      </div>
    </div>
  );
};

export default WatchlistComponent;