import React, { useState, useEffect } from 'react';
import researchTutorial1 from "../assets/researchTutorial1.svg"
import researchTutorial2 from "../assets/researchTutorial2.svg"
import researchTutorial3 from "../assets/researchTutorial3.svg"
import researchTutorial4 from "../assets/researchTutorial4.svg"
import TutorialExit from "../assets/TutorialExit.svg";
import blueArrowIcon from "../assets/blueArrow.png";
import './ResearchTutorial.css';

const ResearchTutorial = ({ allLocations, handleCitySelect, handleItemClick, handleAddCityCard, setIsExpanded }) => {
    const [showTutorial, setShowTutorial] = useState(false);
    const [currentStep, setCurrentStep] = useState('overview');
    const [targetPosition, setTargetPosition] = useState(null);

    const tutorialImages = [
        researchTutorial1,
        researchTutorial2,
        researchTutorial3,
        researchTutorial4
    ];

    useEffect(() => {
        const shouldShowTutorial = localStorage.getItem('showResearchTutorial');
        if (shouldShowTutorial === null || shouldShowTutorial === 'true') {
            setShowTutorial(true);
        }
    }, []);

    useEffect(() => {
        const updatePosition = () => {
            switch (currentStep) {
                case 'searchField':
                    const searchInput = document.querySelector('.research-search-container div.research-search-bar[data-key="firstInput"]');
                    if (searchInput) {
                        const rect = searchInput.getBoundingClientRect();
                        setTargetPosition({
                            top: rect.top,
                            left: rect.left,
                            width: rect.width,
                            height: rect.height,
                            bubbleTop: rect.bottom + 10,
                            bubbleLeft: rect.left
                        });
                    }
                    break;

                case 'secondSearchField':
                    const secondSearch = document.querySelector('.research-search-container div.research-search-bar[data-key="secondInput"]');
                    if (secondSearch) {
                        const rect = secondSearch.getBoundingClientRect();
                        setTargetPosition({
                            top: rect.top,  // Change to include full element
                            left: rect.left,
                            width: rect.width,
                            height: rect.height,
                            bubbleTop: rect.bottom + 10,
                            bubbleLeft: rect.left
                        });
                    }
                    break;

                case 'quickDateFilter':
                    const filterButtons = document.querySelector('.research-quick-filter-container');  // Changed from buttons to container
                    if (filterButtons) {
                        const rect = filterButtons.getBoundingClientRect();
                        setTargetPosition({
                            top: rect.top,
                            left: rect.left,
                            width: rect.width,
                            height: rect.height,
                            bubbleTop: rect.bottom + 10,
                            bubbleLeft: rect.left + (rect.width / 2) - 100 // Center horizontally
                        });
                    }
                    break;

                case 'dropdown':
                    const dropdown = document.querySelector('.research-dropdown');
                    if (dropdown) {
                        const rect = dropdown.getBoundingClientRect();
                        setTargetPosition({
                            top: rect.top,
                            left: rect.left,
                            width: rect.width,
                            height: rect.height,
                            bubbleTop: rect.top + (rect.height / 2), // Center vertically
                            bubbleLeft: rect.left - rect.width * 1.2 // Position to the left with spacing
                        });
                    }
                    break;

                case 'researchCards':
                    const cardsContainer = document.querySelector('div.research-cards-container');
                    if (cardsContainer) {
                        const rect = cardsContainer.getBoundingClientRect();
                        setTargetPosition({
                            top: rect.top,
                            left: rect.left,
                            width: rect.width,
                            height: rect.height,
                            bubbleTop: rect.height / 2.5, // Position near top of cards
                            bubbleLeft: rect.width / 3.5 // Position to the right of cards
                        });
                    }
                    break;

                case 'graphicBox':
                    const expandedView = document.querySelector('.expanded-view');
                    if (expandedView) {
                        const rect = expandedView.getBoundingClientRect();
                        setTargetPosition({
                            top: rect.top,
                            left: rect.left,
                            width: rect.width,
                            height: rect.height,
                            bubbleLeft: rect.left + 325
                        });
                    }
                    break;

                case 'dataTypeSelector':
                    const selector = document.querySelector('.data-type-item selected');
                    if (selector) {
                        const rect = selector.getBoundingClientRect();
                        setTargetPosition({
                            top: rect.top,
                            left: rect.left - 250,
                            width: rect.width,
                            height: rect.height,
                            bubbleLeft: rect.left - 250
                        });
                    }
                    break;

                case 'clearSelections':
                    const clearButton = document.querySelector('.clear-selections-button');
                    if (clearButton) {
                        const rect = clearButton.getBoundingClientRect();
                        setTargetPosition({
                            top: rect.top,
                            left: rect.left - 250,
                            width: rect.width,
                            height: rect.height,
                            bubbleLeft: rect.left - 250
                        });
                    }
                    break;
            }
        };

        if (currentStep !== 'overview') {
            updatePosition();
            window.addEventListener('resize', updatePosition);
            return () => window.removeEventListener('resize', updatePosition);
        }
    }, [currentStep]);

    const handleExit = () => {
        setShowTutorial(false);
        localStorage.setItem('showResearchTutorial', 'false');
    };

    const handleStartTour = () => {
        setCurrentStep('searchField');

        // Simulate Providence selection
        setTimeout(() => {
            const providence = allLocations.find(loc => loc.name === "Providence");
            if (providence) {
                handleCitySelect(providence);
            }
        }, 1);
    };

    const handleNext = () => {
        const steps = [
            'searchField',
            'secondSearchField',
            'quickDateFilter',
            'dropdown',
            'researchCards',
            'graphicBox',
            'dataTypeSelector',
            'clearSelections'
        ];

        const currentIndex = steps.indexOf(currentStep);
        if (currentIndex < steps.length - 1) {
            setCurrentStep(steps[currentIndex + 1]);

            // Handle additional behaviors for each step transition
            switch (steps[currentIndex + 1]) {
                case 'secondSearchField':
                    handleAddCityCard();
                    break;
                case 'dropdown':
                    const dropdown = document.querySelector('.research-dropdown');
                    if (dropdown) dropdown.click()
                    break;
                case 'researchCards':
                    handleItemClick({
                        title: "Algorithm",
                        subtitle: "Valuation Index"
                    })
                    break;
                case 'graphicBox':
                    const expandedView = document.querySelector('div.expanded-view');
                    if (expandedView) expandedView.click();
                    break;
                case 'dataTypeSelector':
                    setIsExpanded(false);
                    break;
                case 'clearSelections':
                    setIsExpanded(true);

            }
        } else {
            handleExit();
        }
    };

    if (!showTutorial) return null;

    return (
        <div className="research-tutorial-overlay">
            {currentStep === 'overview' ? (
                <div className="research-tutorial-container">
                    <div className="research-tutorial-content">
                        <div className="research-tutorial-images-wrapper">
                            <div className="research-tutorial-images">
                                {tutorialImages.map((image, index) => (
                                    <div key={index} className="research-tutorial-image-container">
                                        <img
                                            src={image}
                                            alt={`Tutorial step ${index + 1}`}
                                            className="research-tutorial-image"
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <h1 className="research-tutorial-title">Research Page</h1>
                        <p className="research-tutorial-text">
                            Welcome to the Research page! Start by searching a city of interest in the top right.
                            Next, add up to 4 neighborhoods that you would like top compare data points on using
                            the search bar next to the one in the top right.
                        </p>
                        <p className="research-tutorial-text research-tutorial-text--padded">
                            After that simply select the date range you are interested. Finally, use the drop down
                            in the top right to select different data categories.
                        </p>
                        <div className="research-tutorial-buttons">
                            <button
                                className="research-tutorial-button research-tutorial-button--start"
                                onClick={handleExit}
                            >
                                Start
                            </button>
                            <button
                                className="research-tutorial-button research-tutorial-button--tour"
                                onClick={handleStartTour}
                            >
                                Take Tour
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <TutorialBubble
                    step={currentStep}
                    position={targetPosition}
                    onNext={handleNext}
                    onExit={handleExit}
                />
            )}
        </div>
    );
};

const TutorialBubble = ({ step, position, onNext, onExit }) => {
    if (!position) return null;

    const getBubbleContent = () => {
        switch (step) {
            case 'searchField':
                return "Search any city of interest to get specific real estate relates statistics.";
            case 'secondSearchField':
                return "Specify the neighborhood here if we have data for your selected city.";
            case 'quickDateFilter':
                return "Select a date range for our location valuation index data";
            case 'dropdown':
                return "Select a data category in which you are interested in researching for all of your locations.";
            case 'researchCards':
                return "Here is the city of Providence, displaying the RA Valuation index for the most recent 3 months we have data for.";
            case 'graphicBox':
                return "Want to see a specific kind of data compared directly to other locations? Click the category header to pick a data category.";
            case 'dataTypeSelector':
                return "Pick from one of our data categories to display data for just this card.";
            case 'clearSelections':
                return "Want to start fresh? Clear your selections here.";
            default:
                return "";
        }
    };

    const highlightStyle = {
        '--target-top': `${position.top}px`,
        '--target-left': `${position.left}px`,
        '--target-width': `${position.width}px`,
        '--target-height': `${position.height}px`
    };

    return (
        <>
            <div className="research-tutorial-highlight-area" style={highlightStyle} />
            <div
                className={`research-tutorial-bubble research-tutorial-bubble--${step}`}
                style={{
                    top: `${position.bubbleTop || position.top}px`,
                    left: `${position.bubbleLeft}px`
                }}
            >
                <div className="research-tutorial-bubble-arrow" />
                <img
                    src={TutorialExit}
                    alt="Exit Tutorial"
                    className="research-tutorial-exit-icon"
                    onClick={onExit}
                />
                <p className="research-tutorial-bubble-text">
                    {getBubbleContent(step)}
                </p>
                <img
                    src={blueArrowIcon}
                    alt="Next"
                    className="research-tutorial-next-icon"
                    onClick={onNext}
                />
            </div>
        </>
    );
};

export default ResearchTutorial;