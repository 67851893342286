import React, {
  useContext,
  useState,
  useMemo,
  useEffect,
  useRef,
  memo,
} from "react";
import "./MarketModePage.css";
import { MarketModeContext } from "../contexts/MarketModeContext";
import {
  getContentType,
  serializeContentProps,
  transformContentForMarketMode,
  marketModeComponents, // Add this
} from "./contentBoxRegistry";
import { useSpring, animated, useSprings } from "react-spring";
import SubtypeOverlay from "./SubtypeOverlay";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

// Import icons
import algoIcon from "../assets/algo icon-01 1.svg";
import recentHomeSalesIcon from "../assets/houseBlue.svg";
import schoolIcon from "../assets/schools-icon.svg";
import populationIcon from "../assets/community-icon.svg";
import crimeIcon from "../assets/crime-icon-01 1.svg";
import incomeIcon from "../assets/dollarsign-icon.svg";
import dynamicNeighborhoodComparisonIcon from "../assets/houseBlue.svg";
import tabularNeighborhoodComparisonIcon from "../assets/houseBlue.svg";
import savedPresetsIcon from "../assets/saved presets.svg";
import logoIcon from "../assets/logoWhite.svg";
import logoBlue from "../assets/logoBlue.svg";
import removeIcon from "../assets/Group 93.png";
import xIcon from "../assets/x-icon.svg";
import { useNavigate } from "react-router-dom";
import locationMetricsIcon from "../assets/locationMetricsIcon.svg";
import { get_current_user } from '../actions/auth';

// Print configuration constants
const PRINT_CONFIG = {
  PAGE_WIDTH: 816, // Standard US Letter width in pixels at 96 DPI
  PAGE_HEIGHT: 1056, // Standard US Letter height in pixels at 96 DPI
  SCALE: 2, // Higher scale for better quality
  MARGIN: {
    top: 0,
    right: 0,
    bottom: 40, // Space for footer
    left: 0,
  },
};

const EnlargedPreview = ({ isOpen, onClose, imageSrc }) => {
  const animation = useSpring({
    opacity: isOpen ? 1 : 0,
    config: { tension: 300, friction: 20 },
  });

  if (!isOpen) return null;

  return (
    <animated.div className="enlarged-preview" style={animation}>
      <button className="close-button" onClick={onClose}>
        <img src={removeIcon} alt="Close" />
      </button>
      <div className="image-container">
        <img src={imageSrc} alt="Enlarged preview" />
      </div>
    </animated.div>
  );
};

const FileNamePopup = ({ isOpen, onClose, onSave }) => {
  const [fileName, setFileName] = useState("market_sheet");

  const animation = useSpring({
    opacity: isOpen ? 1 : 0,
    transform: isOpen ? "translate(-50%, -50%)" : "translate(-50%, -60%)",
    config: { tension: 300, friction: 20 },
  });

  const handleSave = () => {
    onSave(fileName);
  };

  if (!isOpen) return null;

  return (
    <animated.div className="file-name-popup print-popup" style={animation}>
      <div className="print-popup-header">
        <h2>Choose File Name</h2>
        <button className="close-button" onClick={onClose}>
          <img src={xIcon} alt="Close" />
        </button>
      </div>
      <div className="file-name-input">
        <input
          type="text"
          value={fileName}
          onChange={(e) => setFileName(e.target.value)}
        />
      </div>
      <div className="save-options">
        <button onClick={handleSave}>Save</button>
      </div>
    </animated.div>
  );
};

const PrintPopup = ({ isOpen, onClose, onSave, pages }) => {
  const [showFileNamePopup, setShowFileNamePopup] = useState(false);
  const [selectedFormat, setSelectedFormat] = useState(null);
  const [enlargedPreview, setEnlargedPreview] = useState(null);

  const blurAnimation = useSpring({
    opacity: isOpen || showFileNamePopup || enlargedPreview !== null ? 1 : 0,
    config: { duration: 300 },
  });

  const popupAnimation = useSpring({
    opacity: isOpen && !showFileNamePopup && enlargedPreview === null ? 1 : 0,
    config: { tension: 300, friction: 20 },
  });

  const handleFormatSelect = (format) => {
    setSelectedFormat(format);
    setShowFileNamePopup(true);
  };

  const handleSave = (fileName) => {
    onSave(selectedFormat, fileName);
    setShowFileNamePopup(false);
    onClose();
  };

  const handleEnlargedPreviewClose = () => {
    setEnlargedPreview(null);
  };

  if (!isOpen && !showFileNamePopup && enlargedPreview === null) return null;

  return (
    <>
      <animated.div className="blur-overlay" style={blurAnimation} />
      {!showFileNamePopup && enlargedPreview === null && (
        <animated.div className="print-popup" style={popupAnimation}>
          <div className="print-popup-header">
            <h2>Print Market Sheet</h2>
            <button className="close-button" onClick={onClose}>
              <img src={xIcon} alt="Close" />
            </button>
          </div>
          <div className="pages-preview">
            <h3>Pages</h3>
            <div className="preview-container">
              {pages.map((page, index) => (
                <div
                  key={index}
                  className="page-preview"
                  onClick={() => setEnlargedPreview(page)}
                >
                  <img src={page} alt={`Page ${index + 1}`} />
                </div>
              ))}
            </div>
          </div>
          <div className="save-options">
            <button onClick={() => handleFormatSelect("pdf")}>
              Save as PDF
            </button>
            <button onClick={() => handleFormatSelect("png")}>
              Save as PNG
            </button>
          </div>
        </animated.div>
      )}
      <FileNamePopup
        isOpen={showFileNamePopup}
        onClose={() => setShowFileNamePopup(false)}
        onSave={handleSave}
      />
      <EnlargedPreview
        isOpen={enlargedPreview !== null}
        onClose={handleEnlargedPreviewClose}
        imageSrc={enlargedPreview}
      />
    </>
  );
};

const Footer = ({ footerText }) => (
  <div className="footer-market-page">
    {footerText}
  </div>
);

const Banner = ({ bannerText, onEditClick, showEditButton }) => {
  return (
    <div className="banner-container">
      <div className="banner-content">
        <div
          className="banner-edit-button-container"
          data-html2canvas-ignore="true"
          style={{ visibility: showEditButton ? 'visible' : 'hidden' }}
        >
          <button className="banner-edit-button" onClick={onEditClick}>
            Change Header Info
          </button>
        </div>
        <div className="banner-text-wrapper">
          <div className="banner-text-company">{bannerText.company}</div>
          <div className="banner-text-information">
            {bannerText.realtorName}
            <span className="banner-separator">|</span>
            {bannerText.realtorWebsite}
            <span className="banner-separator">|</span>
            {bannerText.realtorEmail}
            <span className="banner-separator">|</span>
            {bannerText.realtorPhoneNumber}
          </div>
        </div>
      </div>
    </div>
  );
};

const MarketModePage = ({ isStatisticsTabOpen }) => {
  const { pages, removeContentBox, savePreset } = useContext(MarketModeContext);
  const [hoveredSubtype, setHoveredSubtype] = useState(null);
  const [hoveredBoxKey, setHoveredBoxKey] = useState(null);
  const [isPrintPopupOpen, setIsPrintPopupOpen] = useState(false);
  const [isPresetNamePopupOpen, setIsPresetNamePopupOpen] = useState(false);
  const [presetName, setPresetName] = useState("");
  const [pagePreviews, setPagePreviews] = useState([]);
  const contentRef = useRef(null);
  const navigate = useNavigate();
  const [isCreatingPreview, setIsCreatingPreview] = useState(false);
  const [userData, setUserData] = useState(null);

  const MemoizedSubtypeOverlay = memo(SubtypeOverlay);

  const defaultBannerText = {
    company: "Steal Real Estate",
    realtorWebsite: "stealrealestate.com",
    realtorName: "Diana Glandor",
    realtorEmail: "Diana@stealrealestate.com",
    realtorPhoneNumber: "000-000-0000",
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const data = await get_current_user();
        setUserData(data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
    fetchUserData();
  }, []);
  // Construct banner text from user data or fall back to defaults
  const bannerText = useMemo(() => {
    if (!userData) return defaultBannerText;
    return {
      company: userData.company || defaultBannerText.company,
      realtorWebsite: userData.website || defaultBannerText.realtorWebsite,
      realtorName: `${userData.firstName || ''} ${userData.lastName || ''}`.trim() || defaultBannerText.realtorName,
      realtorEmail: userData.email || defaultBannerText.realtorEmail,
      realtorPhoneNumber: userData.phoneNumber || defaultBannerText.realtorPhoneNumber,
    };
  }, [userData]);
  // Construct footer text from user data or fall back to defaults
  const footerText = useMemo(() => {
    if (!userData) return `${defaultBannerText.realtorWebsite} | ${defaultBannerText.realtorEmail} | ${defaultBannerText.realtorPhoneNumber}`;
    const website = userData.website || defaultBannerText.realtorWebsite;
    const email = userData.email || defaultBannerText.realtorEmail;
    const phone = userData.phoneNumber || defaultBannerText.realtorPhoneNumber;
    return `${website} | ${email} | ${phone}`;
  }, [userData]);
  // Determine if we should show the edit button
  const showEditButton = useMemo(() => {
    return !userData || !(userData.company || userData.website || userData.firstName ||
      userData.lastName || userData.email || userData.phoneNumber);
  }, [userData]);

  const pageAnimation = useSpring({
    transform: isStatisticsTabOpen ? "translateX(0vw)" : "translateX(-17.4vw)",
    config: { duration: 300 },
  });

  // Capture pages function
  const cloneStylesWithPrint = (sourceElement, targetElement) => {
    // Get all stylesheets
    const sheets = document.styleSheets;
    let printStyles = "";

    // Extract print-specific styles
    for (const sheet of sheets) {
      try {
        const rules = sheet.cssRules || sheet.rules;
        for (const rule of rules) {
          if (
            rule.type === CSSRule.MEDIA_RULE &&
            rule.conditionText?.includes("print")
          ) {
            printStyles += rule.cssText;
          }
        }
      } catch (e) {
        console.warn("Could not access stylesheet rules");
      }
    }

    // Create and attach a style element with print styles
    const styleElement = document.createElement("style");
    styleElement.textContent = printStyles
      .replace(/@media print {/g, "")
      .replace(/}/g, "");
    targetElement.appendChild(styleElement);
  };

  const capturePages = async () => {
    if (!contentRef.current) return [];

    // Create a hidden container
    const hiddenContainer = document.createElement("div");
    hiddenContainer.style.position = "fixed";
    hiddenContainer.style.top = "-9999px";
    hiddenContainer.style.left = "-9999px";
    hiddenContainer.style.width = `${PRINT_CONFIG.PAGE_WIDTH}px`;
    hiddenContainer.style.height = `${PRINT_CONFIG.PAGE_HEIGHT}px`;
    document.body.appendChild(hiddenContainer);

    const pageElements = contentRef.current.querySelectorAll(
      ".market-mode-page-wrapper"
    );
    const previews = [];

    try {
      for (const pageElement of pageElements) {
        // Clone the page element
        const clone = pageElement.cloneNode(true);

        // Reset styles on clone
        clone.style.transform = "none";
        clone.style.width = `${PRINT_CONFIG.PAGE_WIDTH}px`;
        clone.style.minHeight = `${PRINT_CONFIG.PAGE_HEIGHT}px`;
        clone.style.margin = "0";
        clone.style.padding = "0";
        clone.style.border = "none";

        // Apply print styles directly to the clone
        const bannerTextWrapper = clone.querySelector(".banner-text-wrapper");
        if (bannerTextWrapper) {
          bannerTextWrapper.style.paddingTop = "27px";
        }

        const buttonContainer = clone.querySelector(
          ".banner-edit-button-container"
        );
        if (buttonContainer) {
          buttonContainer.style.visibility = "hidden";
          buttonContainer.style.height = "27px";
        }

        // Apply print styles from stylesheets
        cloneStylesWithPrint(pageElement, clone);

        // Add clone to hidden container
        hiddenContainer.innerHTML = "";
        hiddenContainer.appendChild(clone);

        // Force a layout recalculation
        const forceLayout = hiddenContainer.getBoundingClientRect();

        // Wait for styles to be applied
        await new Promise((resolve) => setTimeout(resolve, 100));

        const canvas = await html2canvas(clone, {
          scale: PRINT_CONFIG.SCALE,
          useCORS: true,
          allowTaint: true,
          logging: false,
          windowWidth: PRINT_CONFIG.PAGE_WIDTH,
          windowHeight: PRINT_CONFIG.PAGE_HEIGHT,
          x: 0,
          y: 0,
          scrollX: 0,
          scrollY: 0,
          backgroundColor: "#FFFFFF",
          onclone: (clonedDoc) => {
            // Additional modifications to cloned document if needed
            const clonedBanner = clonedDoc.querySelector(
              ".banner-text-wrapper"
            );
            if (clonedBanner) {
              clonedBanner.style.paddingTop = "27px";
            }
          },
        });

        // Create a new canvas with proper dimensions
        const printCanvas = document.createElement("canvas");
        printCanvas.width = PRINT_CONFIG.PAGE_WIDTH * PRINT_CONFIG.SCALE;
        printCanvas.height = PRINT_CONFIG.PAGE_HEIGHT * PRINT_CONFIG.SCALE;
        const ctx = printCanvas.getContext("2d");

        // Fill with white background
        ctx.fillStyle = "#FFFFFF";
        ctx.fillRect(0, 0, printCanvas.width, printCanvas.height);

        // Draw the captured content
        ctx.drawImage(canvas, 0, 0);

        previews.push(printCanvas.toDataURL("image/png", 1.0));
      }
    } finally {
      // Clean up: remove the hidden container
      document.body.removeChild(hiddenContainer);
    }

    return previews;
  };
  const [isPrinting, setIsPrinting] = useState(false);

  const generatePDF = async (canvases, fileName) => {
    const pdf = new jsPDF({
      unit: "px",
      format: "letter",
      orientation: "portrait",
    });

    for (let i = 0; i < canvases.length; i++) {
      if (i > 0) pdf.addPage();

      const canvas = document.createElement("canvas");
      canvas.width = PRINT_CONFIG.PAGE_WIDTH;
      canvas.height = PRINT_CONFIG.PAGE_HEIGHT;
      const ctx = canvas.getContext("2d");

      // Load image data
      const img = new Image();
      await new Promise((resolve) => {
        img.onload = resolve;
        img.src = canvases[i];
      });

      // Calculate dimensions to maintain aspect ratio
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const ratio = Math.min(
        pageWidth / PRINT_CONFIG.PAGE_WIDTH,
        pageHeight / PRINT_CONFIG.PAGE_HEIGHT
      );

      const finalWidth = PRINT_CONFIG.PAGE_WIDTH * ratio;
      const finalHeight = PRINT_CONFIG.PAGE_HEIGHT * ratio;
      const xOffset = (pageWidth - finalWidth) / 2;
      const yOffset = (pageHeight - finalHeight) / 2;

      // Add image to PDF
      pdf.addImage(
        canvases[i],
        "PNG",
        xOffset,
        yOffset,
        finalWidth,
        finalHeight,
        undefined,
        "FAST"
      );
    }

    pdf.save(`${fileName}.pdf`);
  };

  const savePNGs = (canvases, fileName) => {
    canvases.forEach((canvas, index) => {
      const link = document.createElement("a");
      link.download = `${fileName}_page${index + 1}.png`;
      link.href = canvas;
      link.click();
    });
  };

  const handlePrint = async () => {
    setIsPrinting(true);
    try {
      const previews = await capturePages();
      setPagePreviews(previews);
      setIsPrintPopupOpen(true);
    } finally {
      setIsPrinting(false);
    }
  };
  const handleEditHeaderClick = () => {
    navigate("/account?tab=Settings");
  };

  const handleSave = async (format, fileName) => {
    const canvases = await capturePages();

    if (format === "pdf") {
      await generatePDF(canvases, fileName);
    } else {
      savePNGs(canvases, fileName);
    }

    setIsPrintPopupOpen(false);
  };
  const allContentBoxes = useMemo(
    () => pages.flatMap((page) => page.contentBoxes),
    [pages]
  );

  const allSubtypes = useMemo(() => {
    return [...new Set(allContentBoxes.map((box) => box.subtype))];
  }, [allContentBoxes]);

  const [springs, api] = useSprings(allContentBoxes.length, (i) => ({
    from: { opacity: 0, scale: 0.8 },
    to: { opacity: 1, scale: 1 },
    config: { mass: 5, tension: 500, friction: 100 },
  }));

  useEffect(() => {
    api.start((i) => ({
      opacity: 1,
      scale: 1,
      delay: i * 25,
    }));
  }, [api, allContentBoxes]);

  const getHeaderIcon = (subtype) => {
    switch (subtype) {
      case "algo":
        return algoIcon;
      case "recentHomeSales":
        return recentHomeSalesIcon;
      case "school":
        return schoolIcon;
      case "population":
        return populationIcon;
      case "crime":
        return crimeIcon;
      case "income":
        return incomeIcon;
      case "dynamicNeighborhoodComparison":
        return dynamicNeighborhoodComparisonIcon;
      case "tabularNeighborhoodComparison":
        return tabularNeighborhoodComparisonIcon;
      case "savedPresets":
        return savedPresetsIcon;
      case "locationMetrics":
        return locationMetricsIcon;
      default:
        return null;
    }
  };

  const getHeaderText = (subtype) => {
    switch (subtype) {
      case "algo":
        return "Algo";
      case "recentHomeSales":
        return "Recent Home Sales";
      case "locationMetrics":
        return "Location Metrics";
      case "school":
        return "School Statistics";
      case "population":
        return "Population Statistics";
      case "crime":
        return "Crime Statistics";
      case "income":
        return "Income Statistics";
      case "dynamicNeighborhoodComparison":
        return "Dynamic Neighborhood Comparison";
      case "tabularNeighborhoodComparison":
        return "Tabular Neighborhood Comparison";
      case "savedPresets":
        return "Saved Presets";
      default:
        return "";
    }
  };

  const handleMouseEnter = (key) => {
    setHoveredBoxKey(key);
  };

  const handleMouseLeave = () => {
    setHoveredBoxKey(null);
  };

  const handleSubtypeMouseEnter = (subtype) => {
    setHoveredSubtype(subtype);
  };

  const handleSubtypeMouseLeave = () => {
    setHoveredSubtype(null);
  };

  const handleRemoveContentBox = (boxToRemove) => {
    removeContentBox(boxToRemove);
    api.start((i) => {
      if (i >= allContentBoxes.indexOf(boxToRemove)) {
        return {
          opacity: 0,
          scale: 0.8,
          onRest: () => api.start((i) => ({ opacity: 1, scale: 1 })),
        };
      }
      return {};
    });
  };
  const PresetNamePopup = ({
    isOpen,
    onClose,
    onSave,
    presetName,
    setPresetName,
  }) => {
    const animation = useSpring({
      opacity: isOpen ? 1 : 0,
      transform: isOpen ? "translate(-50%, -50%)" : "translate(-50%, -60%)",
      config: { tension: 300, friction: 20 },
    });

    if (!isOpen) return null;

    return (
      <animated.div className="preset-name-popup print-popup" style={animation}>
        <div className="print-popup-header">
          <h2>Save Preset</h2>
          <button className="close-button" onClick={onClose}>
            <img src={xIcon} alt="Close" />
          </button>
        </div>
        <div className="preset-name-input">
          <input
            type="text"
            value={presetName}
            onChange={(e) => setPresetName(e.target.value)}
            placeholder="Enter preset name"
          />
        </div>
        <div className="save-options">
          <button onClick={onSave}>Save</button>
        </div>
      </animated.div>
    );
  };

  const PageLogo = ({ pageIndex }) => {
    const logoSrc = pageIndex === 0 ? logoIcon : logoBlue;
    const logoClass = pageIndex === 0 ? "white-logo" : "blue-logo";

    return (
      <div
        className="logo-container"
        style={{
          position: "absolute",
          top: "1.5rem",
          right: "1.5rem",
          zIndex: 10,
        }}
      >
        <img
          src={logoSrc}
          alt={`Logo for page ${pageIndex}`}
          className={`logo-icon ${logoClass}`}
          style={{ width: "50px", height: "auto" }}
        />
      </div>
    );
  };

  const renderContentBoxes = (contentBoxes, isFirstPage) => {
    const subtypes = [...new Set(contentBoxes.map((box) => box.subtype))];

    return subtypes.map((subtype, subtypeIndex) => {
      const subtypeBoxes = contentBoxes.filter(
        (box) => box.subtype === subtype
      );

      const isFirstSubtype = subtypeIndex === 0;
      const topMargin = isFirstPage
        ? isFirstSubtype
          ? 0
          : 0
        : isFirstSubtype
          ? 50
          : 0;

      return (
        <div
          key={subtype}
          className="market-mode-subtype-section-wrapper"
          style={{ marginTop: `${topMargin}px` }}
        >
          <div className="market-mode-subtype-section">
            <div
              className="market-mode-subtype-header"
              onMouseEnter={() => handleSubtypeMouseEnter(subtype)}
              onMouseLeave={handleSubtypeMouseLeave}
            >
              <img
                src={getHeaderIcon(subtype)}
                alt={subtype}
                className="subtype-icon"
              />
              <h2>{getHeaderText(subtype)}</h2>
            </div>
            <div className="market-mode-page-content">
              {springs.map((props, index) => {
                const item = allContentBoxes[index];
                if (
                  item &&
                  item.subtype === subtype &&
                  contentBoxes.includes(item)
                ) {

                  const locationDisplay =
                    item.locationName ||
                    (item.locationId
                      ? `Location ID: ${item.locationId}`
                      : "Selected Area: National Average");

                  let displayContent = item.content;
                  if (marketModeComponents[item.contentType]) {
                    displayContent = transformContentForMarketMode(
                      item.content,
                      item.contentType
                    );
                  }

                  return (
                    <animated.div
                      key={item.key}
                      style={props}
                      className="market-mode-content-box-wrapper"
                      onMouseEnter={() => handleMouseEnter(item.key)}
                      onMouseLeave={handleMouseLeave}
                    >
                      <div className="market-mode-content-box">
                        <div className="market-mode-box-header">
                          <div className="market-mode-box-header-title">
                            <span>{item.title}</span>
                            <button
                              className="market-mode-remove-button"
                              onClick={() => handleRemoveContentBox(item)}
                              title={`Remove ${item.title}`}
                              aria-label="Remove"
                            />
                          </div>
                        </div>
                        <div className="market-mode-box-content">
                          <div className="market-mode-box-content-location">
                            {locationDisplay}
                          </div>
                          {displayContent}
                        </div>
                      </div>
                    </animated.div>
                  );
                }
                return null;
              })}
            </div>
          </div>
        </div>
      );
    });
  };

  const handleSavePreset = () => {
    if (presetName) {
      const serializablePages = pages.map((page) => ({
        ...page,
        contentBoxes: page.contentBoxes.map((box) => ({
          ...box,
          contentType: box.contentType,
          props: serializeContentProps(box.content),
          content: null, // Don't save the React component
        })),
      }));

      savePreset(presetName, serializablePages);
      setPresetName("");
      setIsPresetNamePopupOpen(false);
    }
  };

  return (
    <>
      <animated.div
        className="market-mode-page-container"
        style={pageAnimation}
      >
        <div className="scrollable-content">
          <div ref={contentRef}>
            {pages.map((page, index) => (
              <div key={page.id} className="market-mode-page-wrapper">
                <div
                  className={`market-mode-page ${index === pages.length - 1 ? "last-page" : ""
                    }`}
                  style={{ position: "relative" }}
                >
                  {index === 0 && (
                    <Banner
                      bannerText={bannerText}
                      onEditClick={handleEditHeaderClick}
                      showEditButton={showEditButton}
                    />
                  )}
                  <PageLogo pageIndex={index} />
                  <MemoizedSubtypeOverlay
                    subtypes={allSubtypes}
                    contentBoxes={page.contentBoxes}
                    pageIndex={index}
                  />
                  {renderContentBoxes(page.contentBoxes, index === 0)}
                  <Footer footerText={footerText} />
                </div>
              </div>
            ))}
          </div>
          <div className="fade-and-print-container">
            <div className="fade-bottom"></div>
            <div
              className={`print-icon ${isPrinting ? "printing" : ""}`}
              onClick={!isPrinting ? handlePrint : undefined}
              style={{
                opacity: isPrinting ? 0.5 : 1,
                cursor: isPrinting ? "wait" : "pointer",
              }}
            />
          </div>
        </div>
      </animated.div>
      <PrintPopup
        isOpen={isPrintPopupOpen}
        onClose={() => setIsPrintPopupOpen(false)}
        onSave={handleSave}
        pages={pagePreviews}
      />
      <PresetNamePopup
        isOpen={isPresetNamePopupOpen}
        onClose={() => setIsPresetNamePopupOpen(false)}
        onSave={handleSavePreset}
        presetName={presetName}
        setPresetName={setPresetName}
      />
    </>
  );
};

export default MarketModePage;
