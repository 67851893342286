// src/components/DashboardSection.js
import React from 'react';
import './DashboardSection.css';
import dashboardImage from '../assets/dashboard example 1.svg';

const DashboardSection = () => {
    return (
        <section className="left-aligned-section">
            <div className="left-aligned-content">
                <h2>Sleek, Intuitive, and Powerful<br />Real Estate Tools.</h2>
                <p>
                    With a fully customizable dashboard, you can have access to crucial data
                    ready at your fingertips.  <br></br>Gain a competitive edge with access to valuable and complex insights simplified for your clients.
                </p>
            </div>
        </section>
    );
};

export default DashboardSection;