// src/components/TestimonialSection.js
import React, { useEffect } from 'react';
import './TestimonialSection.css';
import { Link } from 'react-router-dom';
import testimonialBackgroundImage from '../assets/testimonial-section-building.svg';
import card1image from "../assets/card1bgimage.svg";
import card2image from "../assets/card2bgimage.svg";
import card3image from "../assets/card3bgimage.svg";
import card1pfp from "../assets/card1pfp.svg";
import card2pfp from "../assets/card2pfp.svg";
import card3pfp from "../assets/card3pfp.svg";

const TestimonialSection = () => {
    const cases = [
        {
            image: card1image,
            avatar: card1pfp,
            name: "Tim Daly",
            company: "North Field Real Estate",
            tool: "SnapSheets",
            useCase: "Tim uses SnapSheets to quickly create sleek, data-packed one-pagers for his buyer clients. By providing hyper-local market insights tailored to specific neighborhoods, he positions himself as an expert and saves hours on report creation. These polished sheets help Tim stand out in client presentations, securing more listings and increasing referrals"
        },
        {
            image: card2image,
            avatar: card2pfp,
            name: "Diana Truldy",
            company: "Escape Real Estate",
            tool: "Custom Sheets",
            useCase: "Generate polished, data-packed one-pagers that position you as the go-to expert in any neighborhood. With RealAnalytica, you can walk into every meeting with a powerful, marketable report in hand, making a lasting impression on clients."
        },
        {
            image: card3image,
            avatar: card3pfp,
            name: "George Foreman",
            company: "Flatter Real Estate",
            tool: "Research",
            useCase: "Generate polished, data-packed one-pagers that position you as the go-to expert in any neighborhood. With RealAnalytica, you can walk into every meeting with a powerful, marketable report in hand, making a lasting impression on clients."
        }
    ];

    useEffect(() => {
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.2
        };

        const handleIntersection = (entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, observerOptions);

        // Only observe boxes on mobile
        if (window.innerWidth <= 768) {
            document.querySelectorAll('.client-use-case-box').forEach(box => {
                observer.observe(box);
            });
        }

        return () => observer.disconnect();
    }, []);

    return (
        <div className="testimonial-section">
            <img
                src={testimonialBackgroundImage}
                alt="Testimonial Background"
                className="testimonial-background-image"
            />
            <div className="testimonial-content">
                <h2 className="client-use-case-title">Client Use Cases</h2>
                <p className="client-use-case-subtitle">Our clients say it all!</p>
                <div className="client-use-case-boxes">
                    {cases.map((item, index) => (
                        <div
                            key={index}
                            className="client-use-case-box"
                            style={{
                                transitionDelay: `${index * 0.2}s`
                            }}
                        >
                            <div className="client-use-case-image-container">
                                <img
                                    src={item.image}
                                    alt={`${item.company} office`}
                                    className="client-use-case-image"
                                />
                            </div>
                            <div className="client-use-case-details">
                                <div className="client-use-case-realtor-info">
                                    <img
                                        src={item.avatar}
                                        alt={item.name}
                                        className="client-use-case-realtor-avatar"
                                    />
                                    <div className="client-use-case-realtor-text">
                                        <h3 className="client-use-case-realtor-name">{item.name}</h3>
                                        <p className="client-use-case-company-name">{item.company}</p>
                                    </div>
                                </div>
                                <div className="client-use-case-tools-section">
                                    <p className="client-use-case-tools-header">Tools Used</p>
                                    <div className="client-use-case-tool-tag">
                                        <p className="client-use-case-tool-text">{item.tool}</p>
                                    </div>
                                </div>
                                <div className="client-use-case-description-section">
                                    <p className="client-use-case-description-header">Use Case</p>
                                    <p className="client-use-case-description-text">{item.useCase}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <Link to="/signup" className="signup-btn testimonial-signup-btn">
                    <strong>Sign Up</strong> - It's Free
                </Link>
            </div>
            <div className="white-band"></div>
        </div>
    );
};

export default TestimonialSection;