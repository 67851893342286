import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCheckoutSession, getUserData } from '../actions/auth';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function priceIdToPlan(planId) {
    const paymentToRoleMap = {
        [process.env.REACT_APP_PREMIUM_PLAN_TEST_PRICE_ID]: "Premium",
        [process.env.REACT_APP_PREMIUM_PLUS_TEST_PRICE_ID]: "Premium Plus",
        [process.env.REACT_APP_PROFESSIONAL_TEST_PRICE_ID]: "Professional"
    };

    let plan = paymentToRoleMap[planId];
    return plan;
}

function CheckoutSuccess() {
    const dispatch = useDispatch();
    let query = useQuery();
    const sessionId = query.get("session_id");
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState('');
    const auth = useSelector(state => state.auth);
    const currentUser = auth.user;
    const [purchasedPlan, setPurchasedPlan] = useState('');

    useEffect(() => {
        if (!sessionId) return;

        dispatch(getCheckoutSession(sessionId))
            .then(response => {
                // Assuming the action returns the response data
                console.log(response);
                setLoading(false);
                if (response.data["status"] === "complete" && response.data["payment_status"] === "paid") {
                    setMessage('Your purchase of the ' + priceIdToPlan(response.data.subscription.plan["id"]) + ' role was successful!');
                    setPurchasedPlan(priceIdToPlan(response.data.subscription.plan["id"]));

                } else {
                    setMessage('You do not have permission to view this page or the session does not exist.');
                }
            })
            .catch(error => {
                console.error('Failed to fetch data:', error);
                setMessage('An error occurred while verifying your purchase.');
                setLoading(false);
            });
    }, [sessionId, dispatch]);

    if (loading) return <div>Loading...</div>;

    return (
        <div>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <h2>Purchase Status</h2>
            <p>{message}</p>
        </div>
    );
}

export default CheckoutSuccess;