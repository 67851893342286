// Footer.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { checkAuthStatus } from '../actions/auth';
import { signOut } from '@aws-amplify/auth';
import './Footer.css';
import blueLogo from '../assets/SVG-realanalytica-primary-logo-blue.svg';
import blueBetaLogo from '../assets/RA-logo-1-beta.svg';

const Footer = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    // Check auth status whenever route changes
    useEffect(() => {
        const checkAuth = async () => {
            try {
                const status = await checkAuthStatus();
                setIsAuthenticated(status.isAuthenticated);
            } catch (error) {
                setIsAuthenticated(false);
            }
        };

        checkAuth();
    }, [location.pathname]); // Re-check when route changes

    const logoutUser = async () => {
        try {
            await signOut();
            setIsAuthenticated(false);
            navigate('/');
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    const generalLinks = (
        <>
            <Link to="/">Home</Link>
            {/* <Link to="/about">About</Link> */}
            <Link to="/blog">Blog</Link>
            <Link to="/contacts">Contacts</Link>
        </>
    );

    const authLinks = (
        <>
            <Link to="/dashboard">Dashboard</Link>
            <Link to="/account">Account</Link>
            <Link to="/" onClick={logoutUser}>Logout</Link>
        </>
    );

    const guestLinks = (
        <>
            <Link to="/signup">Sign Up</Link>
            <Link to="/login">Sign In</Link>
        </>
    );

    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-links">
                    <div className="footer-section">
                        {generalLinks}
                    </div>
                    <div className="footer-section">
                        {isAuthenticated ? authLinks : guestLinks}
                    </div>
                    <div className="footer-section">
                        <Link to="/faq">FAQ</Link>
                    </div>
                </div>
            </div>
            <div className="footer-logo">
                <img src={blueBetaLogo} alt="RealAnalytica Logo" />
            </div>
        </footer>
    );
};

export default Footer;