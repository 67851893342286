// CrimeTab.js
import React, { memo, useMemo } from "react";
import { Pie, Bar } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { LineChart } from "@mui/x-charts/LineChart";
import "./CrimeTab.css";

ChartJS.register(ArcElement, Tooltip, Legend);

// Helper function to calculate overall safety score
const calculateSafetyScore = (crimeStats) => {
  const weights = {
    murderIndex: 0.25,
    forcibleRapeIndex: 0.15,
    forcibleRobberyIndex: 0.15,
    aggravatedAssaultIndex: 0.15,
    burglaryIndex: 0.1,
    larcenyIndex: 0.1,
    motorVehicleIndex: 0.1,
  };

  let weightedSum = 0;
  let maxPossible = 0;

  Object.entries(weights).forEach(([crime, weight]) => {
    weightedSum += (100 - crimeStats[crime] / 2) * weight; // Normalize to 100 scale
    maxPossible += 100 * weight;
  });

  return ((weightedSum / maxPossible) * 10).toFixed(1);
};

export const CrimeStatsSummary = memo(({ locationData }) => {
  if (!locationData?.crimeStatistics) return null;

  const { crimeStatistics } = locationData;
  const safetyScore = calculateSafetyScore(crimeStatistics);

  // Calculate average index for overall crime trend
  const avgCrimeIndex =
    (crimeStatistics.murderIndex / 2 +
      crimeStatistics.forcibleRapeIndex / 2 +
      crimeStatistics.forcibleRobberyIndex / 2 +
      crimeStatistics.aggravatedAssaultIndex / 2 +
      crimeStatistics.burglaryIndex / 2 +
      crimeStatistics.larcenyIndex / 2 +
      crimeStatistics.motorVehicleIndex / 2) /
    7;

  return (
    <div>
      <div className="summary-item">
        <span className="summary-label">Overall Crime Index:</span>
        <span className="summary-value">
          {(crimeStatistics.crimeIndex / 2).toFixed(1)}
        </span>
      </div>
      <div className="summary-item">
        <span className="summary-label">Neighborhood Safety Rating:</span>
        <span className="summary-value">{safetyScore}/10 (SafetyScore™)</span>
      </div>
      <div className="summary-item">
        <span className="summary-label">Violent Crime Index:</span>
        <span className="summary-value">
          {Math.round(
            (crimeStatistics.murderIndex +
              crimeStatistics.forcibleRapeIndex +
              crimeStatistics.forcibleRobberyIndex +
              crimeStatistics.aggravatedAssaultIndex) /
              8
          )}
        </span>
      </div>
      <div className="summary-item">
        <span className="summary-label">Property Crime Index:</span>
        <span className="summary-value">
          {Math.round(
            (crimeStatistics.burglaryIndex +
              crimeStatistics.larcenyIndex +
              crimeStatistics.motorVehicleIndex) /
              6
          )}
        </span>
      </div>
    </div>
  );
});

export const CrimeTypeBreakdown = memo(({ locationData }) => {
  if (!locationData?.crimeStatistics) return null;

  const { crimeStatistics } = locationData;

  const data = {
    labels: [
      "Murder",
      "Forcible Rape",
      "Robbery",
      "Aggravated Assault",
      "Burglary",
      "Larceny",
      "Motor Vehicle Theft",
    ],
    datasets: [
      {
        data: [
          crimeStatistics.murderIndex / 2,
          crimeStatistics.forcibleRapeIndex / 2,
          crimeStatistics.forcibleRobberyIndex / 2,
          crimeStatistics.aggravatedAssaultIndex / 2,
          crimeStatistics.burglaryIndex / 2,
          crimeStatistics.larcenyIndex / 2,
          crimeStatistics.motorVehicleIndex / 2,
        ],
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
          "#7CBA3D",
        ],
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "left",
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.label || "";
            const value = context.raw || 0;
            return `${label}: Index ${value.toFixed(1)}`;
          },
        },
      },
    },
    animation: false,
  };

  return <Pie data={data} options={options} />;
});

const CrimeSummaryResearch = ({ data }) => (
  <div className="bg-gray-100 p-4 rounded-lg">
    <ul className="space-y-2">
      {Object.entries(data).map(([key, value]) => (
        <li key={key} className="flex justify-between crime-summary-item">
          <span className="font-medium">{key}:</span>
          <span className="crime-summary-value">{value}</span>
        </li>
      ))}
    </ul>
  </div>
);

const CrimeTypeBreakdownResearch = ({ data }) => (
  <div className="bg-gray-100 p-4 rounded-lg">
    <h3 className="text-xl font-semibold mb-2">Crime Type Distribution</h3>
    <div className="h-64">
      <Pie
        data={data}
        options={{
          maintainAspectRatio: false,
          responsive: true,
          plugins: {
            legend: {
              position: "right",
              align: "center",
            },
            tooltip: {
              callbacks: {
                label: (context) => `Index: ${(context.raw / 2).toFixed(1)}`,
              },
            },
          },
        }}
      />
    </div>
  </div>
);

const CrimeComparisonResearch = ({ data }) => (
  <div className="bg-gray-100 p-4 rounded-lg">
    <h3 className="text-xl font-semibold mb-2">Crime Category Comparison</h3>
    <div className="h-64">
      <Bar
        data={data}
        options={{
          maintainAspectRatio: false,
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: "Crime Index",
              },
            },
          },
        }}
      />
    </div>
  </div>
);

export const CrimeOverview = ({ locationData }) => {
  if (!locationData?.crimeStatistics) {
    return <div>No crime statistics available</div>;
  }

  const { crimeStatistics } = locationData;

  const violentCrimeIndex =
    (crimeStatistics.murderIndex / 2 +
      crimeStatistics.forcibleRapeIndex / 2 +
      crimeStatistics.forcibleRobberyIndex / 2 +
      crimeStatistics.aggravatedAssaultIndex / 2) /
    4;

  const propertyCrimeIndex =
    (crimeStatistics.burglaryIndex / 2 +
      crimeStatistics.larcenyIndex / 2 +
      crimeStatistics.motorVehicleIndex / 2) /
    3;

  const calculateSafetyScore = () => {
    const maxPossibleIndex = 100; // Changed from 200 to 100
    const safetyScore =
      10 * (1 - crimeStatistics.crimeIndex / 2 / maxPossibleIndex);
    return Math.max(0, Math.min(10, safetyScore)).toFixed(1);
  };

  const summaryData = {
    "Overall Crime Index": (crimeStatistics.crimeIndex / 2).toFixed(1),
    "Violent Crime Index": violentCrimeIndex.toFixed(1),
    "Property Crime Index": propertyCrimeIndex.toFixed(1),
    "Safety Score": `${calculateSafetyScore()}/10`,
  };

  const crimeTypesData = {
    labels: [
      "Murder",
      "Rape",
      "Robbery",
      "Assault",
      "Burglary",
      "Larceny",
      "Vehicle Theft",
    ],
    datasets: [
      {
        data: [
          crimeStatistics.murderIndex / 2,
          crimeStatistics.forcibleRapeIndex / 2,
          crimeStatistics.forcibleRobberyIndex / 2,
          crimeStatistics.aggravatedAssaultIndex / 2,
          crimeStatistics.burglaryIndex / 2,
          crimeStatistics.larcenyIndex / 2,
          crimeStatistics.motorVehicleIndex / 2,
        ],
        backgroundColor: [
          "rgba(255, 99, 132, 0.6)",
          "rgba(54, 162, 235, 0.6)",
          "rgba(255, 206, 86, 0.6)",
          "rgba(75, 192, 192, 0.6)",
          "rgba(153, 102, 255, 0.6)",
          "rgba(255, 159, 64, 0.6)",
          "rgba(99, 255, 132, 0.6)",
        ],
      },
    ],
  };

  const crimeComparisonData = {
    labels: ["Violent Crimes", "Property Crimes"],
    datasets: [
      {
        label: "Crime Index by Category",
        data: [violentCrimeIndex, propertyCrimeIndex],
        backgroundColor: ["rgba(255, 99, 132, 0.6)", "rgba(54, 162, 235, 0.6)"],
      },
    ],
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <CrimeSummaryResearch data={summaryData} />
        <CrimeTypeBreakdownResearch data={crimeTypesData} />
        <CrimeComparisonResearch data={crimeComparisonData} />
      </div>
    </div>
  );
};

const PercentileBar = ({ value = 0, label = "" }) => {
  // Normalize to 0-100 scale
  const safeValue = (Number(value) || 0) / 2;

  const getSegmentWidth = (start, end) => {
    if (safeValue >= start && safeValue <= end) {
      return ((safeValue - start) / (end - start)) * 20;
    } else if (safeValue > end) {
      return 20;
    }
    return 0;
  };

  const segments = [
    { className: "percentile-bar-segment-safe", width: getSegmentWidth(0, 20) },
    {
      className: "percentile-bar-segment-mostly-safe",
      width: getSegmentWidth(20, 40),
    },
    {
      className: "percentile-bar-segment-average",
      width: getSegmentWidth(40, 60),
    },
    {
      className: "percentile-bar-segment-less-safe",
      width: getSegmentWidth(60, 80),
    },
    {
      className: "percentile-bar-segment-unsafe",
      width: getSegmentWidth(80, 100),
    },
  ];

  return (
    <div className="percentile-bar-wrapper">
      <div className="percentile-bar-header">
        <span className="percentile-bar-label">{label}</span>
        <span className="percentile-bar-value">{safeValue.toFixed(1)}th</span>
      </div>
      <div className="percentile-bar-container">
        {segments.map(
          (segment, index) =>
            segment.width > 0 && (
              <div
                key={index}
                className={`percentile-bar-segment ${segment.className}`}
                style={{ width: `${segment.width}%` }}
              />
            )
        )}
      </div>
    </div>
  );
};

export const ViolentCrimeBar = memo(({ locationData }) => {
  if (!locationData?.crimeStatistics) {
    return (
      <div className="crime-stats-error">No crime statistics available</div>
    );
  }

  const { crimeStatistics } = locationData;

  const metrics = [
    { key: "crimeIndex", label: "Overall Crime" },
    { key: "murderIndex", label: "Murder" },
    { key: "forcibleRobberyIndex", label: "Robbery" },
    { key: "aggravatedAssaultIndex", label: "Assault" },
  ].filter((metric) => typeof crimeStatistics[metric.key] !== "undefined");

  if (metrics.length === 0) {
    return (
      <div className="crime-stats-error">Invalid crime statistics data</div>
    );
  }

  return (
    <div>
      <div className="percentile-markers-container">
        <span className="percentile-bar-marker">0%</span>
        <span className="percentile-bar-marker">25%</span>
        <span className="percentile-bar-marker">50%</span>
        <span className="percentile-bar-marker">75%</span>
        <span className="percentile-bar-marker">100%</span>
      </div>
      <div className="crime-metrics-grid">
        {metrics.map(({ key, label }) => (
          <PercentileBar key={key} value={crimeStatistics[key]} label={label} />
        ))}
      </div>
      <div className="crime-stats-legend">
        <strong>How to read this:</strong> Lower percentiles (greener) indicate
        safer conditions.
      </div>
    </div>
  );
});

export const PropertyCrimeBar = memo(({ locationData }) => {
  if (!locationData?.crimeStatistics) {
    return (
      <div className="crime-stats-error">No crime statistics available</div>
    );
  }

  const { crimeStatistics } = locationData;

  const metrics = [
    { key: "mortalityIndex", label: "Mortality" },
    { key: "burglaryIndex", label: "Burglary" },
    { key: "larcenyIndex", label: "Larceny" },
    { key: "motorVehicleIndex", label: "Vehicle Theft" },
  ].filter((metric) => typeof crimeStatistics[metric.key] !== "undefined");

  if (metrics.length === 0) {
    return (
      <div className="crime-stats-error">Invalid crime statistics data</div>
    );
  }

  return (
    <div>
      <div className="percentile-markers-container">
        <span className="percentile-bar-marker">0%</span>
        <span className="percentile-bar-marker">25%</span>
        <span className="percentile-bar-marker">50%</span>
        <span className="percentile-bar-marker">75%</span>
        <span className="percentile-bar-marker">100%</span>
      </div>
      <div className="crime-metrics-grid">
        {metrics.map(({ key, label }) => (
          <PercentileBar key={key} value={crimeStatistics[key]} label={label} />
        ))}
      </div>
      <div className="crime-stats-legend">
        <strong>How to read this:</strong> Lower percentiles (greener) indicate
        safer conditions.
      </div>
    </div>
  );
});

const CrimeTab = ({ ContentBox, locationData }) => {
  console.log("CrimeTab rendering with data:", locationData);

  return (
    <>
      <ContentBox
        title="Violent Crime Analysis"
        key="ViolentCrimeBar"
        subtype="crime"
        contentType="ViolentCrimeBar"
      >
        <ViolentCrimeBar locationData={locationData} />
      </ContentBox>
      <ContentBox
        title="Property Crime Analysis"
        key="PropertyCrimeBar"
        subtype="crime"
        contentType="PropertyCrimeBar"
      >
        <PropertyCrimeBar locationData={locationData} />
      </ContentBox>
    </>
  );
};

export default CrimeTab;
