import React from 'react';
import { PopStatAgeDem, PopStatEducPct, PopStatHsldAgg, PopStatHsngOcpd, PopStatPopAgg, PopStatQuickStats, PopStatRacialDem, PopStatTransport,   MarketModePopStatRacialDem,
    MarketModePopStatTransport,
    MarketModePopStatHsngOcpd,
    MarketModePopStatEducPct,
    MarketModePopStatAgeDem
  } from './contentTabs/PopulationTab';
  
import { 
    SchoolEnrlmtLevelPcts, 
    SchoolEnrlmtTypePcts,
    MarketModeSchoolEnrlmtLevelPcts,
    MarketModeSchoolEnrlmtTypePcts 
} from './contentTabs/SchoolsTab';
import { ViolentCrimeBar, PropertyCrimeBar } from './contentTabs/CrimeTab';
import { IncomeQuickStats, IncomeHsldPct, MarketModeIncomeHsldPct } from './contentTabs/IncomeTab';
import { RecentHomeSalesSummary, RecentHomeSalesList } from './contentTabs/RecentHomeSalesTab';
import { TopGrowthComparison, MedianIncomeComparison, MarketModeTopGrowthComparison, MarketModeMedianIncomeComparison } from './contentTabs/DynamicNeighborhoodComparisonTab';
import { NeighborhoodComparisonTable } from './contentTabs/TabularNeighborhoodComparisonTab';
import { ValuationTab } from './contentTabs/AlgoTab';
import { LocMetQuickStats, LocMetHsngValue, LocMetHsngBltYr,  MarketModeLocMetHsngValue,
    MarketModeLocMetHsngBltYr } from './contentTabs/LocationMetricsTab';

export const contentBoxRegistry = {
    PopStatAgeDem,
    PopStatEducPct,
    PopStatHsldAgg,
    PopStatHsngOcpd,
    PopStatPopAgg,
    PopStatQuickStats,
    PopStatRacialDem,
    PopStatTransport,
    SchoolEnrlmtLevelPcts,
    SchoolEnrlmtTypePcts,
    ViolentCrimeBar,
    PropertyCrimeBar,
    IncomeQuickStats,
    IncomeHsldPct,
    RecentHomeSalesSummary,
    RecentHomeSalesList,
    TopGrowthComparison,
    MedianIncomeComparison,
    NeighborhoodComparisonTable,
    ValuationTab,
    LocMetQuickStats,
    LocMetHsngValue,
    LocMetHsngBltYr
};

export const marketModeComponents = {
    SchoolEnrlmtLevelPcts: MarketModeSchoolEnrlmtLevelPcts,
    SchoolEnrlmtTypePcts: MarketModeSchoolEnrlmtTypePcts,
    PopStatRacialDem: MarketModePopStatRacialDem,
    PopStatTransport: MarketModePopStatTransport,
    PopStatHsngOcpd: MarketModePopStatHsngOcpd,
    PopStatEducPct: MarketModePopStatEducPct,
    PopStatAgeDem: MarketModePopStatAgeDem,
    LocMetHsngValue: MarketModeLocMetHsngValue,
    LocMetHsngBltYr: MarketModeLocMetHsngBltYr,
    IncomeHsldPct: MarketModeIncomeHsldPct,
    TopGrowthComparison: MarketModeTopGrowthComparison,
    MedianIncomeComparison: MarketModeMedianIncomeComparison
  };

  export const transformContentForMarketMode = (content, contentType) => {
    if (!content || !contentType) return content;

    console.log('Transforming content:', {
        contentType,
        hasMarketMode: !!marketModeComponents[contentType],
        props: content?.props,
        marketModeComponent: marketModeComponents[contentType]
    });

    // Check if we have a market mode version for this content type
    const MarketModeComponent = marketModeComponents[contentType];
    if (MarketModeComponent) {
        console.log('Creating market mode component for:', contentType);
        try {
            const transformedComponent = <MarketModeComponent {...content.props} />;
            console.log('Successfully created market mode component');
            return transformedComponent;
        } catch (error) {
            console.error('Error creating market mode component:', error);
            return content;
        }
    }

    return content;
};

export const recreateContent = (contentType, props) => {
    if (!contentType) {
        console.warn('Undefined content type passed to recreateContent');
        return null;
    }

    const Component = contentBoxRegistry[contentType];
    if (!Component) {
        console.warn(`Unknown content type: ${contentType}`);
        return null;
    }
    return <Component {...props} />;
};

export const getContentType = (component) => {
    if (!component || !component.type) {
        console.warn('Invalid component passed to getContentType');
        return undefined;
    }
    return component.type.name || component.type.displayName || 'UnknownComponent';
};

export const serializeContentProps = (component) => {
    if (!component || !component.props) return {};
    const { children, ...serializableProps } = component.props;
    return serializableProps;
};