import React, { useState, useEffect, useRef, useMemo } from 'react';
import { FaSearch, FaArrowRight, FaChevronDown } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import './ResearchPage.css';
import plusIcon from "../assets/Plus_Black.svg";
import ResearchPageCard from './ResearchPageCard';
import { fetchRhodeIslandLocations } from '../utils/locationData';
import ResearchPageContent from './ResearchPageContent';
import Fuse from 'fuse.js';
import { fetchLocationStatistics, fetchMultipleRankings } from '../actions/auth';
import blueIcon from '../assets/SVG-realanalytica-icon-blue.svg';
import ResearchTutorial from './ResearchTutorial';

const FlashingBorder = ({ children, isFlashing, color = "#1352E2", className = "" }) => {
    return (
        <div className={`flashing-border-container ${className}`}>
            {isFlashing && (
                <div className="flashing-border-overlay" style={{ borderColor: color }} />
            )}
            {children}
        </div>
    );
};

const colors = ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728'];

const ResearchPage = ({
    selectedCity,
    setSelectedCity,
    selectedNeighborhoods,
    setSelectedNeighborhoods,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    activeFilter,
    setActiveFilter,
    globalSelectedDataType,
    setGlobalSelectedDataType,
    selectedItem,
    setSelectedItem
}) => {
    const quickFilters = ['3M', '6M', '1Y', '2Y'];

    const [allLocations, setAllLocations] = useState([]);
    const [cities, setCities] = useState([]);
    const [neighborhoods, setNeighborhoods] = useState([]);

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [citySearchQuery, setCitySearchQuery] = useState('');
    const [neighborhoodSearchQuery, setNeighborhoodSearchQuery] = useState('');
    const [loadContent, setLoadContent] = useState(false);
    const [neighborhoodColors, setNeighborhoodColors] = useState({});
    const dropdownRef = useRef(null);
    const [neighborhoodData, setNeighborhoodData] = useState({});
    const [dataLoadingStates, setDataLoadingStates] = useState({});

    const [showCityFlash, setShowCityFlash] = useState(true);
    const [showSelectionFlash, setShowSelectionFlash] = useState(false);
    const [showDataTypeFlash, setShowDataTypeFlash] = useState(false);
    const [isLoadingLocations, setIsLoadingLocations] = useState(true);
    const [locationError, setLocationError] = useState(null);
    const [isLoadingNeighborhoods, setIsLoadingNeighborhoods] = useState(false);
    const [neighborhoodError, setNeighborhoodError] = useState(null);

    const [rankingsDataMap, setRankingsDataMap] = useState({});
    const [rankingsLoadingStates, setRankingsLoadingStates] = useState({});
    const [rankingsErrors, setRankingsErrors] = useState({});
    const [isExpanded, setIsExpanded] = useState(true);

    // Update flashing states based on selections
    useEffect(() => {
        if (selectedCity) {
            setShowCityFlash(false);
            if (!neighborhoodError) {
                setShowSelectionFlash(true);
            } else {
                setShowDataTypeFlash(true);
            }
        }
    }, [selectedCity, neighborhoodError]);

    useEffect(() => {
        if (selectedNeighborhoods.length > 0) {
            setShowSelectionFlash(false);
            setShowDataTypeFlash(true);
        }
    }, [selectedNeighborhoods]);

    useEffect(() => {
        if (selectedItem) {
            setShowDataTypeFlash(false);
        }
    }, [selectedItem]);

    useEffect(() => {
        const fetchLocations = async () => {
            setIsLoadingLocations(true);
            setLocationError(null);
            try {
                const locations = await fetchRhodeIslandLocations();
                if (locations && locations.length > 0) {
                    setAllLocations(locations);
                    const cityLocations = locations.filter(loc => loc.type === 'city');
                    if (cityLocations.length > 0) {
                        setCities(cityLocations);
                    } else {
                        setLocationError("No cities available");
                    }
                } else {
                    setLocationError("Unable to load locations");
                }
            } catch (error) {
                console.error("Error fetching Rhode Island locations:", error);
                setLocationError("Unable to load locations");
            } finally {
                setIsLoadingLocations(false);
            }
        };

        fetchLocations();
    }, []);

    // Add effect to fetch data when neighborhoods are selected
    useEffect(() => {
        const fetchLocationData = async (neighborhood) => {
            // Skip if we already have data for this neighborhood
            if (neighborhoodData[neighborhood.id]) return;

            setDataLoadingStates(prev => ({
                ...prev,
                [neighborhood.id]: true
            }));

            try {
                const data = await fetchLocationStatistics(neighborhood.id.trim());
                console.log(`Fetched data for ${neighborhood.name}:`, data);

                setNeighborhoodData(prev => ({
                    ...prev,
                    [neighborhood.id]: data
                }));
            } catch (err) {
                console.error(`Error fetching data for ${neighborhood.name}:`, err);
            } finally {
                setDataLoadingStates(prev => ({
                    ...prev,
                    [neighborhood.id]: false
                }));
            }
        };

        // Fetch data for each selected neighborhood that we don't have data for yet
        selectedNeighborhoods.forEach(neighborhood => {
            if (!neighborhoodData[neighborhood.id]) {
                fetchLocationData(neighborhood);
            }
        });
    }, [selectedNeighborhoods]);

    // Add cleanup of neighborhood data when neighborhoods are removed
    useEffect(() => {
        const currentIds = selectedNeighborhoods.map(n => n.id);
        setNeighborhoodData(prev => {
            const newData = { ...prev };
            Object.keys(newData).forEach(id => {
                if (!currentIds.includes(id)) {
                    delete newData[id];
                }
            });
            return newData;
        });

        setDataLoadingStates(prev => {
            const newStates = { ...prev };
            Object.keys(newStates).forEach(id => {
                if (!currentIds.includes(id)) {
                    delete newStates[id];
                }
            });
            return newStates;
        });
    }, [selectedNeighborhoods]);

    useEffect(() => {
        updateNeighborhoodColors(selectedNeighborhoods);
    }, [selectedNeighborhoods]);

    useEffect(() => {
        if (selectedCity) {
            setIsLoadingNeighborhoods(true);
            setNeighborhoodError(null);

            // Filter neighborhoods for the selected city
            const cityNeighborhoods = allLocations.filter(loc =>
                loc.type === 'neighborhood' &&
                loc.fullName.includes(selectedCity.name)
            );

            if (cityNeighborhoods.length > 0) {
                setNeighborhoods(cityNeighborhoods);
            } else {
                setNeighborhoodError("Neighborhoods unavailable");
            }
            setIsLoadingNeighborhoods(false);
        } else {
            setNeighborhoods([]);
            setNeighborhoodError(null);
        }
    }, [selectedCity, allLocations]);

    const memoizedFetchRankings = useMemo(() => {
        const fetchRankingsData = async (neighborhood, locationData) => {
            // Skip if we already have rankings for this neighborhood
            if (rankingsDataMap[neighborhood.id]) {
                console.log(`Using cached rankings for ${neighborhood.name}`);
                return;
            }

            // Skip if we don't have location data yet
            if (!locationData) {
                console.log(`Waiting for location data for ${neighborhood.name}`);
                return;
            }

            // Skip if we're already loading rankings for this neighborhood
            if (rankingsLoadingStates[neighborhood.id]) {
                console.log(`Already fetching rankings for ${neighborhood.name}`);
                return;
            }

            setRankingsLoadingStates(prev => ({
                ...prev,
                [neighborhood.id]: true
            }));

            try {
                console.log(`Fetching new rankings for ${neighborhood.name}`);
                const rankings = await fetchMultipleRankings(
                    neighborhood.id,
                    locationData.geoType,
                    locationData.geoType === 'NEIGHBORHOOD'
                        ? locationData.cityId?.trim()
                        : locationData.stateId?.trim()
                );

                setRankingsDataMap(prev => ({
                    ...prev,
                    [neighborhood.id]: rankings
                }));
            } catch (err) {
                console.error(`Error fetching rankings for ${neighborhood.name}:`, err);
                setRankingsErrors(prev => ({
                    ...prev,
                    [neighborhood.id]: err.message
                }));
            } finally {
                setRankingsLoadingStates(prev => ({
                    ...prev,
                    [neighborhood.id]: false
                }));
            }
        };

        return fetchRankingsData;
    }, [rankingsDataMap, rankingsLoadingStates]);

    // Replace the existing rankings fetch effect with this new one
    useEffect(() => {
        const isDynamicComparisonSelected =
            globalSelectedDataType === "Dynamic Comparison" ||
            selectedItem?.title === "Dynamic Comparison";

        if (!isDynamicComparisonSelected) {
            return; // Don't fetch rankings if Dynamic Comparison isn't selected
        }

        const pendingFetches = new Set();

        selectedNeighborhoods.forEach(neighborhood => {
            const locationData = neighborhoodData[neighborhood.id];

            if (locationData && !rankingsDataMap[neighborhood.id] && !pendingFetches.has(neighborhood.id)) {
                pendingFetches.add(neighborhood.id);
                memoizedFetchRankings(neighborhood, locationData);
            }
        });
    }, [
        selectedNeighborhoods,
        neighborhoodData,
        memoizedFetchRankings,
        globalSelectedDataType,
        selectedItem
    ]);

    // Add this cleanup effect to clear rankings data when changing data types
    useEffect(() => {
        if (globalSelectedDataType !== "Dynamic Comparison") {
            setRankingsDataMap({});
            setRankingsLoadingStates({});
            setRankingsErrors({});
        }
    }, [globalSelectedDataType]);

    const handleCardDataTypeChange = (newDataType, neighborhood) => {
        if (newDataType === "Dynamic Comparison" && !rankingsDataMap[neighborhood.id]) {
            // Fetch rankings for this specific neighborhood
            const locationData = neighborhoodData[neighborhood.id];
            if (locationData) {
                memoizedFetchRankings(neighborhood, locationData);
            }
        }
    };

    const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

    const handleItemClick = (item) => {
        setSelectedItem(item);
        setGlobalSelectedDataType(item.title);
        setIsDropdownOpen(false);
        setLoadContent(true);
    };

    const handleQuickFilterClick = (filter) => {
        setActiveFilter(filter);
        const end = new Date();
        let start = new Date(end);

        switch (filter) {
            case '1M':
                start.setMonth(end.getMonth() - 1);
                break;
            case '3M':
                start.setMonth(end.getMonth() - 3);
                break;
            case '6M':
                start.setMonth(end.getMonth() - 6);
                break;
            case '1Y':
                start.setFullYear(end.getFullYear() - 1);
                break;
            case '2Y':
                start.setFullYear(end.getFullYear() - 2);
                break;
            default:
                return;
        }

        setStartDate(start);
        setEndDate(end);
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
        setActiveFilter(null);

        // Ensure minimum 3-month span
        const minEndDate = new Date(date);
        minEndDate.setMonth(minEndDate.getMonth() + 3);
        if (endDate < minEndDate) {
            setEndDate(minEndDate);
        }
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
        setActiveFilter(null);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleCitySelect = (city) => {
        setSelectedCity(city);
        setCitySearchQuery('');
        setNeighborhoods([]); // Clear neighborhoods while loading new ones

        // Filter neighborhoods for the selected city
        const cityNeighborhoods = allLocations.filter(loc =>
            loc.type === 'neighborhood' &&
            loc.fullName.includes(city.name)
        );
        setNeighborhoods(cityNeighborhoods);
    };

    const handleAddCityCard = () => {
        if (selectedCity && selectedNeighborhoods.length < 4 && !selectedNeighborhoods.some(n => n.id === selectedCity.id)) {
            const cityAsSelection = {
                ...selectedCity,
                isCity: true
            };
            const newNeighborhoods = [...selectedNeighborhoods, cityAsSelection];
            setSelectedNeighborhoods(newNeighborhoods);
            updateNeighborhoodColors(newNeighborhoods);
        }
    };

    const handleNeighborhoodSelect = (neighborhood) => {
        if (selectedNeighborhoods.length < 4 && !selectedNeighborhoods.some(n => n.id === neighborhood.id)) {
            const newNeighborhoods = [...selectedNeighborhoods, neighborhood];
            setSelectedNeighborhoods(newNeighborhoods);
            setNeighborhoodSearchQuery('');
            updateNeighborhoodColors(newNeighborhoods);
        }
    };

    const removeCity = () => {
        setSelectedCity(null);
        // No longer clear selected neighborhoods when removing selected city
        setNeighborhoods([]);
    };

    const removeNeighborhood = (item) => {
        const newItems = selectedNeighborhoods.filter(n => n.id !== item.id);
        setSelectedNeighborhoods(newItems);
        updateNeighborhoodColors(newItems);

        // If removing current selected city, clear it
        if (item.isCity && selectedCity?.id === item.id) {
            setSelectedCity(null);
            setNeighborhoods([]);
        }
    };

    const updateNeighborhoodColors = (neighborhoods) => {
        const newColors = {};
        neighborhoods.forEach((neighborhood, index) => {
            newColors[neighborhood.id] = colors[index % colors.length];
        });
        setNeighborhoodColors(newColors);
    };

    const searchLocations = (query, locations) => {
        const fuse = new Fuse(locations, {
            keys: ['name', 'fullName'],
            threshold: 0.3,
        });
        return fuse.search(query)
            .map(result => result.item)
            .filter(item => !selectedNeighborhoods.some(n => n.id === item.id));
    };

    const filteredCities = citySearchQuery
        ? searchLocations(citySearchQuery, cities)
        : [];

    const filteredNeighborhoods = neighborhoodSearchQuery
        ? searchLocations(neighborhoodSearchQuery, neighborhoods)
        : [];

    const dropdownItems = [
        {
            title: "Algorithm",
            subtitle: "Valuation Index"
        },
        {
            title: "Recent Home Sales",
            subtitle: "Recent Sales Summary | Latest Sales"
        },
        {
            title: "Location Metrics",
            subtitle: "Location Summary | Household Valuations | Housing Built Years"
        },
        {
            title: "Population",
            subtitle: "Quick Population Statistics | Population Growth Trends | Household Trends..."
        },
        {
            title: "School",
            subtitle: "Enrollment by Grade Level | Public vs Private Enrollment"
        },
        {
            title: "Crime",
            subtitle: "Violent Crime Analysis | Property Crime Analysis"
        },
        {
            title: "Income",
            subtitle: "Quick Income Statistics | Income Distribution"
        },
        {
            title: "Dynamic Comparison",
            subtitle: "Population Comparison | Income Comparison"
        }
    ];


    return (
        <div className="research-page">
            <div className="research-top-container">
                <div className="research-search-container">
                    <FlashingBorder isFlashing={showCityFlash}>
                        <div className="research-search-bar" data-key="firstInput">
                            <FaSearch className="research-search-icon" />
                            <input
                                type="text"
                                placeholder={
                                    isLoadingLocations
                                        ? "Fetching data, please wait a moment..."
                                        : locationError
                                            ? "Data unavailable. Please try again later."
                                            : "Search city of interest"
                                }
                                className="research-search-input"
                                value={citySearchQuery}
                                onChange={(e) => setCitySearchQuery(e.target.value)}
                                disabled={isLoadingLocations || locationError}
                            />
                            {selectedNeighborhoods.length !== 4 && (
                                <button className="research-add-button">
                                    <img src={plusIcon} alt="Add" />
                                </button>
                            )}
                            {!isLoadingLocations && !locationError && filteredCities.length > 0 && citySearchQuery && (
                                <div className="research-search-results">
                                    {filteredCities.map((city) => (
                                        <div
                                            key={city.id}
                                            className="research-search-result"
                                            onClick={() => handleCitySelect(city)}
                                        >
                                            {city.name}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </FlashingBorder>

                    <FlashingBorder isFlashing={showSelectionFlash && selectedCity && !neighborhoodError}>
                        <div className="research-search-bar" data-key="secondInput">
                            <FaSearch className="research-search-icon" />
                            <input
                                type="text"
                                placeholder={
                                    !selectedCity
                                        ? "Select a city first"
                                        : isLoadingNeighborhoods
                                            ? "Fetching neighborhoods..."
                                            : neighborhoodError
                                                ? "Neighborhoods unavailable"
                                                : "Search neighborhoods of interest"
                                }
                                className="research-search-input"
                                value={neighborhoodSearchQuery}
                                onChange={(e) => setNeighborhoodSearchQuery(e.target.value)}
                                disabled={!selectedCity || isLoadingNeighborhoods || neighborhoodError}
                            />
                            {selectedNeighborhoods.length !== 4 && (
                                <button className="research-add-button">
                                    <img src={plusIcon} alt="Add" />
                                </button>
                            )}
                            {!isLoadingNeighborhoods && !neighborhoodError && filteredNeighborhoods.length > 0 && neighborhoodSearchQuery && (
                                <div className="research-search-results">
                                    {filteredNeighborhoods.map((neighborhood) => (
                                        <div
                                            key={neighborhood.id}
                                            className="research-search-result"
                                            onClick={() => handleNeighborhoodSelect(neighborhood)}
                                        >
                                            {neighborhood.name}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </FlashingBorder>
                </div>
                <div className="research-quick-filter-container">
                    <div className="research-filter-label">Quick Filter</div>
                    <div className="research-quick-filter-buttons">
                        {quickFilters.map((filter, index) => (
                            <button
                                key={index}
                                className={`research-quick-filter-button ${activeFilter === filter ? 'active' : ''}`}
                                onClick={() => handleQuickFilterClick(filter)}
                            >
                                {filter}
                            </button>
                        ))}
                    </div>
                </div>
                <div className="research-date-filter-container">
                    <div className="research-filter-label">Filter by Date</div>
                    <div className="research-date-filter-content">
                        <DatePicker
                            selected={startDate}
                            onChange={handleStartDateChange}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            maxDate={new Date(endDate.getTime() - 90 * 24 * 60 * 60 * 1000)} // 90 days minimum
                            className="research-date-input"
                            dateFormat="MM/dd/yy"
                        />
                        <FaArrowRight className="research-date-arrow" />
                        <DatePicker
                            selected={endDate}
                            onChange={handleEndDateChange}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            minDate={new Date(startDate.getTime() + 90 * 24 * 60 * 60 * 1000)} // 90 days minimum
                            maxDate={new Date()}
                            className="research-date-input"
                            dateFormat="MM/dd/yy"
                        />
                    </div>
                </div>
                <FlashingBorder isFlashing={showDataTypeFlash}>
                    <div className="research-dropdown-container" ref={dropdownRef}>
                        <div className="research-dropdown" onClick={toggleDropdown}>
                            <span className="research-dropdown-placeholder">
                                {selectedItem ? selectedItem.title : "Select data of interest"}
                            </span>
                            <FaChevronDown className="research-dropdown-icon" />
                        </div>
                        {isDropdownOpen && (
                            <div className="research-dropdown-menu">
                                {dropdownItems.map((item, index) => (
                                    <div
                                        key={index}
                                        className="research-dropdown-item"
                                        onClick={() => handleItemClick(item)}
                                        data-type={item.title}
                                    >
                                        <span className="research-dropdown-item-title">{item.title}</span>
                                        <span className="research-dropdown-item-subtitle">{item.subtitle}</span>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </FlashingBorder>
            </div>
            <div className="research-selections-wrapper">
                <div className="research-selections-labels">
                    <h3>City of Interest</h3>
                    <h3>Neighborhoods of Interest</h3>
                    <h3>Data Points of Interest</h3>
                </div>
                <div className="research-selections-container">
                    <div className="research-selection-section city-section">
                        {selectedCity && (
                            <div className="city-selection-container">
                                <div
                                    className="research-selection-item"
                                    onClick={removeCity}
                                >
                                    {selectedCity.name}
                                </div>
                                <button
                                    className="add-city-button"
                                    onClick={handleAddCityCard}
                                    disabled={selectedNeighborhoods.some(n => n.id === selectedCity.id) || selectedNeighborhoods.length >= 4}
                                >
                                    Add City Card
                                </button>
                            </div>
                        )}
                    </div>

                    <div className="vertical-divider"></div>
                    <div className="vertical-divider"></div>
                    <div className="research-selection-section neighborhoods-section">
                        <div className="neighborhoods-header">
                            <div className="neighborhood-pills">
                                {selectedNeighborhoods.map((neighborhood) => (
                                    <div
                                        key={neighborhood.id}
                                        className="research-selection-item neighborhood-item"
                                        onClick={() => removeNeighborhood(neighborhood)}
                                    >
                                        <span
                                            className="neighborhood-color-circle"
                                            style={{ backgroundColor: neighborhoodColors[neighborhood.id] }}
                                        ></span>
                                        {neighborhood.name}
                                    </div>
                                ))}
                            </div>
                            {selectedNeighborhoods.length > 0 && (
                                <button
                                    className="clear-selections-button"
                                    onClick={() => setSelectedNeighborhoods([])}
                                >
                                    Clear Selections
                                </button>
                            )}
                        </div>
                    </div>
                    <div className="vertical-divider"></div>
                    <div className="research-selection-section data-point-section">
                        {selectedItem && (
                            <div
                                className="research-selection-item"
                                onClick={() => setSelectedItem(null)}
                            >
                                {selectedItem.title}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="research-content-wrapper" style={{ minHeight: '600px' }}>
                {selectedNeighborhoods.length > 0 && globalSelectedDataType ? (
                    <div className="research-cards-container">
                        {selectedNeighborhoods.map((item) => (
                            <ResearchPageCard
                                key={item.id}
                                neighborhood={{
                                    ...item,
                                    name: item.isCity ? `${item.name} (City)` : item.name
                                }}
                                globalSelectedDataType={globalSelectedDataType}
                                startDate={startDate}
                                endDate={endDate}
                                color={neighborhoodColors[item.id]}
                                locationData={neighborhoodData[item.id]}
                                rankingsData={rankingsDataMap[item.id]}
                                isLoading={dataLoadingStates[item.id] || rankingsLoadingStates[item.id]}
                                error={rankingsErrors[item.id]}
                                onDataTypeChange={handleCardDataTypeChange}
                                setIsExpanded={setIsExpanded}
                                isExpanded={isExpanded}
                            />
                        ))}
                        {[...Array(Math.max(0, 4 - selectedNeighborhoods.length))].map((_, index) => (
                            <div key={`empty-${index}`} className="empty-card"></div>
                        ))}
                    </div>
                ) : (
                    <div className="research-default-view">
                        <img src={blueIcon} alt="RealAnalytica Logo" className="research-default-logo" />
                        <div className="research-default-text-container">
                            <p className="research-default-text">
                                Welcome to the Research page! Start by entering your city of interest in the top left corner, followed by the neighborhoods of interest, finish off by selecting a date range and data category of your choice.
                            </p>
                        </div>
                    </div>
                )}
            </div>
            <ResearchTutorial
                allLocations={allLocations}
                handleCitySelect={(city) => setSelectedCity(city)}
                handleItemClick={handleItemClick}
                handleAddCityCard={handleAddCityCard}
                setIsExpanded={setIsExpanded}
            />
        </div>
    );
};

export default ResearchPage;