// LocationMetricsTab.js
import React, { memo } from "react";
import { Bar } from "react-chartjs-2";

export const LocMetQuickStats = memo(({ locationData }) => {
  if (!locationData?.housing) {
    return <div>No housing data available</div>;
  }

  const { housing } = locationData;

  // Helper function for formatting currency values
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  };

  return (
    <div>
      <div className="summary-item">
        <span className="summary-label">Median Rent:</span>
        <span className="summary-value">
          {formatCurrency(parseInt(housing.medianRent))}
        </span>
      </div>
      <div className="summary-item">
        <span className="summary-label">Median Home Value:</span>
        <span className="summary-value">
          {formatCurrency(parseInt(housing.ownerHouseholdsMedianValue))}
        </span>
      </div>
      <div className="summary-item">
        <span className="summary-label">Median Year Built:</span>
        <span className="summary-value">{housing.medianBuiltYear}</span>
      </div>
    </div>
  );
});

export const LocMetHsngValue = memo(({ locationData }) => {
  if (!locationData?.housing) return null;

  const { housing } = locationData;

  // Helper function to format currency ranges
  const formatRange = (min, max) => {
    const formatNum = (num) => {
      if (num >= 1000) {
        return `${num / 1000}M`;
      }
      return `${num}K`;
    };

    if (!max) return `$${formatNum(min)}+`;
    return `$${formatNum(min)}-${formatNum(max)}`;
  };

  // Define and aggregate value brackets
  const valueBrackets = [
    {
      label: formatRange(0, 20),
      value: Math.round(
        housing.housingOwnerHouseholdsValuedLessThan10000Pct +
          housing.housingOwnerHouseholdsValued10000To14999Pct +
          housing.housingOwnerHouseholdsValued15000To19999Pct
      ),
    },
    {
      label: formatRange(20, 35),
      value: Math.round(
        housing.housingOwnerHouseholdsValued20000To24999Pct +
          housing.housingOwnerHouseholdsValued25000To29999Pct +
          housing.housingOwnerHouseholdsValued30000To34999Pct
      ),
    },
    {
      label: formatRange(35, 60),
      value: Math.round(
        housing.housingOwnerHouseholdsValued35000To39999Pct +
          housing.housingOwnerHouseholdsValued40000To49999Pct +
          housing.housingOwnerHouseholdsValued50000To59999Pct
      ),
    },
    {
      label: formatRange(60, 90),
      value: Math.round(
        housing.housingOwnerHouseholdsValued60000To69999Pct +
          housing.housingOwnerHouseholdsValued70000To79999Pct +
          housing.housingOwnerHouseholdsValued80000To89999Pct
      ),
    },
    {
      label: formatRange(90, 150),
      value: Math.round(
        housing.housingOwnerHouseholdsValued90000To99999Pct +
          housing.housingOwnerHouseholdsValued100000To124999Pct +
          housing.housingOwnerHouseholdsValued125000To149999Pct
      ),
    },
    {
      label: formatRange(150, 250),
      value: Math.round(
        housing.housingOwnerHouseholdsValued150000To174999Pct +
          housing.housingOwnerHouseholdsValued175000To199999Pct +
          housing.housingOwnerHouseholdsValued200000To249999Pct
      ),
    },
    {
      label: formatRange(250, 500),
      value: Math.round(
        housing.housingOwnerHouseholdsValued250000To299999Pct +
          housing.housingOwnerHouseholdsValued300000To399999Pct +
          housing.housingOwnerHouseholdsValued400000To499999Pct
      ),
    },
    {
      label: formatRange(500, 1000),
      value: Math.round(
        housing.housingOwnerHouseholdsValued500000To749999Pct +
          housing.housingOwnerHouseholdsValued750000To999999Pct
      ),
    },
    {
      label: formatRange(1000),
      value: Math.round(housing.housingOwnerHouseholdsValued1000000OrMorePct),
    },
  ];

  const data = {
    labels: valueBrackets.map((bracket) => bracket.label),
    datasets: [
      {
        label: "Home Value Distribution",
        data: valueBrackets.map((bracket) => bracket.value),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  return (
    <Bar
      data={data}
      options={{
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 1.5,
        animation: false, // Disable animations for print
        devicePixelRatio: 2, // Match PRINT_CONFIG.SCALE
        scales: {
          x: {
            title: {
              display: true,
              text: "Home Value",
              font: {
                size: 12,
              },
            },
            ticks: {
              font: {
                size: 10,
              },
              maxRotation: 45,
              minRotation: 45,
            },
          },
          y: {
            title: {
              display: true,
              text: "% of Homes",
              font: {
                size: 12,
              },
            },
            beginAtZero: true,
            ticks: {
              callback: (value) => `${value}%`,
              font: {
                size: 10,
              },
            },
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: (context) => `${Math.round(context.raw)}% of homes`,
            },
          },
          legend: {
            display: false,
          },
          datalabels: {
            display: false, // Explicitly disable data labels
          },
        },
        animation: false,
        maintainAspectRatio: true,
        aspectRatio: 1.5,
      }}
    />
  );
});

export const LocMetHsngBltYr = memo(({ locationData }) => {
  if (!locationData?.housing) return null;

  const { housing } = locationData;

  // Define year ranges and labels
  const yearBrackets = [
    { label: "2010+", value: housing.builtIn2010OrLaterPct },
    { label: "2000-09", value: housing.builtIn2000To2009Pct },
    { label: "1990-99", value: housing.builtIn1990To1999Pct },
    { label: "1980-89", value: housing.builtIn1980To1989Pct },
    { label: "1970-79", value: housing.builtIn1970To1979Pct },
    { label: "1960-69", value: housing.builtIn1960To1969Pct },
    { label: "1950-59", value: housing.builtIn1950To1959Pct },
    { label: "1940-49", value: housing.builtIn1940To1949Pct },
    { label: "Pre-1939", value: housing.builtBefore1939Pct },
  ];

  const data = {
    labels: yearBrackets.map((bracket) => bracket.label),
    datasets: [
      {
        label: "Housing Age Distribution",
        data: yearBrackets.map((bracket) => Math.round(bracket.value)),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  return (
    <Bar
      data={data}
      options={{
        responsive: true,
        scales: {
          x: {
            title: {
              display: true,
              text: "Year Built",
              font: {
                size: 12,
              },
            },
            ticks: {
              font: {
                size: 10,
              },
              maxRotation: 45,
              minRotation: 45,
            },
          },
          y: {
            title: {
              display: true,
              text: "% of Homes",
              font: {
                size: 12,
              },
            },
            beginAtZero: true,
            ticks: {
              callback: (value) => `${value}%`,
              font: {
                size: 10,
              },
            },
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: (context) => `${Math.round(context.raw)}% of homes`,
            },
          },
          legend: {
            display: false,
          },
          datalabels: {
            display: false, // Explicitly disable data labels
          },
        },
        animation: false,
        maintainAspectRatio: true,
        aspectRatio: 1.5,
      }}
    />
  );
});

export const LocationMetricsOverview = ({ locationData }) => {
  if (!locationData?.housing) {
    return <div>No location metrics data available</div>;
  }

  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <style jsx global>{`
        :root {
          --summary-title-size: 1.5vh;
          --summary-item-size: 1.5vh;
          --summary-value-size: 1.5vh;
        }

        @media (min-width: 768px) {
          :root {
            --summary-title-size: 1.5vh;
            --summary-item-size: 1.5vh;
            --summary-value-size: 1.5vh;
          }
        }

        .location-summary-title {
          font-size: var(--summary-title-size);
        }

        .location-summary-item {
          font-size: var(--summary-item-size);
        }

        .location-summary-value {
          font-size: var(--summary-value-size);
          font-weight: 100;
        }
      `}</style>

      <div className="grid grid-cols-1 gap-4">
        {/* Quick Stats */}
        <div className="bg-gray-100 p-4 rounded-lg">
          <h3 className="text-xl font-semibold mb-2">Location Summary</h3>
          <LocMetQuickStats locationData={locationData} />
        </div>

        {/* Home Value Distribution Bar Chart */}
        <div className="bg-gray-100 p-4 rounded-lg">
          <h3 className="text-xl font-semibold mb-2">
            Home Value Distribution
          </h3>
          <LocMetHsngValue locationData={locationData} />
        </div>

        {/* Housing Build Years Bar Chart */}
        <div className="bg-gray-100 p-4 rounded-lg">
          <h3 className="text-xl font-semibold mb-2">
            Housing Age Distribution
          </h3>
          <LocMetHsngBltYr locationData={locationData} />
        </div>
      </div>
    </div>
  );
};

export const MarketModeLocMetHsngValue = memo(({ locationData }) => {
  if (!locationData?.housing) return null;

  const { housing } = locationData;

  // Helper function to format currency ranges
  const formatRange = (min, max) => {
    const formatNum = (num) => {
      if (num >= 1000) {
        return `${num / 1000}M`;
      }
      return `${num}K`;
    };

    if (!max) return `$${formatNum(min)}+`;
    return `$${formatNum(min)}-${formatNum(max)}`;
  };

  // Define and aggregate value brackets
  const valueBrackets = [
    {
      label: formatRange(0, 20),
      value: Math.round(
        housing.housingOwnerHouseholdsValuedLessThan10000Pct +
          housing.housingOwnerHouseholdsValued10000To14999Pct +
          housing.housingOwnerHouseholdsValued15000To19999Pct
      ),
    },
    {
      label: formatRange(20, 35),
      value: Math.round(
        housing.housingOwnerHouseholdsValued20000To24999Pct +
          housing.housingOwnerHouseholdsValued25000To29999Pct +
          housing.housingOwnerHouseholdsValued30000To34999Pct
      ),
    },
    {
      label: formatRange(35, 60),
      value: Math.round(
        housing.housingOwnerHouseholdsValued35000To39999Pct +
          housing.housingOwnerHouseholdsValued40000To49999Pct +
          housing.housingOwnerHouseholdsValued50000To59999Pct
      ),
    },
    {
      label: formatRange(60, 90),
      value: Math.round(
        housing.housingOwnerHouseholdsValued60000To69999Pct +
          housing.housingOwnerHouseholdsValued70000To79999Pct +
          housing.housingOwnerHouseholdsValued80000To89999Pct
      ),
    },
    {
      label: formatRange(90, 150),
      value: Math.round(
        housing.housingOwnerHouseholdsValued90000To99999Pct +
          housing.housingOwnerHouseholdsValued100000To124999Pct +
          housing.housingOwnerHouseholdsValued125000To149999Pct
      ),
    },
    {
      label: formatRange(150, 250),
      value: Math.round(
        housing.housingOwnerHouseholdsValued150000To174999Pct +
          housing.housingOwnerHouseholdsValued175000To199999Pct +
          housing.housingOwnerHouseholdsValued200000To249999Pct
      ),
    },
    {
      label: formatRange(250, 500),
      value: Math.round(
        housing.housingOwnerHouseholdsValued250000To299999Pct +
          housing.housingOwnerHouseholdsValued300000To399999Pct +
          housing.housingOwnerHouseholdsValued400000To499999Pct
      ),
    },
    {
      label: formatRange(500, 1000),
      value: Math.round(
        housing.housingOwnerHouseholdsValued500000To749999Pct +
          housing.housingOwnerHouseholdsValued750000To999999Pct
      ),
    },
    {
      label: formatRange(1000),
      value: Math.round(housing.housingOwnerHouseholdsValued1000000OrMorePct),
    },
  ];

  const data = {
    labels: valueBrackets.map((bracket) => bracket.label),
    datasets: [
      {
        data: valueBrackets.map((bracket) => bracket.value),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
      },
    ],
  };

  return <MarketModeBarChart data={data} />;
});
export const MarketModeLocMetHsngBltYr = memo(({ locationData }) => {
  if (!locationData?.housing) return null;

  const { housing } = locationData;

  // Define year ranges and labels
  const yearBrackets = [
    { label: "2010+", value: housing.builtIn2010OrLaterPct },
    { label: "2000-09", value: housing.builtIn2000To2009Pct },
    { label: "1990-99", value: housing.builtIn1990To1999Pct },
    { label: "1980-89", value: housing.builtIn1980To1989Pct },
    { label: "1970-79", value: housing.builtIn1970To1979Pct },
    { label: "1960-69", value: housing.builtIn1960To1969Pct },
    { label: "1950-59", value: housing.builtIn1950To1959Pct },
    { label: "1940-49", value: housing.builtIn1940To1949Pct },
    { label: "Pre-1939", value: housing.builtBefore1939Pct },
  ];

  const data = {
    labels: yearBrackets.map((bracket) => bracket.label),
    datasets: [
      {
        data: yearBrackets.map((bracket) => Math.round(bracket.value)),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
      },
    ],
  };

  return <MarketModeBarChart data={data} />;
});

// SVG-based bar chart component
const MarketModeBarChart = memo(({ data, options = {} }) => {
  const chartWidth = 400;
  const chartHeight = 200;
  const margins = { top: 20, right: 20, bottom: 50, left: 40 };
  const width = chartWidth - margins.left - margins.right;
  const height = chartHeight - margins.top - margins.bottom;

  const values = data.datasets[0].data;
  const maxValue = Math.max(...values);
  const barWidth = (width / values.length) * 0.8;
  const barSpacing = (width / values.length) * 0.2;

  return (
    <div className="flex flex-col items-center w-full">
      <div
        className="mt-6 flex justify-center"
        style={{ marginTop: "12px", display: "flex", justifyContent: "center" }}
      >
        <svg viewBox={`0 0 ${chartWidth} ${chartHeight}`}>
          <g transform={`translate(${margins.left}, ${margins.top})`}>
            {/* Y-axis */}
            <line x1="0" y1="0" x2="0" y2={height} stroke="#333" />
            {[0, 25, 50, 75, 100].map((tick, i) => (
              <g
                key={tick}
                transform={`translate(-10, ${height - (height * tick) / 100})`}
              >
                <line x1="0" x2="10" y1="0" y2="0" stroke="#333" />
                <text x="-5" y="5" textAnchor="end" fontSize="9">
                  {tick}%
                </text>
              </g>
            ))}

            {/* Bars */}
            {values.map((value, i) => (
              <g
                key={i}
                transform={`translate(${i * (barWidth + barSpacing)}, 0)`}
              >
                <rect
                  x="0"
                  y={height - (height * value) / maxValue}
                  width={barWidth}
                  height={(height * value) / maxValue}
                  fill={data.datasets[0].backgroundColor}
                />
                <text
                  x={barWidth / 2}
                  y={height + 20}
                  textAnchor="middle"
                  transform={`rotate(45, ${barWidth / 2}, ${height + 20})`}
                  fontSize="9"
                >
                  {data.labels[i]}
                </text>
              </g>
            ))}
          </g>
        </svg>
      </div>
    </div>
  );
});
const LocationMetricsTab = ({ ContentBox, locationData }) => {
  console.log("LocationMetricsTab rendering with data:", locationData);

  return (
    <>
      <ContentBox
        title="Location Summary"
        key="LocMetQuickStats"
        subtype="locationMetrics"
        contentType="LocMetQuickStats"
      >
        <LocMetQuickStats locationData={locationData} />
      </ContentBox>
      <ContentBox
        title="Household Valuations"
        key="LocMetHsngValue"
        subtype="locationMetrics"
        contentType="LocMetHsngValue"
      >
        <LocMetHsngValue locationData={locationData} />
      </ContentBox>
      <ContentBox
        title="Housing Built Years"
        key="LocMetHsngBltYr"
        subtype="locationMetrics"
        contentType="LocMetHsngBltYr"
      >
        <LocMetHsngBltYr locationData={locationData} />
      </ContentBox>
    </>
  );
};

export default LocationMetricsTab;
