import axios from 'axios';
import { signUp, signIn, fetchAuthSession, confirmSignUp, signOut, getCurrentUser, resendSignUpCode } from '@aws-amplify/auth';
import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    USER_LOADED_SUCCESS,
    USER_LOADED_FAIL,
    AUTHENTICATED_SUCCESS,
    AUTHENTICATED_FAIL,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_FAIL,
    PASSWORD_RESET_CONFIRM_SUCCESS,
    PASSWORD_RESET_CONFIRM_FAIL,
    SIGNUP_SUCCESS,
    SIGNUP_FAIL,
    ACTIVATION_SUCCESS,
    ACTIVATION_FAIL,
    GOOGLE_AUTH_SUCCESS,
    GOOGLE_AUTH_FAIL,
    CREATE_USER_SUCCESS,
    CREATE_USER_FAIL,
    CREATE_GROUP_SUCCESS,
    CREATE_GROUP_FAIL,
    LOGOUT,
    FETCH_USERS_SUCCESS,
    ADD_USER_TO_GROUP_SUCCESS,
    ADD_USER_TO_GROUP_FAIL,
    FETCH_USERS_FAIL,
    FETCH_GROUPS_SUCCESS,
    FETCH_GROUPS_FAIL,
    FETCH_MOCK_DATA_SUCCESS,
    FETCH_MOCK_DATA_FAIL,
    REMOVE_USER_GROUP_SUCCESS,
    REMOVE_USER_GROUP_FAIL,
    CHECKOUT_SESSION_SUCCESS,
    CHECKOUT_SESSION_FAIL,
    GET_CHECKOUT_SESSION_SUCCESS,
    GET_CHECKOUT_SESSION_FAIL,
    SET_USER_CUSTOMER_ID_SUCCESS,
    SET_USER_CUSTOMER_ID_FAIL,
    RETRIEVE_STRIPE_CUSTOMER_INFO_SUCCESS,
    RETRIEVE_STRIPE_CUSTOMER_INFO_FAIL,
    CREATE_BILLING_PORTAL_SUCCESS,
    CREATE_BILLING_PORTAL_FAIL,
    ADD_TO_MAILING_LIST_SUCCESS,
    ADD_TO_MAILING_LIST_FAIL,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAIL,
    CHECK_EMAIL_AVAILABILITY_SUCCESS,
    CHECK_EMAIL_AVAILABILITY_FAIL,
    DELETE_PROFILE_PICTURE_SUCCESS,
    DELETE_PROFILE_PICTURE_FAIL,
    CREATE_BLOG_POST_SUCCESS,
    CREATE_BLOG_POST_FAIL,
    FETCH_BLOG_POSTS_SUCCESS,
    FETCH_BLOG_POSTS_FAIL,
    FETCH_BLOG_POST_SUCCESS,
    FETCH_BLOG_POST_FAIL,
    DELETE_BLOG_POST_SUCCESS,
    DELETE_BLOG_POST_FAIL
} from './types';
import imageCompression from 'browser-image-compression';


export const get_current_user = async () => {
    try {
        const user = await getCurrentUser();
        const session = await fetchAuthSession();
        const token = session.tokens.idToken.toString();

        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/user/me`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }
        );

        return response.data;
    } catch (err) {
        console.error('Error fetching user data:', err);
        throw err;
    }
};

export const updateUser = async (userData) => {
    try {
        const currentUser = await get_current_user();
        const session = await fetchAuthSession();
        const token = session.tokens.idToken.toString();

        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/user`,
            userData,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                params: {
                    'user_id': currentUser.id
                }
            }
        );

        return response.data;
    } catch (err) {
        console.error('Error updating user data:', err);
        throw err;
    }
};

export const googleAuthenticate = (state, code) => async dispatch => {
    if (state && code && !localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        };

        const details = {
            'state': state,
            'code': code
        };

        const formBody = Object.keys(details).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(details[key])).join('&');

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/o/google-oauth2/?${formBody}`, config);
            dispatch({
                type: GOOGLE_AUTH_SUCCESS,
                payload: res.data
            });
            // dispatch(load_user());
        } catch (err) {
            console.error('Google authentication failed', err);
            dispatch({
                type: GOOGLE_AUTH_FAIL
            });
        }
    }
};

// export const checkAuthenticated = () => async dispatch => {
//     if (localStorage.getItem('access')) {
//         const config = {
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Accept': 'application/json'
//             }
//         };

//         const body = JSON.stringify({ token: localStorage.getItem('access') });

//         try {
//             const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/jwt/verify/`, body, config)

//             if (res.data.code !== 'token_not_valid') {
//                 dispatch({
//                     type: AUTHENTICATED_SUCCESS
//                 });
//             } else {
//                 dispatch({
//                     type: AUTHENTICATED_FAIL
//                 });
//             }
//         } catch (err) {
//             dispatch({
//                 type: AUTHENTICATED_FAIL
//             });
//         }

//     } else {
//         dispatch({
//             type: AUTHENTICATED_FAIL
//         });
//     }
// };

// Add this to your auth.js
export const resendVerificationCode = async (username) => {
    try {
        await resendSignUpCode({
            username
        });
        return { success: true };
    } catch (err) {
        console.error('Error resending verification code:', err);
        throw err;
    }
};

export const checkAuthStatus = async () => {
    try {
        await getCurrentUser();
        const session = await fetchAuthSession();
        return {
            isAuthenticated: true,
            session: session
        };
    } catch (err) {
        return {
            isAuthenticated: false,
            session: null
        };
    }
};

export const loginUser = async (username, password) => {
    try {
        const signInResult = await signIn({ username, password });

        if (signInResult.isSignedIn) {
            const session = await fetchAuthSession();
            return {
                success: true,
                session: session
            };
        }
        throw new Error('Sign in failed');
    } catch (err) {
        console.error('Login error:', err);
        throw err;
    }
};

export const getCurrentSession = async () => {
    try {
        const session = await fetchAuthSession();
        return session;
    } catch (err) {
        console.error('Error getting current session:', err);
        return null;
    }
};

export const login = async (username, password) => {
    try {
        // First try to sign out any existing user
        try {
            await signOut();
        } catch (signOutError) {
            console.log('No user to sign out or sign out failed:', signOutError);
        }

        // Now try to sign in
        const signInResult = await signIn({
            username,
            password
        });

        if (signInResult.isSignedIn) {
            const session = await fetchAuthSession();
            return {
                success: true,
                session: session
            };
        }

        throw new Error('Sign in failed');
    } catch (err) {
        console.error('Login error:', err);
        throw err;
    }
};



export const createCognitoUser = async (email, password, firstName, lastName) => {
    try {
        const { userId, userConfirmed, nextStep } = await signUp({
            username: email,
            password,
            attributes: {
                email,
                given_name: firstName,
                family_name: lastName,
            }
        });

        return {
            success: true,
            userId, // This is the Cognito User ID we need
            userConfirmed,
            nextStep
        };
    } catch (err) {
        console.error('Error creating Cognito user:', err);
        throw err;
    }
};

export const verifyEmail = async (username, confirmationCode) => {
    try {
        await confirmSignUp({
            username,
            confirmationCode
        });
        return { success: true };
    } catch (err) {
        console.error('Error verifying email:', err);
        throw err;
    }
};

export const createBackendUser = async (cognitoUserId, email, firstName, lastName, session) => {
    try {
        if (!cognitoUserId) {
            throw new Error('Cognito User ID is required');
        }

        if (!session?.tokens?.idToken) {
            throw new Error('Valid session is required');
        }

        const idToken = session.tokens.idToken.toString();

        const response = await axios.put(
            `${process.env.REACT_APP_API_URL}/user`,
            {
                cognito_user_id: cognitoUserId,
                email,
                first_name: firstName,
                last_name: lastName
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                }
            }
        );

        return response.data;
    } catch (err) {
        console.error('Error creating backend user:', err);
        if (err.response) {
            console.error('Response data:', err.response.data);
            console.error('Response status:', err.response.status);
        }
        throw err;
    }
};

export const logout = () => async dispatch => {
    try {
        await signOut();

    } catch (err) {
        console.error('Error signing out:', err);
    }
};

export const fetchUsers = () => async dispatch => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };

        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/accounts/users/`, config);
            dispatch({
                type: FETCH_USERS_SUCCESS,
                payload: res.data
            });
        } catch (err) {
            dispatch({
                type: FETCH_USERS_FAIL
            });
        }
    } else {
        dispatch({
            type: FETCH_USERS_FAIL
        });
    }
};



export const createUser = (userData) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
        },
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/accounts/users/`, userData, config);
        dispatch({
            type: CREATE_USER_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: CREATE_USER_FAIL,
            payload: err.response.data,
        });
    }
};

export const createGroup = (groupName) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
        },
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/accounts/groups/`, { name: groupName }, config);
        dispatch({
            type: CREATE_GROUP_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: CREATE_GROUP_FAIL,
            payload: err.response.data,
        });
    }
};

export const addUserToGroup = (userId, groupName) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
        },
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/accounts/add_user_to_group/`, { user_id: userId, group_name: groupName }, config);
        dispatch({
            type: ADD_USER_TO_GROUP_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: ADD_USER_TO_GROUP_FAIL,
            payload: err.response.data,
        });
    }
};

export const removeUserFromGroup = (userId, groupName) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
        },
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/accounts/remove_user_from_group/`, { user_id: userId, group_name: groupName }, config);
        dispatch({
            type: REMOVE_USER_GROUP_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: REMOVE_USER_GROUP_FAIL,
            payload: err.response.data,
        });
    }
};


export const fetchGroups = () => async (dispatch) => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };

        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/accounts/groups/`, config);
            dispatch({
                type: FETCH_GROUPS_SUCCESS,
                payload: res.data
            });
        } catch (err) {
            dispatch({
                type: FETCH_GROUPS_FAIL,
                payload: err.response.data,
            });
        }
    } else {
        dispatch({
            type: FETCH_GROUPS_FAIL,
            payload: 'User not authenticated',
        });
    }
};

function getCsrfToken() {
    return document.cookie
        .split('; ')
        .find(row => row.startsWith('csrftoken='))
        ?.split('=')[1];
}

export const fetchMockData = (selectedState) => async dispatch => {
    if (!selectedState) {
        console.error('No state selected for mock data generation');
        return;
    }

    const csrfToken = getCsrfToken();
    const jwtToken = localStorage.getItem('access');
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${jwtToken}`,
            'X-CSRFToken': csrfToken // Include CSRF token
        }
    };

    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/indexes/generate_mock_data/`, { state: selectedState }, config);

        dispatch({
            type: FETCH_MOCK_DATA_SUCCESS,
            payload: response.data
        });

        return response; // Return the response for chaining in your component

    } catch (err) {
        console.error('Error fetching mock data:', err);
        dispatch({
            type: FETCH_MOCK_DATA_FAIL,
            payload: err.response ? err.response.data : 'Error fetching mock data'
        });
    }
};

// Adjusted function to accept a `tier` parameter
export const createCheckoutSession = (tier) => async (dispatch, getState) => {
    const csrfToken = getCsrfToken();
    const jwtToken = localStorage.getItem('access');
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${jwtToken}`,
                'X-CSRFToken': csrfToken // Include CSRF token
            },
        };
        try {
            const state = getState();
            const userId = state.auth.user.id; // Assuming the user ID is stored in the auth state

            const body = JSON.stringify({
                plan: tier.toLowerCase(),
                user_id: userId, // Pass the user ID in the request body
            });

            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/stripePayments/create_checkout_session/`, body, config);

            dispatch({
                type: CHECKOUT_SESSION_SUCCESS,
                payload: res.data,
            });

            // Handle any post-checkout session creation logic here, e.g., redirecting to the checkout page
            return res;
        } catch (err) {
            dispatch({
                type: CHECKOUT_SESSION_FAIL,
                payload: err.response ? err.response.data : "An error occurred",
            });
            // Handle the error, e.g., showing an error message to the user
        }
    } else {
        // Handle the case where the user is not authenticated
        dispatch({
            type: CHECKOUT_SESSION_FAIL,
            payload: "User not authenticated",
        });
    }
};

export const getCheckoutSession = (sessionId) => async (dispatch) => {
    const jwtToken = localStorage.getItem('access');
    if (jwtToken) {
        const config = {
            headers: {
                'Authorization': `JWT ${jwtToken}`,
                // 'Content-Type': 'application/json', // This can be removed as you're not sending JSON in the body
                // 'X-CSRFToken': csrfToken // CSRF token is generally not needed for GET requests
            },
        };

        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/stripePayments/get_checkout_session/?sessionId=${sessionId}`, config);
            dispatch({
                type: GET_CHECKOUT_SESSION_SUCCESS,
                payload: res.data,
            });
            return res;
        } catch (err) {
            dispatch({
                type: GET_CHECKOUT_SESSION_FAIL,
                payload: err.response ? err.response.data : "An error occurred",
            });
        }
    }
    else {
        dispatch({
            type: GET_CHECKOUT_SESSION_FAIL,
            payload: "User not authenticated",
        });
    }
};

export const setCustomerIdToUser = (userId, customerId) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
        },
    };

    try {
        const body = JSON.stringify({ user_id: userId, customer_id: customerId });
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/accounts/set_customer_id/`, body, config);
        dispatch({
            type: SET_USER_CUSTOMER_ID_SUCCESS,
            payload: res.data, // Assuming the response data includes some confirmation or updated user object
        });
    } catch (err) {
        dispatch({
            type: SET_USER_CUSTOMER_ID_FAIL,
            payload: err.response.data, // Assuming err.response.data contains the error message
        });
    }
};

export const retrieveStripeCustomerInfo = () => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
        },
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/stripePayments/retrieve_stripe_customer_info/`, config);
        dispatch({
            type: RETRIEVE_STRIPE_CUSTOMER_INFO_SUCCESS,
            payload: res.data, // Assuming the response data includes the Stripe customer information
        });
    } catch (err) {
        dispatch({
            type: RETRIEVE_STRIPE_CUSTOMER_INFO_FAIL,
            payload: err.response ? err.response.data : 'Error retrieving Stripe customer information', // More robust error handling
        });
    }
};

export const createBillingPortalSession = (customerId) => async (dispatch) => {
    const csrfToken = getCsrfToken();
    const jwtToken = localStorage.getItem('access');

    if (jwtToken) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${jwtToken}`,
                'X-CSRFToken': csrfToken // Include CSRF token
            },
        };

        try {
            const body = JSON.stringify({
                customerId, // Send the customer ID to the backend
            });

            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/stripePayments/create_billing_portal_session/`, body, config);
            dispatch({
                type: CREATE_BILLING_PORTAL_SUCCESS,
                payload: res.data, // Assuming the API returns the URL to the billing portal
            });
            // Optionally, handle post-billing portal session creation logic here, e.g., redirecting to the billing portal
            return res;
        } catch (err) {
            dispatch({
                type: CREATE_BILLING_PORTAL_FAIL,
                payload: err.response ? err.response.data : "An error occurred",
            });
            // Handle the error, e.g., showing an error message to the user
        }
    } else {
        // Handle the case where the user is not authenticated
        dispatch({
            type: CREATE_BILLING_PORTAL_FAIL,
            payload: "User not authenticated",
        });
    }
};

export const addToMailingList = (email) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const body = JSON.stringify({ email });
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/accounts/mailing_list/`, body, config);
        dispatch({
            type: ADD_TO_MAILING_LIST_SUCCESS,
            payload: res.data,
        });
        return res.data;
    } catch (err) {
        console.error('Error adding user to mailing list:', err);
        dispatch({
            type: ADD_TO_MAILING_LIST_FAIL,
            payload: err.message,
        });
        throw err;
    }
};

export const checkEmailAvailability = (email) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const body = JSON.stringify({ email });
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/accounts/check_email/`, body, config);
        dispatch({
            type: CHECK_EMAIL_AVAILABILITY_SUCCESS,
            payload: res.data,
        });
        return res.data;
    } catch (err) {
        console.error('Error checking email availability:', err);
        dispatch({
            type: CHECK_EMAIL_AVAILABILITY_FAIL,
            payload: err.response ? err.response.data : 'Error checking email availability',
        });
        throw err;
    }
};

export const deleteProfilePicture = () => async (dispatch) => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`,
        },
    };

    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/accounts/delete_profile_picture/`, config);
        dispatch({
            type: DELETE_PROFILE_PICTURE_SUCCESS,
            payload: res.data,
        });
        dispatch({
            type: USER_LOADED_SUCCESS,
            payload: res.data.user,
        });
        return res.data;
    } catch (err) {
        console.error('Error deleting profile picture:', err);
        dispatch({
            type: DELETE_PROFILE_PICTURE_FAIL,
            payload: err.response ? err.response.data : 'Error deleting profile picture',
        });
        throw err;
    }
};

const getAuthToken = async () => {
    const session = await fetchAuthSession();
    const token = session.tokens.idToken.toString();
    return token;
}

export const generatePublicUrl = async (imageData) => {
    const authToken = await getAuthToken();
    if (!authToken) {
        throw new Error('No auth token found');
    }

    try {
        // Step 1: Get presigned URLs for all images
        const presignedUrlsPromises = imageData.map(async (img) => {
            const config = {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': img.file.type
                },
                params: {
                    fileName: img.file.name,
                    fileType: img.file.type
                }
            };

            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/img/presigned-url/`,
                config
            );

            return response.data;
        });

        const presignedUrlsData = await Promise.all(presignedUrlsPromises);

        // Step 2: Compress and upload images to S3
        const uploadPromises = imageData.map(async (img, index) => {
            const { uploadURL, publicURL } = presignedUrlsData[index];

            // Compress the image
            const compressionOptions = {
                maxSizeMB: 0.5,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            };

            let compressedFile;
            try {
                compressedFile = await imageCompression(img.file, compressionOptions);
            } catch (err) {
                console.warn(`Compression failed for ${img.file.name}, using original file:`, err);
                compressedFile = img.file;
            }

            // Upload the compressed (or original if compression failed) file
            await axios.put(uploadURL, compressedFile, {
                headers: {
                    'Content-Type': compressedFile.type
                }
            });

            return { id: img.id, url: publicURL };
        });

        const uploadedUrls = await Promise.all(uploadPromises);

        return uploadedUrls;
    } catch (err) {
        console.error('Error generating public URLs:', err);
        throw err;
    }
};

export const createBlogPost = async (blogData) => {
    const authToken = await getAuthToken();
    if (!authToken) {
        throw new Error('No auth token found');
    }

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
        }
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/blog`, blogData, config);
        return res.data;
    } catch (err) {
        console.error('Error creating blog post:', err);
        throw err;
    }
};

export const fetchBlogPosts = async (queryStringParameters) => {
    try {
        const { search, category, from, to, page, limit } = queryStringParameters || {};

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                search,
                category,
                from,
                to,
                page,
                limit
            }
        };

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/blogs`, config);

        return response.data;
    } catch (error) {
        console.error('Error fetching blog posts:', error);
        throw error;
    }
};

export const fetchBlogPost = async (id) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            params: { id: id }
        };

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/blog`, config);

        // Assuming the API returns the blog post data directly
        // If it's nested under a 'data' property, you might need to return response.data.data
        return response.data;
    } catch (error) {
        console.error('Error fetching blog post:', error);
        throw error;
    }
};

export const deleteBlogPost = (slug) => async dispatch => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
            }
        };

        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/blogs/${slug}/delete/`, config);
            dispatch({
                type: DELETE_BLOG_POST_SUCCESS,
                payload: slug
            });
        } catch (err) {
            dispatch({
                type: DELETE_BLOG_POST_FAIL,
                payload: err.response.data
            });
        }
    } else {
        dispatch({
            type: DELETE_BLOG_POST_FAIL,
            payload: 'No access token found'
        });
    }
};

export const fetchLocationDataAction = async (locationType, limit, offset, query) => {
    const authToken = await getAuthToken();
    if (!authToken) {
        throw new Error('No auth token found');
    }

    const config = {
        headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json',
        },
        params: {
            location_type: locationType.toUpperCase(),
            limit,
            offset,
            stateId: query
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/location/search`, config);
        return res.data;
    } catch (err) {
        console.error('Error fetching location data:', err);
        throw err;
    }
};

export const fetchLocationStatistics = async (geoId) => {
    const authToken = await getAuthToken();
    if (!authToken) {
        throw new Error('No auth token found');
    }

    const config = {
        headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json'
        }
    };

    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/location/${geoId}`,
            config
        );

        if (response.data.success && response.data.data.location) {
            return response.data.data.location;
        } else {
            throw new Error('Invalid response format from location API');
        }
    } catch (error) {
        console.error(`Error fetching location data for geoId ${geoId}:`, error);
        throw new Error(`Failed to fetch location data: ${error.message}`);
    }
};

export const fetchLocationRankings = async ({
    category,
    sortBy,
    direction = 'top',
    limit = 5,
    geoType,
    locationIdToRank
}) => {
    const authToken = await getAuthToken();
    if (!authToken) {
        throw new Error('No auth token found');
    }

    const config = {
        headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json'
        }
    };

    const queryParams = new URLSearchParams({
        category,
        sortBy,
        direction,
        limit: limit.toString(),
        geoType
    });

    if (locationIdToRank) {
        queryParams.append('locationIdToRank', locationIdToRank);
    }

    console.log('Rankings API request:', {
        geoType,
        locationIdToRank,
        queryParams: queryParams.toString()
    });

    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/location/rankings?${queryParams.toString()}`,
            config
        );

        if (response.data.success && response.data.data.rankings) {
            return response.data.data.rankings;
        } else {
            throw new Error('Invalid response format from rankings API');
        }
    } catch (error) {
        console.error('Error fetching location rankings:', error);
        throw new Error(`Failed to fetch rankings: ${error.message}`);
    }
};

export const fetchMultipleRankings = async (locationId, geoType, parentLocationId) => {
    console.log('Fetching multiple rankings:', { locationId, geoType, parentLocationId });

    // Configuration for multiple ranking requests
    const rankingConfigs = [
        {
            category: 'demographics',
            sortBy: 'population',
            direction: 'top',
            label: 'Population'
        },
        {
            category: 'economics',
            sortBy: 'medianHouseholdIncome',
            direction: 'bottom',  // Get bottom 5 for income comparison
            label: 'Median Household Income'
        }
    ];

    try {
        const rankingsPromises = rankingConfigs.map(config =>
            fetchLocationRankings({
                category: config.category,
                sortBy: config.sortBy,
                direction: config.direction,
                limit: 5,
                geoType: geoType,
                locationIdToRank: parentLocationId
            })
        );

        const rankingsResults = await Promise.all(rankingsPromises);

        // Create an object with both population and income rankings
        return rankingConfigs.reduce((acc, config, index) => {
            acc[config.sortBy] = {
                data: rankingsResults[index],
                label: config.label
            };
            return acc;
        }, {});
    } catch (error) {
        console.error('Error fetching multiple rankings:', error);
        throw error;
    }
};