import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { createBlogPost, generatePublicUrl } from '../actions/auth';
import { EditorState, RichUtils, convertToRaw, convertFromRaw, AtomicBlockUtils, CompositeDecorator } from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import createImagePlugin from 'draft-js-image-plugin';
import { v4 as uuidv4 } from 'uuid';
import 'draft-js/dist/Draft.css';
import 'draft-js-image-plugin/lib/plugin.css';
import './CreateBlogPost.css';

const imagePlugin = createImagePlugin();
const plugins = [imagePlugin];

// Link component to render hyperlinks
const Link = (props) => {
    const { url } = props.contentState.getEntity(props.entityKey).getData();
    return (
        <a href={url} style={{ color: '#3b5998', textDecoration: 'underline' }}>
            {props.children}
        </a>
    );
};

// Function to find link entities
const findLinkEntities = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges(
        (character) => {
            const entityKey = character.getEntity();
            return (
                entityKey !== null &&
                contentState.getEntity(entityKey).getType() === 'LINK'
            );
        },
        callback
    );
};

// Create a decorator for links
const decorator = new CompositeDecorator([
    {
        strategy: findLinkEntities,
        component: Link,
    },
]);

const CreateBlogPost = () => {
    const navigate = useNavigate();
    const [title, setTitle] = useState('');
    const [editorState, setEditorState] = useState(
        EditorState.createEmpty(decorator)
    );
    const [category, setCategory] = useState('');
    const [author, setAuthor] = useState('');
    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState(null);
    const [imagesToUpload, setImagesToUpload] = useState([]);
    const [thumbnail, setThumbnail] = useState(null);
    const [thumbnailPreview, setThumbnailPreview] = useState('');

    const editor = useRef(null);
    const fileInput = useRef(null);
    const thumbnailInput = useRef(null);

    const [showURLInput, setShowURLInput] = useState(false);
    const [urlValue, setUrlValue] = useState('');
    const [urlType, setUrlType] = useState('');
    const urlInputRef = useRef(null);

    useEffect(() => {
        const savedContent = localStorage.getItem('draftContent');
        if (savedContent) {
            const contentState = convertFromRaw(JSON.parse(savedContent));
            setEditorState(EditorState.createWithContent(contentState));
        }
        focusEditor();
    }, []);

    const focusEditor = () => {
        if (editor.current) {
            editor.current.focus();
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setUploading(true);
        setError(null);

        try {
            // Generate public URLs for all images including thumbnail
            const imageUrlMap = new Map();
            const allImages = [...imagesToUpload, { id: 'thumbnail', file: thumbnail }].filter(img => img.file);

            if (allImages.length > 0) {
                const imageData = allImages.map(img => ({
                    id: img.id,
                    file: img.file
                }));
                const publicUrls = await generatePublicUrl(imageData);
                publicUrls.forEach(item => {
                    imageUrlMap.set(item.id, item.url);
                });
            }

            // Update content with public URLs
            const contentState = editorState.getCurrentContent();
            const rawContent = convertToRaw(contentState);

            rawContent.entityMap = Object.keys(rawContent.entityMap).reduce((newEntityMap, key) => {
                const entity = rawContent.entityMap[key];
                if (entity.type === 'IMAGE') {
                    const imageId = entity.data.id;
                    if (imageUrlMap.has(imageId)) {
                        entity.data.src = imageUrlMap.get(imageId);
                    }
                }
                newEntityMap[key] = entity;
                return newEntityMap;
            }, {});

            // Prepare blog post data
            const blogPostData = {
                title,
                content: JSON.stringify(rawContent),
                category,
                author,
                thumbnail: imageUrlMap.get('thumbnail') || ''
            };


            // Create blog post
            const createdPost = await createBlogPost(blogPostData);


            //Now navigate to the blog based on the returned slug
            localStorage.removeItem('draftContent');
            console.log(createdPost.data.slug)
            console.log(createdPost.data.id)
            navigate(`/blog/${createdPost.data.slug}?id=${createdPost.data.id}`);
        } catch (error) {
            console.error('Error creating blog post:', error);
            setError('Failed to create blog post. Please try again.');
        } finally {
            setUploading(false);
        }
    };

    const handleEditorChange = (newEditorState) => {
        setEditorState(newEditorState);
        const contentState = newEditorState.getCurrentContent();
        localStorage.setItem('draftContent', JSON.stringify(convertToRaw(contentState)));
    };

    const handleKeyCommand = (command, editorState) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            handleEditorChange(newState);
            return 'handled';
        }
        return 'not-handled';
    };

    const promptForLink = () => {
        const selection = editorState.getSelection();
        if (!selection.isCollapsed()) {
            setShowURLInput(true);
            setTimeout(() => urlInputRef.current.focus(), 0);
        }
    };

    const confirmLink = (e) => {
        e.preventDefault();
        const contentState = editorState.getCurrentContent();
        let url = urlValue;

        // Ensure the URL has a protocol
        if (!/^https?:\/\//i.test(url)) {
            url = 'http://' + url;
        }

        const contentStateWithEntity = contentState.createEntity(
            'LINK',
            'MUTABLE',
            { url: url }
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        let newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
        newEditorState = RichUtils.toggleLink(
            newEditorState,
            newEditorState.getSelection(),
            entityKey
        );
        handleEditorChange(newEditorState);
        setShowURLInput(false);
        setUrlValue('');
    };

    const onLinkInputKeyDown = (e) => {
        if (e.which === 13) {
            confirmLink(e);
        }
    };

    const removeLink = () => {
        const selection = editorState.getSelection();
        if (!selection.isCollapsed()) {
            handleEditorChange(RichUtils.toggleLink(editorState, selection, null));
        }
    };

    const toggleBlockType = (blockType) => {
        handleEditorChange(RichUtils.toggleBlockType(editorState, blockType));
    };

    const toggleInlineStyle = (inlineStyle) => {
        handleEditorChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
    };

    const handleImageUpload = useCallback(async (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        if (file) {
            try {
                setUploading(true);
                setError(null);
                const imageId = uuidv4();
                const contentState = editorState.getCurrentContent();
                const contentStateWithEntity = contentState.createEntity(
                    'IMAGE',
                    'IMMUTABLE',
                    { src: URL.createObjectURL(file), id: imageId }
                );
                const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
                const newEditorState = EditorState.set(editorState, {
                    currentContent: contentStateWithEntity
                });
                handleEditorChange(AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' '));
                setImagesToUpload(prev => [...prev, { id: imageId, file }]);
            } catch (error) {
                console.error('Error handling image:', error);
                setError('Failed to add image. Please try again.');
            } finally {
                setUploading(false);
            }
        }
    }, [editorState]);

    const handleThumbnailUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            setThumbnail(file);
            setThumbnailPreview(URL.createObjectURL(file));
        }
    };

    const StyleButton = ({ onToggle, style, active, label }) => {
        let className = 'RichEditor-styleButton';
        if (active) {
            className += ' RichEditor-activeButton';
        }

        return (
            <span className={className} onMouseDown={(e) => {
                e.preventDefault();
                onToggle(style);
            }}>
                {label}
            </span>
        );
    };

    const BLOCK_TYPES = [
        { label: 'H1', style: 'header-one' },
        { label: 'H2', style: 'header-two' },
        { label: 'H3', style: 'header-three' },
        { label: 'H4', style: 'header-four' },
        { label: 'H5', style: 'header-five' },
        { label: 'H6', style: 'header-six' },
        { label: 'Blockquote', style: 'blockquote' },
        { label: 'UL', style: 'unordered-list-item' },
        { label: 'OL', style: 'ordered-list-item' },
        { label: 'Code Block', style: 'code-block' },
    ];

    const BlockStyleControls = (props) => {
        const { editorState } = props;
        const selection = editorState.getSelection();
        const blockType = editorState
            .getCurrentContent()
            .getBlockForKey(selection.getStartKey())
            .getType();

        return (
            <div className="RichEditor-controls">
                {BLOCK_TYPES.map((type) =>
                    <StyleButton
                        key={type.label}
                        active={type.style === blockType}
                        label={type.label}
                        onToggle={props.onToggle}
                        style={type.style}
                    />
                )}
            </div>
        );
    };

    const INLINE_STYLES = [
        { label: 'Bold', style: 'BOLD' },
        { label: 'Italic', style: 'ITALIC' },
        { label: 'Underline', style: 'UNDERLINE' },
        { label: 'Monospace', style: 'CODE' },
    ];

    const InlineStyleControls = (props) => {
        const currentStyle = props.editorState.getCurrentInlineStyle();

        return (
            <div className="RichEditor-controls">
                {INLINE_STYLES.map((type) =>
                    <StyleButton
                        key={type.label}
                        active={currentStyle.has(type.style)}
                        label={type.label}
                        onToggle={props.onToggle}
                        style={type.style}
                    />
                )}
            </div>
        );
    };

    const LinkControl = () => {
        return (
            <div className="RichEditor-controls">
                <span className="RichEditor-styleButton" onMouseDown={(e) => {
                    e.preventDefault();
                    promptForLink();
                }}>
                    Add Link
                </span>
                <span className="RichEditor-styleButton" onMouseDown={(e) => {
                    e.preventDefault();
                    removeLink();
                }}>
                    Remove Link
                </span>
            </div>
        );
    };

    return (
        <div className="create-blog-post">
            <h1>Create New Blog Post</h1>
            {error && <div className="error-message">{error}</div>}
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="title">Title:</label>
                    <input
                        type="text"
                        id="title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="author">Author:</label>
                    <input
                        type="text"
                        id="author"
                        value={author}
                        onChange={(e) => setAuthor(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="category">Category:</label>
                    <input
                        type="text"
                        id="category"
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="thumbnail">Thumbnail:</label>
                    <button type="button" onClick={() => thumbnailInput.current.click()} disabled={uploading}>
                        Upload Thumbnail
                    </button>
                    <input
                        ref={thumbnailInput}
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleThumbnailUpload}
                        accept="image/*"
                    />
                    {thumbnailPreview && (
                        <img src={thumbnailPreview} alt="Thumbnail preview" style={{ maxWidth: '200px', maxHeight: '200px' }} />
                    )}
                </div>
                <div className="RichEditor-root">
                    <BlockStyleControls
                        editorState={editorState}
                        onToggle={toggleBlockType}
                    />
                    <InlineStyleControls
                        editorState={editorState}
                        onToggle={toggleInlineStyle}
                    />
                    <LinkControl />
                    <button type="button" onClick={() => fileInput.current.click()} disabled={uploading}>
                        Insert Image
                    </button>
                    <input
                        ref={fileInput}
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleImageUpload}
                        accept="image/*"
                    />
                    {showURLInput && (
                        <div className="RichEditor-urlInput">
                            <input
                                ref={urlInputRef}
                                type="text"
                                value={urlValue}
                                onChange={(e) => setUrlValue(e.target.value)}
                                onKeyDown={onLinkInputKeyDown}
                                placeholder="Enter URL and press Enter"
                            />
                            <button onMouseDown={confirmLink}>Confirm</button>
                        </div>
                    )}
                    <div className="RichEditor-editor" onClick={focusEditor}>
                        <Editor
                            editorState={editorState}
                            handleKeyCommand={handleKeyCommand}
                            onChange={handleEditorChange}
                            plugins={plugins}
                            ref={editor}
                        />
                    </div>
                </div>
                <button type="submit" disabled={uploading}>
                    {uploading ? 'Creating...' : 'Create Blog Post'}
                </button>
            </form>
        </div>
    );
};

export default CreateBlogPost;