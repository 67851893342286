import React from 'react';
import { Link } from 'react-router-dom';
import './BlogPostSidebar.css';

function BlogPostSidebar() {
    return (
        <aside className="blog-sidebar">
            <h2>Related Posts</h2>
            <div className="empty-state">
                <p>Nothing Here Yet!</p>
                <Link to="/blog" className="back-to-blog">Back to Blog</Link>
            </div>
        </aside>
    );
}

export default BlogPostSidebar;