import React from 'react';
import { useNavigate } from 'react-router-dom';
import './SheetsSection.css';
import simpleSheetsImage from '../assets/simple_sheets_2.svg';
import customMarketsImage from '../assets/custom_markets_sheet_2.svg';
import detailedResearchImage from '../assets/detailed_research_2.svg';

const SheetsSection = () => {
    const navigate = useNavigate();

    const handleRegisterClick = () => {
        navigate('/signup');
    };

    return (
        <div className="sheets-container">
            <div className="sheets-section">
                <div className="image-box">
                    <div className="image-container">
                        <img src={simpleSheetsImage} alt="Simple Sheets" className="sheets-images" />
                        <div className="fade-overlay"></div>
                    </div>
                    <div className="text-container">
                        <h3>SnapSheets™</h3>
                        <p>Create professional, data-rich summaries of any neighborhood in minutes. Our tools segment neighborhoods to provide hyper-local insights, giving you accurate, actionable data that you can share with clients to close deals faster.</p>
                    </div>
                </div>
                <div className="image-box">
                    <div className="image-container">
                        <img src={customMarketsImage} alt="Custom Market Sheets" className="sheets-images" />
                        <div className="fade-overlay"></div>
                    </div>
                    <div className="text-container">
                        <h3>CustomCanvas™</h3>
                        <p>Take your branding and market expertise to the next level. Customize every detail of your market sheets to match your personal brand or brokerage, and make edits to fit your clients’ needs. Dive deeper into neighborhood-specific data to create polished, professional reports that showcase your unique value.</p>
                    </div>
                </div>
                <div className="image-box">
                    <div className="image-container">
                        <img src={detailedResearchImage} alt="Detailed Research" className="sheets-images" />
                        <div className="fade-overlay"></div>
                    </div>
                    <div className="text-container">
                        <h3>DeepDive™</h3>
                        <p>Explore neighborhood analytics in detail to better serve your clients. Easily compare multiple areas side by side to spot key differences in demographics, crime rates, and market trends. With precise, hyperlocal data, you’ll gain a deeper understanding of each neighborhood to enhance your expertise and client recommendations.</p>
                    </div>
                </div>
            </div>
            <button className="try-button" onClick={handleRegisterClick}>
                Let me try!
            </button>
        </div>
    );
};

export default SheetsSection;