import React, { memo } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import './SchoolsOverview.css';

ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// SVG version of pie chart for market mode
const MarketModePieChart = memo(({ data }) => {
    const total = data.datasets[0].data.reduce((sum, value) => sum + value, 0);
    let currentAngle = 0;
    const size = 125;
    const radius = size / 2;
    const centerX = radius;
    const centerY = radius;
    const donutWidth = radius * 0.6;
  
    // Legend configuration
    const legendItemHeight = 20;
    const legendWidth = 165;
    const legendX = size + 20;
    const totalHeight = Math.max(size, data.labels.length * legendItemHeight);
  
    return (
      <div className="flex flex-col items-center w-full">
        <div className="mt-6 flex justify-center" style={{ marginTop: '24px', display: 'flex', justifyContent: 'center'}}> {/* Added margin-top and centered container */}
          <svg viewBox={`0 0 ${size + legendWidth} ${totalHeight}`} style={{ width: `${size + legendWidth}px`, height: `${totalHeight}px` }}>
            {/* Main chart group */}
            <g transform={`translate(0, ${totalHeight / 2 - size / 2})`}>
              {data.datasets[0].data.map((value, i) => {
                const percentage = (value / total) * 100;
                const angle = (percentage / 100) * Math.PI * 2;
                const largeArcFlag = percentage > 50 ? 1 : 0;
  
                const startX = centerX + radius * Math.cos(currentAngle);
                const startY = centerY + radius * Math.sin(currentAngle);
                const endX = centerX + radius * Math.cos(currentAngle + angle);
                const endY = centerY + radius * Math.sin(currentAngle + angle);
  
                const innerStartX = centerX + (radius - donutWidth) * Math.cos(currentAngle);
                const innerStartY = centerY + (radius - donutWidth) * Math.sin(currentAngle);
                const innerEndX = centerX + (radius - donutWidth) * Math.cos(currentAngle + angle);
                const innerEndY = centerY + (radius - donutWidth) * Math.sin(currentAngle + angle);
  
                const path = [
                  `M ${startX} ${startY}`,
                  `A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endX} ${endY}`,
                  `L ${innerEndX} ${innerEndY}`,
                  `A ${radius - donutWidth} ${radius - donutWidth} 0 ${largeArcFlag} 0 ${innerStartX} ${innerStartY}`,
                  'Z'
                ].join(' ');
  
                const thisAngle = currentAngle;
                currentAngle += angle;
  
                return (
                  <path 
                    key={data.labels[i]}
                    d={path} 
                    fill={data.datasets[0].backgroundColor[i]} 
                  />
                );
              })}
            </g>
  
            {/* Legend */}
            <g transform={`translate(${legendX}, 0)`}>
              {data.labels.map((label, i) => (
                <g key={label} transform={`translate(0, ${i * legendItemHeight})`}>
                  {/* Legend color box */}
                  <rect
                    x="0"
                    y="4"
                    width="12"
                    height="12"
                    fill={data.datasets[0].backgroundColor[i]}
                  />
                  {/* Legend text */}
                  <text
                    x="20"
                    y="14"
                    fontSize="9"
                    fontFamily="sans-serif"
                    fill="#333"
                  >
                    {`${label} (${Math.round(data.datasets[0].data[i])}%)`}
                  </text>
                </g>
              ))}
            </g>
          </svg>
        </div>
      </div>
    );
  });

// Shared chart configuration
const pieChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 1.75,
    layout: {
      padding: {
        top: 10,
        right: 0,
        bottom: 10,
        left: 0
      }
    },
    plugins: {
      legend: {
        position: 'right',
        align: 'center',
        labels: {
          boxWidth: 12,
          padding: 10,
          font: {
            size: 9,  // Changed from 11 to 9
            family: 'system-ui'
          }
        }
      },
      tooltip: {
        callbacks: {
          label: (context) => `${context.label}: ${Math.round(context.raw)}%`
        },
        bodyFont: {
          size: 11
        }
      },
      datalabels: {
        display: false
      }
    },
    animation: false
  };
  const chartContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'fit-content',
    marginTop: '30px',
    maxHeight: '125px', // Add a max height
    width: '100%'       // Ensure it's responsive up to maxWidth
  };
  
  export const SchoolEnrlmtLevelPcts = memo(({ locationData, isMarketMode = false }) => {
    if (!locationData?.education) return null;
  
    const { education } = locationData;
  
    const data = {
      labels: isMarketMode 
        ? ['K', '1-4', '5-8', '9-12'] 
        : ['Kindergarten', 'Elementary (1-4)', 'Middle (5-8)', 'High (9-12)'],
      datasets: [{
        data: [
          Math.round(education.enrollmentPublicKindergartenPct + education.enrollmentPrivateKindergartenPct),
          Math.round(education.enrollmentPublicGrades1_4Pct + education.enrollmentPrivateGrades1_4Pct),
          Math.round(education.enrollmentPublicGrades5_8Pct + education.enrollmentPrivateGrades5_8Pct),
          Math.round(education.enrollmentPublicGrades9_12Pct + education.enrollmentPrivateGrades9_12Pct)
        ],
        backgroundColor: ['#4e79a7', '#f28e2c', '#e15759', '#76b7b2']
      }]
    };
  
    if (isMarketMode) {
      return <MarketModePieChart data={data} />;
    }
  
    return (
      <div style={chartContainerStyle}>
        <Pie data={data} options={pieChartOptions} />
      </div>
    );
  });
  
  export const SchoolEnrlmtTypePcts = memo(({ locationData, isMarketMode = false }) => {
    if (!locationData?.education) return null;
  
    const { education } = locationData;
  
    const publicTotal = Math.round(
      education.enrollmentPublicKindergartenPct +
      education.enrollmentPublicGrades1_4Pct +
      education.enrollmentPublicGrades5_8Pct +
      education.enrollmentPublicGrades9_12Pct
    );
  
    const privateTotal = Math.round(
      education.enrollmentPrivateKindergartenPct +
      education.enrollmentPrivateGrades1_4Pct +
      education.enrollmentPrivateGrades5_8Pct +
      education.enrollmentPrivateGrades9_12Pct
    );
  
    const data = {
      labels: ['Public', 'Private'],
      datasets: [{
        data: [publicTotal, privateTotal],
        backgroundColor: ['#4e79a7', '#e15759']
      }]
    };
  
    if (isMarketMode) {
      return <MarketModePieChart data={data} />;
    }
  
    return (
      <div style={chartContainerStyle}>
        <Pie data={data} options={pieChartOptions} />
      </div>
    );
  });

// Market mode versions (these just pass isMarketMode=true to base components)
export const MarketModeSchoolEnrlmtLevelPcts = memo((props) => (
  <SchoolEnrlmtLevelPcts {...props} isMarketMode={true} />
));

export const MarketModeSchoolEnrlmtTypePcts = memo((props) => (
  <SchoolEnrlmtTypePcts {...props} isMarketMode={true} />
));

const SchoolsTab = ({ ContentBox, locationData }) => {
  return (
    <>
      <ContentBox
        title="Enrollment by Grade Level"
        key="SchoolEnrlmtLevelPcts"
        subtype="school"
        contentType="SchoolEnrlmtLevelPcts"
      >
        <SchoolEnrlmtLevelPcts locationData={locationData} />
      </ContentBox>
      <ContentBox
        title="Public vs Private Enrollment"
        key="SchoolEnrlmtTypePcts"
        subtype="school"
        contentType="SchoolEnrlmtTypePcts"
      >
        <SchoolEnrlmtTypePcts locationData={locationData} />
      </ContentBox>
    </>
  );
};
export const SchoolsOverview = ({ locationData }) => {
    if (!locationData?.education) {
        return <div>No school data available</div>;
    }

    return (
        <div className="p-4 bg-white rounded-lg shadow-md">
            <div className="grid grid-cols-1 gap-4">
                <div className="bg-gray-100 p-4 rounded-lg">
                    <h3 className="text-xl font-semibold mb-2">Enrollment by Grade Level</h3>
                    <SchoolEnrlmtLevelPcts locationData={locationData} />
                </div>

                <div className="bg-gray-100 p-4 rounded-lg">
                    <h3 className="text-xl font-semibold mb-2">Public vs Private Enrollment</h3>
                    <SchoolEnrlmtTypePcts locationData={locationData} />
                </div>
            </div>
        </div>
    );
};
export default SchoolsTab;