// src/App.js
import awsConfig from './amplify-config';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { Hub } from 'aws-amplify/utils';
import { getCurrentUser } from '@aws-amplify/auth';
import './App.css';
import Dashboard from './pages/Dashboard';
import HomePage from './pages/Home';
import Login from './pages/Login';
import Signup from './pages/Signup';
import VerificationPage from './pages/VerificationPage';
import AdminPage from './pages/AdminPage';
import About from './pages/About';
import Blog from './pages/Blog'
import ViewUseCases from './pages/ViewUseCases';
import BlogPost from './components/BlogPost';
import CreateBlogPost from './components/CreateBlogPost';
import CheckoutSuccess from './components/CheckoutSuccess';

import ResetPassword from './containers/ResetPassword';
import ResetPasswordConfirm from './containers/ResetPasswordConfirm';
import Google from './components/Google';
import Account from './components/Account';

import Navbar from './components/Navbar';

import { MarketModeProvider } from './contexts/MarketModeContext';
import FeedbackBubble from './components/FeedbackBubble';
import ProtectedRoute from './components/ProtectedRoute';
import BlogPostOne from './components/blog/blogPostOne';

Amplify.configure(awsConfig);


function App() {

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    checkUser();
    const authListener = Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          setIsAuthenticated(true);
          break;
        case 'signOut':
          setIsAuthenticated(false);
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data);
          break;
        default:
          break;
      }
    });

    return () => {
      authListener(); // Clean up the listener when the component unmounts
    };
  }, []);

  async function checkUser() {
    try {
      const user = await getCurrentUser();
      setIsAuthenticated(!!user);
    } catch {
      setIsAuthenticated(false);
    }
  }

  return (
    <Router>
      <Navbar isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />
      <MarketModeProvider>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
          <Route path="/account" element={<Account />} />
          {/* <Route path="/about" element={<About />} /> */}
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:slug" element={<BlogPost />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/verify" element={<VerificationPage />} />
          <Route path="/google" element={<Google />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/reset-password-confirm" element={<ResetPasswordConfirm />} />
          <Route path="/password/reset/confirm/:uid/:token" element={<ResetPasswordConfirm />} />
          <Route path="/success" element={<CheckoutSuccess />} />
        </Routes>
      </MarketModeProvider>
      {/* <FeedbackBubble /> */}
    </Router>
  );
}

export default App;
