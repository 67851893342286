// src/components/HeroSection.js
import React from 'react';
import { Link } from 'react-router-dom';
import './HeroSection.css';
import heroBackgroundImage from '../assets/hero-section-house 1.svg';

const HeroSection = () => {
    return (
        <div className="hero-section">
            <div className="hero-content">
                <h1>Captivate Your Clients.<br></br>Empower Them to Know More,<br></br>While Doing Less.</h1>
                <Link to="/signup" className="signup-btn hero-signup-btn">
                    <strong>Get Started</strong>
                </Link>
            </div>
            <img
                src={heroBackgroundImage}
                alt="Hero Background"
                className="hero-background-image"
            />
        </div>
    );
};

export default HeroSection;