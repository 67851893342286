import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    USER_LOADED_SUCCESS,
    USER_LOADED_FAIL,
    AUTHENTICATED_SUCCESS,
    AUTHENTICATED_FAIL,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_FAIL,
    PASSWORD_RESET_CONFIRM_SUCCESS,
    PASSWORD_RESET_CONFIRM_FAIL,
    SIGNUP_SUCCESS,
    SIGNUP_FAIL,
    ACTIVATION_SUCCESS,
    ACTIVATION_FAIL,
    GOOGLE_AUTH_SUCCESS,
    GOOGLE_AUTH_FAIL,
    CREATE_USER_SUCCESS,
    CREATE_USER_FAIL,
    CREATE_GROUP_SUCCESS,
    CREATE_GROUP_FAIL,
    LOGOUT,
    FETCH_MOCK_DATA_SUCCESS,
    FETCH_MOCK_DATA_FAIL,
    CHECKOUT_SESSION_SUCCESS,
    CHECKOUT_SESSION_FAIL,
    GET_CHECKOUT_SESSION_SUCCESS,
    GET_CHECKOUT_SESSION_FAIL,
    SET_USER_CUSTOMER_ID_SUCCESS,
    SET_USER_CUSTOMER_ID_FAIL,
    RETRIEVE_STRIPE_CUSTOMER_INFO_SUCCESS,
    RETRIEVE_STRIPE_CUSTOMER_INFO_FAIL,
    CREATE_BILLING_PORTAL_SUCCESS,
    CREATE_BILLING_PORTAL_FAIL,
    ADD_TO_MAILING_LIST_SUCCESS,
    ADD_TO_MAILING_LIST_FAIL,
} from '../actions/types';

const initialState = {
    access: localStorage.getItem('access'),
    refresh: localStorage.getItem('refresh'),
    isAuthenticated: null,
    user: null,
    mockData: null,
    billingPortalUrl: null,
    mailingListSuccess: false,
    mailingListError: null,
    posts: [],
    currentPost: null,
    error: null
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case AUTHENTICATED_SUCCESS:
            return {
                ...state,
                isAuthenticated: true
            }
        case LOGIN_SUCCESS:
        case GOOGLE_AUTH_SUCCESS:
            localStorage.setItem('access', payload.access);
            localStorage.setItem('refresh', payload.refresh);
            return {
                ...state,
                isAuthenticated: true,
                access: payload.access,
                refresh: payload.refresh
            };
        case SIGNUP_SUCCESS:
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                error: null,
            };
        case USER_LOADED_SUCCESS:
            return {
                ...state,
                user: payload,
                isAdmin: payload.isAdmin, // Store admin status in state
            }
        case AUTHENTICATED_FAIL:
            return {
                ...state,
                isAuthenticated: false
            }
        case USER_LOADED_FAIL:
            return {
                ...state,
                user: null
            }
        case GOOGLE_AUTH_FAIL:
        case LOGIN_FAIL:
        case SIGNUP_FAIL:
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                error: payload,
            };
        case LOGOUT:
            localStorage.removeItem('access');
            localStorage.removeItem('refresh');
            return {
                ...state,
                access: null,
                refresh: null,
                isAuthenticated: false,
                user: null
            }
        case PASSWORD_RESET_SUCCESS:
        case PASSWORD_RESET_FAIL:
        case PASSWORD_RESET_CONFIRM_SUCCESS:
        case PASSWORD_RESET_CONFIRM_FAIL:
        case ACTIVATION_SUCCESS:
        case ACTIVATION_FAIL:
            return {
                ...state
            }
        case CREATE_USER_SUCCESS:
            return {
                ...state,
                users: [...state.users, payload], // Add the new user to the users array
                error: null, // Reset any errors
            };
        case CREATE_GROUP_SUCCESS:
            return {
                ...state,
                groups: [...state.groups, payload], // Add the new group to the groups array
                error: null, // Reset any errors
            };
        case CREATE_USER_FAIL:
            return {
                ...state,
                error: payload, // Store the error message
            };
        case CREATE_GROUP_FAIL:
            return {
                ...state,
                error: payload, // Store the error message
            };
        case FETCH_MOCK_DATA_SUCCESS:
            return {
                ...state,
                mockData: payload,  // Update the state with the fetched mock data
            };
        case FETCH_MOCK_DATA_FAIL:
            return {
                ...state,
                mockData: null,  // Clear or handle the mock data field on failure
            };
        case CHECKOUT_SESSION_SUCCESS:
            return {
                ...state,
                checkoutSessionUrl: payload.url, // Assuming the payload contains a URL property
                error: null, // Clear any previous errors
            };
        case CHECKOUT_SESSION_FAIL:
            return {
                ...state,
                checkoutSessionUrl: null, // Clear any previous session URL
                error: payload, // Store the error message
            };
        case GET_CHECKOUT_SESSION_SUCCESS:
            return {
                ...state,
                checkoutSession: payload,
                error: null,
            };
        case GET_CHECKOUT_SESSION_FAIL:
            return {
                ...state,
                checkoutSession: null,
                error: null,
            };
        case SET_USER_CUSTOMER_ID_SUCCESS:
            // Assuming payload contains the updated user object or confirmation message
            // Update as necessary based on actual payload structure
            return {
                ...state,
                user: { ...state.user, ...action.payload }, // Update the user in the state with the new customer ID info
                error: null, // Clear any previous error
            };
        case SET_USER_CUSTOMER_ID_FAIL:
            // Assuming payload contains an error message
            return {
                ...state,
                error: action.payload, // Update the state with the error message
            };
        case RETRIEVE_STRIPE_CUSTOMER_INFO_SUCCESS:
            // Assuming the payload contains the retrieved Stripe customer information
            // Update the state to include this information. You might store it separately,
            // or within a specific part of the user profile, depending on your application structure.
            return {
                ...state,
                stripeCustomerInfo: action.payload, // Store the retrieved Stripe customer info in the state
                error: null, // Clear any previous errors
            };

        case RETRIEVE_STRIPE_CUSTOMER_INFO_FAIL:
            // Assuming the payload contains an error message related to the retrieval failure
            return {
                ...state,
                error: action.payload, // Update the state with the error message from the failed retrieval
            };
        case CREATE_BILLING_PORTAL_SUCCESS:
            return {
                ...state,
                billingPortalUrl: payload.url, // Assuming payload contains the billing portal URL
                error: null, // Reset any error messages
            };

        case CREATE_BILLING_PORTAL_FAIL:
            return {
                ...state,
                billingPortalUrl: null, // Reset the billing portal URL
                error: payload, // Assuming payload contains the error message
            };
        case ADD_TO_MAILING_LIST_SUCCESS:
            return {
                ...state,
                mailingListSuccess: true,
                mailingListError: null,
            };
        case ADD_TO_MAILING_LIST_FAIL:
            return {
                ...state,
                mailingListSuccess: false,
                mailingListError: payload,
            };
        default:
            return state
    }
};