import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';
import './Blog.css';

function Blog() {
    const [posts, setPosts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filter, setFilter] = useState('');
    const [yearRange, setYearRange] = useState({ start: 2023, end: new Date().getFullYear() });
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [showSidebar, setShowSidebar] = useState(true);

    useEffect(() => {
        const handleResize = () => {
            const mobile = window.innerWidth <= 768;
            setIsMobile(mobile);
            setShowSidebar(!mobile);
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const fetchPosts = async () => {
            setIsLoading(true);
            try {
                const response = await fetch('/static/blog/index.json');
                if (!response.ok) {
                    throw new Error('Failed to fetch blog posts');
                }
                const data = await response.json();

                // Filter posts based on search, category, and year
                let filteredPosts = data.filter(post => {
                    const postDate = new Date(post.date);
                    const matchesSearch = !searchTerm ||
                        post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        post.description.toLowerCase().includes(searchTerm.toLowerCase());
                    const matchesCategory = !filter || post.category === filter;
                    const matchesYear = postDate.getFullYear() >= yearRange.start &&
                        postDate.getFullYear() <= yearRange.end;

                    return matchesSearch && matchesCategory && matchesYear;
                });

                setPosts(filteredPosts);
            } catch (err) {
                console.error('Error fetching blog posts:', err);
                setError('Failed to fetch blog posts');
            } finally {
                setIsLoading(false);
            }
        };

        fetchPosts();
    }, [searchTerm, filter, yearRange]);

    return (
        <div className="blog-page">
            {showSidebar && (
                <Sidebar
                    onSearch={setSearchTerm}
                    onFilterChange={setFilter}
                    onYearRangeChange={setYearRange}
                    currentYear={new Date().getFullYear()}
                />
            )}
            <div className="blog-content">
                <div className="blog-header-container">
                    <h1 className="blog-header">Blog Posts</h1>
                </div>
                <div className="blog-grid-container">
                    {isLoading ? (
                        <p>Loading posts...</p>
                    ) : error ? (
                        <p className="error-message">{error}</p>
                    ) : posts.length > 0 ? (
                        <div className="blog-grid">
                            {posts.map((post) => (
                                <BlogCard
                                    key={post.slug}
                                    post={post}
                                />
                            ))}
                        </div>
                    ) : (
                        <p>No posts found</p>
                    )}
                </div>
            </div>
        </div>
    );
}

function BlogCard({ post }) {
    const formattedDate = new Date(post.date).toLocaleDateString('en-US', {
        month: 'numeric',
        day: 'numeric',
        year: '2-digit'
    });

    return (
        <div className="blog-post-card">
            <Link to={`/blog/${post.slug}`} className="blog-post-link">
                <img src={post.thumbnail} alt={post.title} />
                <div className="post-content">
                    <p className="post-date">{formattedDate}</p>
                    <h3 className="post-title">{post.title}</h3>
                    <p className="post-category">{post.category}</p>
                    <p className="post-excerpt">{post.description}</p>
                    <button className="read-more">Read More</button>
                </div>
            </Link>
        </div>
    );
}

function Sidebar({ onSearch, onFilterChange, onYearRangeChange, currentYear }) {
    const [searchInput, setSearchInput] = useState('');
    const [yearStart, setYearStart] = useState(2023);
    const [yearEnd, setYearEnd] = useState(currentYear);

    const handleSubmit = (e) => {
        e.preventDefault();
        onSearch(searchInput);
    };

    const handleYearChange = (start, end) => {
        setYearStart(start);
        setYearEnd(end);
        onYearRangeChange({ start, end });
    };

    return (
        <aside className="blog-sidebar">
            <h2>Welcome</h2>
            <p>Explore our latest insights on real estate trends.</p>
            <form onSubmit={handleSubmit} className="blog-search-form">
                <input
                    type="text"
                    placeholder="Search: state or city"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    className="blog-search-input"
                />
                <button type="submit" className="blog-search-button">
                    <FaSearch />
                </button>
            </form>
            <h3>Filter Blogs</h3>
            <ul>
                <li onClick={() => onFilterChange('')}>All</li>
                <li onClick={() => onFilterChange('Trending')}>Trending</li>
                <li onClick={() => onFilterChange('Population Statistics')}>Population Statistics</li>
                <li onClick={() => onFilterChange('Pricing Statistics')}>Pricing Statistics</li>
            </ul>
            <h3>Year</h3>
            <div className="blog-year-range">
                <input
                    type="number"
                    value={yearStart}
                    min="2000"
                    max={currentYear}
                    onChange={(e) => handleYearChange(parseInt(e.target.value), yearEnd)}
                    className="blog-year-input"
                />
                <span>to</span>
                <input
                    type="number"
                    value={yearEnd}
                    min="2000"
                    max={currentYear}
                    onChange={(e) => handleYearChange(yearStart, parseInt(e.target.value))}
                    className="blog-year-input"
                />
            </div>
        </aside>
    );
}

export default Blog;