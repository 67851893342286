// src/reducers/blogReducer.js

import {
    FETCH_BLOG_POSTS_SUCCESS,
    FETCH_BLOG_POSTS_FAIL,
    FETCH_BLOG_POST_SUCCESS,
    FETCH_BLOG_POST_FAIL,
    CREATE_BLOG_POST_SUCCESS,
    CREATE_BLOG_POST_FAIL,
    DELETE_BLOG_POST_SUCCESS
} from '../actions/types';

const initialState = {
    posts: [],
    currentPost: null,
    error: null
};

export default function blogReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_BLOG_POSTS_SUCCESS:
            return {
                ...state,
                posts: action.payload,
                error: null,
                loading: false
            };
        case FETCH_BLOG_POST_SUCCESS:
            return {
                ...state,
                currentPost: action.payload,
                error: null,
                loading: false
            };
        case CREATE_BLOG_POST_SUCCESS:
            return {
                ...state,
                posts: [action.payload, ...state.posts],
                error: null
            };
        case FETCH_BLOG_POSTS_FAIL:
        case FETCH_BLOG_POST_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case CREATE_BLOG_POST_FAIL:
            return {
                ...state,
                error: action.payload
            };
        case DELETE_BLOG_POST_SUCCESS:
            return {
                ...state,
                posts: state.posts.filter(post => post.slug !== action.payload),
                currentPost: null
            };
        default:
            return state;
    }
}