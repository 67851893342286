import React from 'react';
import './DataTypeSelector.css'

const dataTypes = [
    {
        type: "Algorithm",
        subtitle: "Valuation Index"
    },
    {
        type: "Recent Home Sales",
        subtitle: "Recent Sales Summary | Latest Sales"
    },
    {
        type: "Location Metrics",
        subtitle: "Location Summary | Household Valuations | Housing Built Years"
    },
    {
        type: "Population",
        subtitle: "Quick Population Statistics | Population Growth Trends | Household Trends..."
    },
    {
        type: "School",
        subtitle: "Enrollment by Grade Level | Public vs Private Enrollment"
    },
    {
        type: "Crime",
        subtitle: "Violent Crime Analysis | Property Crime Analysis"
    },
    {
        type: "Income",
        subtitle: "Quick Income Statistics | Income Distribution"
    },
    {
        type: "Dynamic Comparison",
        subtitle: "Population Comparison | Income Comparison"
    }
];

const DataTypeSelector = ({ selectedType, onTypeSelect }) => {
    const handleTypeClick = (type) => {
        if (onTypeSelect && typeof onTypeSelect === 'function') {
            onTypeSelect(type);
        }
    };

    return (
        <div className="data-type-selector">
            {dataTypes.map((item) => (
                <div
                    key={item.type}
                    className={`data-type-item ${selectedType === item.type ? 'selected' : ''}`}
                    onClick={() => handleTypeClick(item.type)}
                    data-type={item.type}
                >
                    <h3>{item.type}</h3>
                    <p>{item.subtitle}</p>
                </div>
            ))}
        </div>
    );
};

export default DataTypeSelector;