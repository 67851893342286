import React, { useMemo } from 'react';
import { useSpring, animated } from 'react-spring';

const SubtypeOutline = React.memo(({ subtype, boxCount, isFirstSubtype, isFirstPage }) => {
  // console.log(`Rendering SubtypeOutline for ${subtype}`);

  const baseHeight = 120;
  const boxHeight = 178;
  const boxPadding = 45;
  const singleBoxWidth = 46;
  const doubleBoxWidth = 92;
  const subtypeMargin = isFirstPage
    ? (isFirstSubtype ? 85 : 10)
    : (isFirstSubtype ? 60 : 10);
  const interRowMargin = 55;

  const rows = Math.ceil(boxCount / 2);
  const height = baseHeight +
    (rows * boxHeight) +
    ((rows - 1) * boxPadding) +
    (rows > 1 ? (rows - 1) * interRowMargin : 0) +
    6;
  const width = boxCount === 1 ? singleBoxWidth : doubleBoxWidth;

  const animation = useSpring({
    width: `${width}%`,
    height: `${height}px`,
    config: { tension: 100, friction: 20 },
  });

  return (
    <animated.div
      className="subtype-outline"
      style={{
        ...animation,
        marginTop: `${subtypeMargin}px`,
        marginLeft: '4%',
        position: 'relative',
        border: '1px solid black', // Adding a black line box
      }}
    />
  );
});

const SubtypeOverlay = React.memo(({ subtypes, contentBoxes, pageIndex }) => {
  // console.log(`Rendering SubtypeOverlay for page ${pageIndex}`);

  const boxesBySubtype = useMemo(() => {
    // console.log('Recalculating boxesBySubtype');
    return contentBoxes.reduce((acc, box) => {
      if (!acc[box.subtype]) acc[box.subtype] = [];
      acc[box.subtype].push(box);
      return acc;
    }, {});
  }, [contentBoxes]);

  const subtypesOnThisPage = useMemo(() => {
    // console.log('Recalculating subtypesOnThisPage');
    return subtypes.filter(subtype =>
      (boxesBySubtype[subtype] && boxesBySubtype[subtype].length > 0)
    );
  }, [subtypes, boxesBySubtype]);

  const isFirstPage = pageIndex === 0;

  return (
    <div className="subtype-overlay">
      {subtypesOnThisPage.map((subtype, index) => {
        const subtypeBoxes = boxesBySubtype[subtype] || [];
        const boxCount = subtypeBoxes.length;

        return (
          <SubtypeOutline
            key={subtype}
            subtype={subtype}
            boxCount={boxCount}
            isFirstSubtype={index === 0}
            isFirstPage={isFirstPage}
          />
        );
      })}
    </div>
  );
});

export default SubtypeOverlay;