import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import MarketOverlays from '../components/MarketOverlays';

const Dashboard = () => {
    const [searchParams] = useSearchParams();
    const [mode, setMode] = useState('simple');
    const [selectedFeatures, setSelectedFeatures] = useState([]);

    useEffect(() => {
        const modeParam = searchParams.get('mode');
        if (modeParam && ['simple', 'custom', 'research'].includes(modeParam)) {
            setMode(modeParam);
        }
    }, [searchParams]);

    return (
        <div>
            <MarketOverlays
                mode={mode}
                setMode={setMode}
                selectedFeatures={selectedFeatures}
            />
        </div>
    );
};

export default Dashboard;