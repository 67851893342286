// Tutorial.js
import React, { useState, useEffect, useRef } from 'react';
import customSheets from '../assets/custom-sheets.svg';
import TutorialExit from "../assets/TutorialExit.svg";
import blueArrowIcon from "../assets/blueArrow.png";
import './Tutorial.css';

const Tutorial = () => {
    const [showTutorial, setShowTutorial] = useState(false);
    const [currentStep, setCurrentStep] = useState('overview');
    const [targetPosition, setTargetPosition] = useState(null);

    useEffect(() => {
        const shouldShowTutorial = localStorage.getItem('showTutorial');
        if (shouldShowTutorial === null || shouldShowTutorial === 'true') {
            setShowTutorial(true);
        }
    }, []);

    useEffect(() => {
        if (currentStep === 'searchBar') {
            const updatePosition = () => {
                const searchBar = document.querySelector('.marketoverlays-search-input');
                if (searchBar) {
                    const rect = searchBar.getBoundingClientRect();
                    setTargetPosition({
                        top: rect.top,
                        left: rect.left,
                        width: rect.width,
                        height: rect.height,
                        bubbleTop: rect.bottom + 10,
                        bubbleLeft: rect.left + (rect.width / 2) - 100, // Center bubble under searchbar
                    });
                }
            };

            updatePosition();
            window.addEventListener('resize', updatePosition);
            return () => window.removeEventListener('resize', updatePosition);
        } else if (currentStep === 'sidebar') {
            const updatePosition = () => {
                const sidebar = document.querySelector('.main-sidebar');
                if (sidebar) {
                    const rect = sidebar.getBoundingClientRect();
                    setTargetPosition({
                        top: rect.top,
                        left: rect.left,
                        width: rect.width,
                        height: rect.height,
                        bubbleTop: rect.top + 20, // Position near top of sidebar
                        bubbleLeft: rect.right + 20, // Position to the right of sidebar
                    });
                }
            };

            updatePosition();
            window.addEventListener('resize', updatePosition);
            return () => window.removeEventListener('resize', updatePosition);
        } else if (currentStep === 'statistics') {
            const updatePosition = () => {
                const statsTab = document.querySelector('.statistics-tab');
                if (statsTab) {
                    const rect = statsTab.getBoundingClientRect();
                    setTargetPosition({
                        top: rect.top,
                        left: rect.left,
                        width: rect.width,
                        height: rect.height,
                        bubbleTop: rect.top + rect.height / 3, // Position in middle third of stats tab
                        bubbleLeft: rect.right + 20, // Position to the right
                    });
                }
            };

            updatePosition();
            window.addEventListener('resize', updatePosition);
            return () => window.removeEventListener('resize', updatePosition);
        } else if (currentStep === 'addButton') {
            const updatePosition = () => {
                const statsTab = document.querySelector('.statistics-tab');
                const plusButton = document.querySelector('.plus-button');
                if (statsTab && plusButton) {
                    const statsRect = statsTab.getBoundingClientRect();
                    const buttonRect = plusButton.getBoundingClientRect();
                    setTargetPosition({
                        // Keep the statistics tab highlight area
                        top: statsRect.top,
                        left: statsRect.left,
                        width: statsRect.width,
                        height: statsRect.height,
                        // Position bubble next to the plus button
                        bubbleTop: buttonRect.top,
                        bubbleLeft: buttonRect.right + 20,
                        // Store button position for automated click
                        buttonTop: buttonRect.top,
                        buttonLeft: buttonRect.left,
                        buttonWidth: buttonRect.width,
                        buttonHeight: buttonRect.height
                    });
                }
            };

            updatePosition();
            window.addEventListener('resize', updatePosition);
            return () => window.removeEventListener('resize', updatePosition);
        } else if (currentStep === 'marketPage') {
            const updatePosition = () => {
                const marketContainer = document.querySelector('.market-mode-page-container');
                const header = document.querySelector('.market-mode-subtype-header');
                if (marketContainer && header) {
                    const containerRect = marketContainer.getBoundingClientRect();
                    const headerRect = header.getBoundingClientRect();
                    setTargetPosition({
                        // Target entire market page container
                        top: containerRect.top,
                        left: containerRect.left,
                        width: containerRect.width,
                        height: containerRect.height,
                        // Position bubble to the left of header
                        bubbleTop: headerRect.top - 20, // Align bubble with header
                        bubbleLeft: headerRect.left - 250, // Position bubble to left of header with space for arrow
                    });
                }
            };

            updatePosition();
            window.addEventListener('resize', updatePosition);
            return () => window.removeEventListener('resize', updatePosition);
        } else if (currentStep === 'printIcon') {
            const updatePosition = () => {
                const marketContainer = document.querySelector('.market-mode-page-container');
                const printIcon = document.querySelector('.print-icon');
                if (marketContainer && printIcon) {
                    const containerRect = marketContainer.getBoundingClientRect();
                    const iconRect = printIcon.getBoundingClientRect();
                    setTargetPosition({
                        // Keep targeting entire market page
                        top: containerRect.top,
                        left: containerRect.left,
                        width: containerRect.width,
                        height: containerRect.height,
                        // Position bubble above print icon
                        bubbleTop: iconRect.top - 120, // Position bubble above icon
                        bubbleLeft: iconRect.left - 150, // Center bubble relative to icon
                    });
                }
            };

            updatePosition();
            window.addEventListener('resize', updatePosition);
            return () => window.removeEventListener('resize', updatePosition);
        }
    }, [currentStep]);

    const handleExit = () => {
        setShowTutorial(false);
        localStorage.setItem('showTutorial', 'false');
    };

    const handleStartTour = () => {
        setCurrentStep('searchBar');
    };

    const handleNext = () => {
        if (currentStep === 'searchBar') {
            setCurrentStep('sidebar');
        } else if (currentStep === 'sidebar') {
            setCurrentStep('statistics');
            const algoTab = document.querySelector('.algo-icon');
            if (algoTab) {
                algoTab.click();
            }
        } else if (currentStep === 'statistics') {
            setCurrentStep('addButton');
        } else if (currentStep === 'addButton') {
            setCurrentStep('marketPage');
        } else if (currentStep === 'marketPage') {
            setCurrentStep('printIcon');
        }
    };

    if (!showTutorial) return null;

    const renderOverview = () => (
        <div className="tutorial-overlay">
            <div className="tutorial-container">
                <div className="tutorial-content">
                    <div className="tutorial-image-container">
                        <img src={customSheets} alt="Custom Sheets" className="tutorial-image" />
                    </div>
                    <h1 className="tutorial-title">Custom Sheets</h1>
                    <p className="tutorial-text">
                        Welcome to the Custom page! Start by entering your state, city, or neighborhood
                        of interest in the top left corner, followed by the neighborhoods of interest.
                    </p>
                    <p className="tutorial-text tutorial-text--padded">
                        Then simple toggle thought the navigation on the left to filter through
                        the different data categories.
                    </p>
                    <div className="tutorial-buttons">
                        <button className="tutorial-button tutorial-button--start" onClick={() => setShowTutorial(false)}>
                            Skip
                        </button>
                        <button className="tutorial-button tutorial-button--tour" onClick={handleStartTour}>
                            Take Tour
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );

    const renderSearchBarBubble = () => {
        if (!targetPosition) return null;

        const bubbleStyle = {
            top: `${targetPosition.bubbleTop}px`,
            left: `${targetPosition.bubbleLeft}px`
        };

        const highlightStyle = {
            '--target-top': `${targetPosition.top}px`,
            '--target-left': `${targetPosition.left}px`,
            '--target-width': `${targetPosition.width}px`,
            '--target-height': `${targetPosition.height}px`
        };

        return (
            <div className="tutorial-overlay" style={highlightStyle}>
                <div className="tutorial-highlight-area" />
                <div className="tutorial-clear-area" />
                <div className="tutorial-bubble tutorial-bubble--searchbar" style={bubbleStyle}>
                    <div className="tutorial-bubble-arrow" />
                    <img
                        src={TutorialExit}
                        alt="Exit Tutorial"
                        className="tutorial-exit-icon"
                        onClick={handleExit}
                    />
                    <p className="tutorial-bubble-text">
                        Search any city, town, or zip code of interest to get specific real estate relates statistics.
                    </p>
                    <img
                        src={blueArrowIcon}
                        alt="Next"
                        className="tutorial-next-icon"
                        onClick={handleNext}
                    />
                </div>
            </div>
        );
    };

    // Add new render function for sidebar bubble
    const renderSidebarBubble = () => {
        if (!targetPosition) return null;

        const bubbleStyle = {
            top: `${targetPosition.bubbleTop}px`,
            left: `${targetPosition.bubbleLeft}px`
        };

        const highlightStyle = {
            '--target-top': `${targetPosition.top}px`,
            '--target-left': `${targetPosition.left}px`,
            '--target-width': `${targetPosition.width}px`,
            '--target-height': `${targetPosition.height}px`
        };

        return (
            <div className="tutorial-overlay" style={highlightStyle}>
                <div className="tutorial-highlight-area" />
                <div className="tutorial-bubble tutorial-bubble--sidebar" style={bubbleStyle}>
                    {/* Changed this line to create a dedicated left arrow div */}
                    <div className="tutorial-bubble-arrow-left" />
                    <img
                        src={TutorialExit}
                        alt="Exit Tutorial"
                        className="tutorial-exit-icon"
                        onClick={handleExit}
                    />
                    <p className="tutorial-bubble-text">
                        Navigate through different data sets using the navigation bar on the left.
                    </p>
                    <img
                        src={blueArrowIcon}
                        alt="Next"
                        className="tutorial-next-icon"
                        onClick={handleNext}
                    />
                </div>
            </div>
        );
    };

    const renderStatisticsBubble = () => {
        if (!targetPosition) return null;

        const bubbleStyle = {
            top: `${targetPosition.bubbleTop}px`,
            left: `${targetPosition.bubbleLeft}px`
        };

        const highlightStyle = {
            '--target-top': `${targetPosition.top}px`,
            '--target-left': `${targetPosition.left}px`,
            '--target-width': `${targetPosition.width}px`,
            '--target-height': `${targetPosition.height}px`
        };

        return (
            <div className="tutorial-overlay" style={highlightStyle}>
                <div className="tutorial-highlight-area" />
                <div className="tutorial-bubble tutorial-bubble--statistics" style={bubbleStyle}>
                    <div className="tutorial-bubble-arrow-left" />
                    <img
                        src={TutorialExit}
                        alt="Exit Tutorial"
                        className="tutorial-exit-icon"
                        onClick={handleExit}
                    />
                    <p className="tutorial-bubble-text">
                        Data sets will display in a pop-out to the right of the data navigation menu.
                    </p>
                    <img
                        src={blueArrowIcon}
                        alt="Next"
                        className="tutorial-next-icon"
                        onClick={handleNext}
                    />
                </div>
            </div>
        );
    };

    // Add new render function for add button bubble
    const renderAddButtonBubble = () => {
        if (!targetPosition) return null;

        const bubbleStyle = {
            top: `${targetPosition.bubbleTop}px`,
            left: `${targetPosition.bubbleLeft}px`
        };

        const highlightStyle = {
            '--target-top': `${targetPosition.top}px`,
            '--target-left': `${targetPosition.left}px`,
            '--target-width': `${targetPosition.width}px`,
            '--target-height': `${targetPosition.height}px`
        };

        const handleAddButtonNext = () => {
            // Find and click the plus button before moving to next step
            const plusButton = document.querySelector('.plus-button');
            if (plusButton) {
                plusButton.click();
            }
            handleNext();
        };

        return (
            <div className="tutorial-overlay" style={highlightStyle}>
                <div className="tutorial-highlight-area" />
                <div className="tutorial-bubble tutorial-bubble--add-button" style={bubbleStyle}>
                    <div className="tutorial-bubble-arrow-left" />
                    <img
                        src={TutorialExit}
                        alt="Exit Tutorial"
                        className="tutorial-exit-icon"
                        onClick={handleExit}
                    />
                    <p className="tutorial-bubble-text">
                        Add or remove desired data sets to your custom market sheet.
                    </p>
                    <img
                        src={blueArrowIcon}
                        alt="Next"
                        className="tutorial-next-icon"
                        onClick={handleAddButtonNext}
                    />
                </div>
            </div>
        );
    };

    const renderMarketPageBubble = () => {
        if (!targetPosition) return null;

        const bubbleStyle = {
            top: `${targetPosition.bubbleTop}px`,
            left: `${targetPosition.bubbleLeft}px`
        };

        const highlightStyle = {
            '--target-top': `${targetPosition.top}px`,
            '--target-left': `${targetPosition.left}px`,
            '--target-width': `${targetPosition.width}px`,
            '--target-height': `${targetPosition.height}px`
        };

        return (
            <div className="tutorial-overlay" style={highlightStyle}>
                <div className="tutorial-highlight-area" />
                <div className="tutorial-bubble tutorial-bubble--market-page" style={bubbleStyle}>
                    <div className="tutorial-bubble-arrow-right" />
                    <img
                        src={TutorialExit}
                        alt="Exit Tutorial"
                        className="tutorial-exit-icon"
                        onClick={handleExit}
                    />
                    <p className="tutorial-bubble-text">
                        Fill your market sheet with data that fits your specifications.
                    </p>
                    <img
                        src={blueArrowIcon}
                        alt="Next"
                        className="tutorial-next-icon"
                        onClick={handleNext}
                    />
                </div>
            </div>
        );
    };

    const renderPrintIconBubble = () => {
        if (!targetPosition) return null;

        const bubbleStyle = {
            top: `${targetPosition.bubbleTop}px`,
            left: `${targetPosition.bubbleLeft}px`
        };

        const highlightStyle = {
            '--target-top': `${targetPosition.top}px`,
            '--target-left': `${targetPosition.left}px`,
            '--target-width': `${targetPosition.width}px`,
            '--target-height': `${targetPosition.height}px`
        };

        return (
            <div className="tutorial-overlay" style={highlightStyle}>
                <div className="tutorial-highlight-area" />
                <div className="tutorial-bubble tutorial-bubble--print" style={bubbleStyle}>
                    <img
                        src={TutorialExit}
                        alt="Exit Tutorial"
                        className="tutorial-exit-icon"
                        onClick={handleExit}
                    />
                    <p className="tutorial-bubble-text">
                        When satisfied with your sheet simply print or save out your custom market sheet.
                    </p>
                    <div className="tutorial-bubble-arrow-bottom-right" />
                    <img
                        src={blueArrowIcon}
                        alt="Next"
                        className="tutorial-next-icon"
                        onClick={handleExit}
                    />
                </div>
            </div>
        );
    };

    return currentStep === 'overview'
        ? renderOverview()
        : currentStep === 'searchBar'
            ? renderSearchBarBubble()
            : currentStep === 'sidebar'
                ? renderSidebarBubble()
                : currentStep === 'statistics'
                    ? renderStatisticsBubble()
                    : currentStep === 'addButton'
                        ? renderAddButtonBubble()
                        : currentStep === 'marketPage'
                            ? renderMarketPageBubble()
                            : renderPrintIconBubble();
};

export default Tutorial;