import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import HeroSection from '../components/HeroSection.js';
import DashboardSection from '../components/DashboardSection.js';
import SheetsSection from '../components/SheetsSection.js';
import SheetsExamples from '../components/SheetsExamples.js';
import UserDashboardHomePage from '../components/UserDashboardHomePage.js';
import MarketingSection from '../components/MarketingSection.js';
import TestimonialSection from '../components/TestimonialSection.js';
import Footer from '../components/Footer.js';
import './Home.css';

const HomePage = () => {
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const signupSuccess = searchParams.get('verification');
    if (signupSuccess === 'success') {
      alert('Your account has been successfully verified!');
    }
  }, [location]);

  return (
    <div className="homepage-container">
      <HeroSection />
      <DashboardSection />
      <SheetsSection />
      <SheetsExamples />
      <UserDashboardHomePage />
      <MarketingSection />
      <TestimonialSection />
      <Footer />
    </div>
  );
};

export default HomePage;