import React, { useRef, useEffect, useState, useMemo } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const PieChartsComponent = ({ neighborhoods, startDate, endDate, dataType }) => {
    const containerRef = useRef(null);
    const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const updateSize = () => {
            if (containerRef.current) {
                const { width, height } = containerRef.current.getBoundingClientRect();
                setContainerSize({ width, height });
            }
        };

        updateSize();
        window.addEventListener('resize', updateSize);
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    // Helper function to calculate safety score
    const calculateSafetyScore = (crimeStats) => {
        if (!crimeStats) return 0;

        const weights = {
            murderIndex: 0.25,
            forcibleRapeIndex: 0.15,
            forcibleRobberyIndex: 0.15,
            aggravatedAssaultIndex: 0.15,
            burglaryIndex: 0.1,
            larcenyIndex: 0.1,
            motorVehicleIndex: 0.1
        };

        let weightedSum = 0;
        let maxPossible = 0;

        Object.entries(weights).forEach(([crime, weight]) => {
            if (typeof crimeStats[crime] === 'number') {
                weightedSum += (200 - crimeStats[crime]) * weight;
                maxPossible += 200 * weight;
            }
        });

        return maxPossible > 0 ? ((weightedSum / maxPossible) * 10).toFixed(1) : 0;
    };

    // Helper function to determine the actual chart type based on the dataType prop
    const getChartType = (dataType) => {
        if (dataType.includes('Household') || dataType.includes('Pop')) {
            return 'Population';
        }
        if (dataType.includes('Crime')) {
            return 'Crime';
        }
        if (dataType.includes('Income')) {
            return 'Income';
        }
        return null;
    };

    // Generate chart data based on data type 
    const chartData = useMemo(() => {
        if (!neighborhoods || neighborhoods.length === 0) {
            console.log('No neighborhoods data');
            return null;
        }

        const chartType = getChartType(dataType);
        console.log('Determined chart type:', chartType);

        switch (chartType) {
            case 'Population':
                return {
                    labels: neighborhoods.flatMap(n => [
                        `${n.name} (Owned)`,
                        `${n.name} (Rented)`
                    ]),
                    datasets: [{
                        data: neighborhoods.flatMap(n => {
                            console.log('Population data for', n.name, n.data?.demographics);
                            return [
                                n.data?.demographics?.housingUnitsOwnerOccupiedPct || 0,
                                n.data?.demographics?.housingUnitsRenterOccupiedPct || 0
                            ];
                        }),
                        backgroundColor: neighborhoods.flatMap(n => [n.color, `${n.color}80`]),
                        borderColor: neighborhoods.flatMap(n => [n.color, n.color]),
                        borderWidth: 1,
                    }]
                };

            case 'Crime':
                return {
                    labels: neighborhoods.map(n => n.name),
                    datasets: [{
                        data: neighborhoods.map(n => {
                            console.log('Crime data for', n.name, n.data?.crimeStatistics);
                            const crimeIndex = n.data?.crimeStatistics?.crimeIndex || 0;
                            // Convert crime index to safety score (invert the scale)
                            return ((200 - crimeIndex) / 2).toFixed(1);
                        }),
                        backgroundColor: neighborhoods.map(n => n.color),
                        borderColor: neighborhoods.map(n => n.color),
                        borderWidth: 1,
                    }]
                };

            case 'Income':
                return {
                    labels: neighborhoods.map(n => n.name),
                    datasets: [{
                        data: neighborhoods.map(n => {
                            console.log('Income data for', n.name, n.data?.economics);
                            return parseInt(n.data?.economics?.medianHouseholdIncome || 0);
                        }),
                        backgroundColor: neighborhoods.map(n => n.color),
                        borderColor: neighborhoods.map(n => n.color),
                        borderWidth: 1,
                    }]
                };

            default:
                console.log('Unknown chart type for:', dataType);
                return null;
        }
    }, [neighborhoods, dataType]);

    const chartOptions = useMemo(() => {
        const baseOptions = {
            responsive: true,
            maintainAspectRatio: false,
            cutout: '40%',
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    enabled: true,
                    callbacks: {
                        label: (context) => {
                            const value = context.raw;
                            const chartType = getChartType(dataType);
                            switch (chartType) {
                                case 'Population':
                                    return `${value.toFixed(1)}%`;
                                case 'Crime':
                                    return `Safety Score: ${value}/100`;
                                case 'Income':
                                    return `$${value.toLocaleString()}`;
                                default:
                                    return value;
                            }
                        }
                    }
                },
                datalabels: {
                    color: '#FFFFFF',
                    font: {
                        weight: 'bold',
                        size: 12
                    },
                    formatter: (value) => {
                        const chartType = getChartType(dataType);
                        switch (chartType) {
                            case 'Population':
                                return `${value.toFixed(1)}%`;
                            case 'Crime':
                                return `${value}/100`;
                            case 'Income':
                                return `$${(value / 1000).toFixed(0)}K`;
                            default:
                                return value;
                        }
                    },
                    anchor: 'center',
                    align: 'center',
                    offset: 0
                }
            }
        };

        return baseOptions;
    }, [dataType]);

    // If no valid chart data, show loading or error state
    if (!chartData) {
        return (
            <div ref={containerRef} style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                Unable to determine chart type for: {dataType}
            </div>
        );
    }

    return (
        <div ref={containerRef} style={{ width: '100%', height: '100%', display: 'flex' }}>
            <div style={{ width: '70%', height: `${Math.min(containerSize.width * 0.7, containerSize.height)}px` }}>
                <Pie data={chartData} options={chartOptions} />
            </div>
            <div style={{ width: '30%', padding: '10px' }}>
                {neighborhoods.map((n, i) => (
                    <div key={i} style={{ marginBottom: '10px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ width: '12px', height: '12px', backgroundColor: n.color, marginRight: '8px' }}></div>
                            <span style={{ fontSize: '12px', fontWeight: 'bold' }}>{n.name}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PieChartsComponent;