import React, { useContext, memo, useEffect, useState } from "react";
import { useSpring, animated } from "react-spring";
import "./StatisticsTab.css";

// Import icons
import algoIcon from "../assets/algo icon-01 1.svg";
import recentHomeSalesIcon from "../assets/home-general-icon.svg";
import schoolIcon from "../assets/schools-icon.svg";
import populationIcon from "../assets/community-icon.svg";
import crimeIcon from "../assets/crime-icon-01 1.svg";
import incomeIcon from "../assets/dollarsign-icon.svg";
import dynamicNeighborhoodComparisonIcon from "../assets/home-general-icon.svg";
import tabularNeighborhoodComparisonIcon from "../assets/home-general-icon.svg";
import savedPresetsIcon from "../assets/saved presets.svg";
import locationMetricsIcon from "../assets/locationMetricsIcon.svg";

import { FaArrowLeft, FaPlus, FaMinus, FaCheck } from "react-icons/fa";
import { MarketModeContext } from "../contexts/MarketModeContext";
import { cleanContentBoxForDisplay } from '../utils/contentTransformer';
import PopulationTab, {
    PopulationSummary,
    ResidentialDwellings,
    PopulationDemographics,
} from "./contentTabs/PopulationTab";
import SchoolsTab, {
    SchoolSummary,
    SchoolTypes,
    StudentEnrollment,
} from "./contentTabs/SchoolsTab";
import AlgoTab from "./contentTabs/AlgoTab";
import RecentHomeSalesTab from "./contentTabs/RecentHomeSalesTab";
import CrimeTab from "./contentTabs/CrimeTab";
import IncomeTab from "./contentTabs/IncomeTab";
import DynamicNeighborhoodComparisonTab from "./contentTabs/DynamicNeighborhoodComparisonTab";
import TabularNeighborhoodComparisonTab from "./contentTabs/TabularNeighborhoodComparisonTab";
import SavedPresetsTab from "./contentTabs/SavedPresetsTab";
import LocationMetricsTab from "./contentTabs/LocationMetricsTab";
import { contentBoxRegistry } from "./contentBoxRegistry";
import { fetchLocationStatistics, fetchMultipleRankings } from "../actions/auth";

const StatisticsTab = ({
    selectedTab,
    onClose,
    clickedFeature,
    selectedFeatures,
    isOpen,
    currentSelectionString,
}) => {
    const [locationData, setLocationData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [rankingsData, setRankingsData] = useState(null);

    const {
        pages,
        addContentBox,
        addMultipleContentBoxes,
        removeContentBox,
        removeAllContentBoxes,
    } = useContext(MarketModeContext);

    const tabAnimation = useSpring({
        transform: isOpen
            ? "translateX(0%) translateY(-47%)"
            : "translateX(-150%) translateY(-47%)",
        config: { duration: 300 },
    });

    useEffect(() => {
        const fetchData = async () => {
            console.log("StatisticsTab attempting to fetch data with:", {
                geoId: clickedFeature?.geoId,
                feature: clickedFeature,
            });

            if (clickedFeature?.id) {
                setIsLoading(true);
                try {
                    // First fetch location statistics to get the parent IDs
                    const locationStats = await fetchLocationStatistics(clickedFeature.id);
                    console.log("StatisticsTab received location data:", locationStats);

                    // Now we can use the correct IDs from locationStats for rankings
                    const rankings = await fetchMultipleRankings(
                        clickedFeature.id,
                        locationStats.geoType,
                        locationStats.geoType === 'NEIGHBORHOOD'
                            ? locationStats.cityId?.trim()
                            : locationStats.stateId?.trim()
                    );

                    console.log("StatisticsTab received rankings data:", rankings);

                    setLocationData(locationStats);
                    setRankingsData(rankings);
                    setError(null);
                } catch (err) {
                    setError(err.message);
                    console.error("Error fetching data:", err);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        fetchData();
    }, [clickedFeature?.id]);

    // const handleAddAllContentBoxes = () => {
    //     console.log('Adding all content boxes for tab:', selectedTab);
    //     const allContentBoxes = getAllContentBoxes(selectedTab);
    //     console.log('All content boxes:', allContentBoxes);
    //     const newContentBoxes = allContentBoxes.filter(newBox => {
    //         const isDuplicate = pages.some(page =>
    //             page.contentBoxes.some(existingBox =>
    //                 existingBox.title === newBox.title && existingBox.subtype === newBox.subtype
    //             )
    //         );
    //         console.log(`Box ${newBox.title} is ${isDuplicate ? 'a duplicate' : 'new'}`);
    //         return !isDuplicate;
    //     });
    //     console.log('New content boxes to add:', newContentBoxes);
    //     if (newContentBoxes.length > 0) {
    //         addMultipleContentBoxes(newContentBoxes);
    //     }
    // };

    // const handleRemoveAllContentBoxes = () => {
    //     removeAllContentBoxes();
    // };

    // const getAllContentBoxes = (tab) => {
    //     console.log("Getting all content boxes for tab:", tab);
    //     const tabContentMap = {
    //         population: [
    //             { contentType: "PopulationSummary", title: "Population Summary" },
    //             {
    //                 contentType: "ResidentialDwellings",
    //                 title: "Percent Residential Dwellings",
    //             },
    //             {
    //                 contentType: "PopulationDemographics",
    //                 title: "Population Demographics",
    //             },
    //         ],
    //         school: [
    //             { contentType: "SchoolSummary", title: "School Summary" },
    //             { contentType: "SchoolTypes", title: "School Types" },
    //             { contentType: "StudentEnrollment", title: "Student Enrollment" },
    //         ],
    //         algo: [
    //             { contentType: "ValuationTab", title: "Valuation Tab" },
    //             { contentType: "ValuationSummary", title: "Valuation Summary" },
    //         ],
    //         crime: [
    //             {
    //                 contentType: "CrimeStatsSummary",
    //                 title: "Neighborhood Crime Statistics",
    //             },
    //             { contentType: "CrimeTypeBreakdown", title: "Crime Type Breakdown" },
    //         ],
    //         income: [
    //             {
    //                 contentType: "MedianIncomeByAge",
    //                 title: "Median Income by Age Bracket",
    //             },
    //             { contentType: "PerCapitaIncome", title: "Per Capita Income" },
    //         ],
    //         recenthomesales: [
    //             // Note: changed to lowercase
    //             {
    //                 contentType: "RecentHomeSalesSummary",
    //                 title: "Recent Home Sales Summary",
    //             },
    //             { contentType: "RecentHomeSalesList", title: "Latest Home Sales" },
    //         ],
    //         dynamicneighborhoodcomparison: [
    //             {
    //                 contentType: "TopGrowthNeighborhoods",
    //                 title: "Top Growth Neighborhoods",
    //             },
    //             { contentType: "TopSchoolScores", title: "Top School Scores" },
    //             {
    //                 contentType: "TopTenPercentNeighborhoods",
    //                 title: "Top 10% Neighborhoods",
    //             },
    //             { contentType: "SafestNeighborhoods", title: "Safest Neighborhoods" },
    //         ],
    //         tabularneighborhoodcomparison: [
    //             {
    //                 contentType: "NeighborhoodComparisonTable",
    //                 title: "Neighborhood Comparison",
    //             },
    //         ],
    //         locationmetrics: [
    //             { contentType: "LocMetQuickStats", title: "Location Summary" },
    //             { contentType: "LocMetHsngValue", title: "Household Valuations" },
    //             { contentType: "LocMetHsngBltYr", title: "Housing Built Years" },
    //         ],
    //     };

    //     const tabContent = tabContentMap[tab.toLowerCase()] || [];
    //     console.log("Content for tab:", tabContent);

    //     return tabContent
    //         .map(({ contentType, title }) => {
    //             const Component = contentBoxRegistry[contentType];
    //             if (!Component) {
    //                 console.warn(`Component not found in registry: ${contentType}`);
    //                 return null;
    //             }
    //             console.log(
    //                 "Creating content box for:",
    //                 contentType,
    //                 "with title:",
    //                 title
    //             );

    //             return {
    //                 key: `${contentType}-${Date.now()}`,
    //                 title: title,
    //                 subtype: tab,
    //                 contentType: contentType,
    //                 content: <Component />,
    //             };
    //         })
    //         .filter(Boolean);
    // };

    // const areAllContentBoxesAdded = () => {
    //     const allContentBoxes = getAllContentBoxes(selectedTab);
    //     return allContentBoxes.every(box =>
    //         pages.some(page =>
    //             page.contentBoxes.some(existingBox =>
    //                 existingBox.contentType.toLowerCase() === box.contentType.toLowerCase() &&
    //                 existingBox.subtype.toLowerCase() === box.subtype.toLowerCase()
    //             )
    //         )
    //     );
    // };

    const renderTabHeader = (icon, title) => (
        <div className="tab-header">
            <div className="tab-header-left">
                <img src={icon} alt={title} className="header-icon" />
                <h2>{title}</h2>
            </div>
            <div className="tab-header-right">
                {/* {selectedTab !== 'savedPresets' && (
                    areAllContentBoxesAdded() ? (
                        <button className="add-all-button" onClick={handleRemoveAllContentBoxes}>
                            <FaMinus />
                        </button>
                    ) : (
                        <button className="add-all-button" onClick={handleAddAllContentBoxes}>
                            <FaPlus />
                        </button>
                    )
                )} */}
                <button className="close-button" onClick={onClose}>
                    &laquo;
                </button>
            </div>
        </div>
    );

    const renderContent = () => {
        if (isLoading) {
            return <div>Loading...</div>;
        }

        if (error) {
            return <div>Error: {error}</div>;
        }

        switch (selectedTab) {
            case "algo":
                return (
                    <div key="algo">
                        {renderTabHeader(algoIcon, "Algo Statistics")}
                        <p className="current-selection">{currentSelectionString}</p>
                        <AlgoTab ContentBox={ContentBox} locationData={locationData} />
                    </div>
                );
            case "recentHomeSales":
                return (
                    <div key="recentHomeSales">
                        {renderTabHeader(recentHomeSalesIcon, "Recent Home Sales")}
                        <p className="current-selection">{currentSelectionString}</p>
                        <RecentHomeSalesTab ContentBox={ContentBox} locationData={locationData} />
                    </div>
                );
            case "locationMetrics":
                return (
                    <div key="locationMetrics">
                        {renderTabHeader(locationMetricsIcon, "Location Metrics")}
                        <p className="current-selection">{currentSelectionString}</p>
                        <LocationMetricsTab ContentBox={ContentBox} locationData={locationData} />
                    </div>
                );
            case "school":
                return (
                    <div key="school">
                        {renderTabHeader(schoolIcon, "School Statistics")}
                        <p className="current-selection">{currentSelectionString}</p>
                        <SchoolsTab ContentBox={ContentBox} locationData={locationData} />
                    </div>
                );
            case "population":
                console.log(
                    "StatisticsTab rendering PopulationTab with data:",
                    locationData
                );
                return (
                    <div key="population">
                        {renderTabHeader(populationIcon, "Population Statistics")}
                        <p className="current-selection">{currentSelectionString}</p>
                        <PopulationTab
                            ContentBox={ContentBox}
                            locationData={locationData}
                        />
                    </div>
                );
            case "crime":
                return (
                    <div key="crime">
                        {renderTabHeader(crimeIcon, "Crime Statistics")}
                        <p className="current-selection">{currentSelectionString}</p>
                        <CrimeTab ContentBox={ContentBox} locationData={locationData} />
                    </div>
                );
            case "income":
                return (
                    <div key="income">
                        {renderTabHeader(incomeIcon, "Income Statistics")}
                        <p className="current-selection">{currentSelectionString}</p>
                        <IncomeTab ContentBox={ContentBox} locationData={locationData} />
                    </div>
                );
            case "dynamicNeighborhoodComparison":
                return (
                    <div key="dynamicNeighborhoodComparison">
                        {renderTabHeader(
                            dynamicNeighborhoodComparisonIcon,
                            "Dynamic Neighborhood Comparison"
                        )}
                        <p className="current-selection">{currentSelectionString}</p>
                        <DynamicNeighborhoodComparisonTab
                            ContentBox={ContentBox}
                            clickedFeature={clickedFeature}
                            rankingsData={rankingsData}
                        />
                    </div>
                );
            case "savedPresets":
                return (
                    <div key="savedPresets">
                        {renderTabHeader(savedPresetsIcon, "Saved Market Sheets")}
                        <SavedPresetsTab ContentBox={ContentBox} />
                    </div>
                );
            default:
                return null;
        }
    };

    const ContentBox = memo(
        ({
            title,
            children,
            flexibleHeight,
            contentType,
            subtype,
            customHeaderStyle,
            hideHeaderButtons,
        }) => {
            const renderButtons = selectedTab !== "savedPresets";
            const isAdded = pages.some((page) =>
                page.contentBoxes.some(
                    (box) =>
                        box.contentType === contentType &&
                        box.subtype === subtype &&
                        box.locationId === clickedFeature?.geoId
                )
            );

            const handleAddContentBox = () => {
                if (!isAdded) {
                    const key = `${contentType}-${clickedFeature?.geoId}-${Date.now()}`;
                    const contentBoxData = {
                        key,
                        title,
                        subtype: selectedTab,
                        content: cleanContentBoxForDisplay({ content: children }).content,
                        contentType: contentType,
                        locationId: clickedFeature?.geoId,
                        locationName: currentSelectionString,
                        locationData: locationData,
                    };
                    addContentBox(contentBoxData);
                }
            };

            const handleRemoveContentBox = () => {
                const contentBoxToRemove = pages
                    .flatMap((page) => page.contentBoxes)
                    .find(
                        (box) =>
                            box.contentType === contentType &&
                            box.subtype === subtype &&
                            box.locationId === clickedFeature?.geoId
                    );
                if (contentBoxToRemove) {
                    removeContentBox(contentBoxToRemove);
                }
            };

            const contentBoxClass =
                subtype === "savedPresetsTab"
                    ? "content-box saved-presets-content-box"
                    : "content-box";

            return (
                <div
                    className={`${contentBoxClass} ${flexibleHeight ? "flexible-height" : ""
                        }`}
                >
                    <div className="box-header" style={customHeaderStyle}>
                        <div className="box-header-content">
                            <span>{title}</span>
                        </div>
                        {!hideHeaderButtons && renderButtons && (
                            <div className="box-header-buttons">
                                {selectedTab !== "savedPresets" && (
                                    <button
                                        className="minus-button"
                                        onClick={handleRemoveContentBox}
                                    >
                                        <FaMinus />
                                    </button>
                                )}
                                <button
                                    className={`plus-button ${isAdded ? "added" : ""}`}
                                    onClick={handleAddContentBox}
                                >
                                    {isAdded ? <FaCheck /> : <FaPlus />}
                                </button>
                            </div>
                        )}
                    </div>
                    <div className="box-content">
                        <div className="box-location-label">{currentSelectionString}</div>
                        {children}
                    </div>
                </div>
            );
        }
    );

    return (
        <animated.div className="statistics-tab" style={tabAnimation}>
            {isOpen && <>{renderContent()}</>}
        </animated.div>
    );
};

export default StatisticsTab;
