import React, { useState, useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { fetchLocationBoundary } from '../utils/locationData';
import './MapPreview.css';
import * as turf from '@turf/turf';

mapboxgl.accessToken = `${process.env.REACT_APP_MAPBOX_API_TOKEN}`;


const MapPreview = ({ location, allLocations }) => {
    const mapContainer = useRef(null);
    const [map, setMap] = useState(null);
    const [boundaryData, setBoundaryData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isMapReady, setIsMapReady] = useState(false);
    const [displayName, setDisplayName] = useState('');

    useEffect(() => {
        const loadBoundaryData = async () => {
            setIsLoading(true);
            setIsMapReady(false);

            let data = await fetchLocationBoundary(location, allLocations);
            let nameToDisplay = location.name;

            if (data && location.type === 'neighborhood' && location.fullName) {
                const cityName = location.fullName.split(',')[1].trim();
                nameToDisplay = `${location.name}, ${cityName}`;
            } else if (!data) {
                nameToDisplay = "Rhode Island"; // Fallback to state level
            }

            setBoundaryData(data);
            setDisplayName(nameToDisplay);
            setIsLoading(false);
        };

        loadBoundaryData();
    }, [location, allLocations]);

    const getZoomLevelForArea = (area) => {
        const areaInSqKm = area / 1000000;
        if (areaInSqKm > 10000) return 7;
        if (areaInSqKm > 1000) return 8;
        if (areaInSqKm > 100) return 9;
        if (areaInSqKm > 10) return 10;
        return 11;
    };

    const createMap = (boundaryData) => {
        if (map) {
            map.remove();
        }

        setIsMapReady(false);

        const newMap = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [0, 0],
            zoom: 1,
            interactive: false
        });

        newMap.on('load', () => {
            newMap.addSource('boundary', {
                type: 'geojson',
                data: boundaryData
            });

            newMap.addLayer({
                id: 'boundary-fill',
                type: 'fill',
                source: 'boundary',
                paint: {
                    'fill-color': 'rgba(19, 82, 226, 0.3)',
                    'fill-outline-color': 'rgba(19, 82, 226, 1)'
                }
            });

            const bounds = turf.bbox(boundaryData.geometry);
            const center = turf.centroid(boundaryData.geometry);
            const area = turf.area(boundaryData.geometry);
            const zoomLevel = getZoomLevelForArea(area);

            newMap.fitBounds(bounds, {
                padding: 20,
                maxZoom: zoomLevel,
                duration: 0
            });

            newMap.setCenter(center.geometry.coordinates);

            // Use requestAnimationFrame to ensure the map has finished rendering
            requestAnimationFrame(() => {
                setIsMapReady(true);
            });
        });

        setMap(newMap);
    };

    useEffect(() => {
        const loadBoundaryData = async () => {
            setIsLoading(true);
            setIsMapReady(false);
            const data = await fetchLocationBoundary(location);
            setBoundaryData(data);
            setIsLoading(false);
        };

        loadBoundaryData();
    }, [location]);

    useEffect(() => {
        if (boundaryData) {
            createMap(boundaryData);
        }
    }, [boundaryData]);

    if (isLoading) {
        return <div className="map-preview-loading">Loading...</div>;
    }

    return (
        <div className="map-preview">
            <div
                ref={mapContainer}
                className="map-container"
                style={{ visibility: isMapReady ? 'visible' : 'hidden' }}
            />
            {isMapReady && (
                <div className="map-label">
                    <p>{displayName}</p>
                </div>
            )}
            {!isMapReady && <div className="map-preview-loading">Rendering map...</div>}
        </div>
    );
};

export default MapPreview;