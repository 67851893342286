// src/containers/ResetPasswordConfirm.js
import React, { useState } from 'react';
import { confirmResetPassword } from '@aws-amplify/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import './ResetPassword.css';

const ResetPasswordConfirm = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      await confirmResetPassword({
        username: email,
        confirmationCode,
        newPassword
      });
      alert('Password reset successful. You can now log in with your new password.');
      navigate('/login');
    } catch (err) {
      console.error('Error in password reset confirmation:', err);
      setError(err.message || 'An error occurred during password reset confirmation.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-page">
      <div className="login-card">
        <form onSubmit={handleSubmit}>
          <h2 className="login-title">Confirm Password Reset</h2>
          {error && <p className="error-message">{error}</p>}
          <div className="input-group">
            <input
              type="text"
              placeholder="Confirmation Code"
              value={confirmationCode}
              onChange={(e) => setConfirmationCode(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <input
              type="password"
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="login-btn" disabled={isLoading}>
            {isLoading ? 'Confirming...' : 'Confirm Reset'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ResetPasswordConfirm;