// SheetsExamples.js
import React from 'react';
import './SheetsExamples.css';
import exampleImage1 from '../assets/Sheets_Example_Image_1.svg';
import exampleImage2 from '../assets/Sheets_Example_Image_2.svg';
import exampleImage3 from '../assets/Sheets_Example_Image_3.svg';

const SheetsExamples = () => {
    return (
        <div className="sheets-examples-container">
            {/* Row One */}
            <div className="sheets-examples-row">
                <div className="sheets-examples-text-section sheets-examples-text-left">
                    <h2 className="sheets-examples-heading-simple">Simple Sheets</h2>
                    <p className="sheets-examples-paragraph-simple">Generate polished, data-packed one-pagers that position you as the go-to expert in any neighborhood. With RealAnalytica, you can walk into every meeting with a powerful, marketable report in hand, making a lasting impression on clients.</p>
                </div>
                <div className="sheets-examples-image-section sheets-examples-align-right">
                    <img src={exampleImage1} alt="Simple Sheets Example" className="sheets-examples-image sheets-examples-image-1" />
                </div>
            </div>

            {/* Row Two */}
            <div className="sheets-examples-row">
                <div className="sheets-examples-image-section sheets-examples-align-left">
                    <img src={exampleImage2} alt="Custom Market Sheets Example" className="sheets-examples-image sheets-examples-image-2" />
                </div>
                <div className="sheets-examples-text-section sheets-examples-text-right">
                    <h2 className="sheets-examples-heading-custom">Custom Market Sheets</h2>
                    <p className="sheets-examples-paragraph-custom">Tired of juggling multiple data sources and spending hours on real estate analysis? Our all-in-one data analytics tool, designed for Realtors, gives you access to comprehensive real estate data on a neighborhood level.</p>
                </div>
            </div>

            {/* Row Three */}
            <div className="sheets-examples-row">
                <div className="sheets-examples-text-section sheets-examples-text-left">
                    <h2 className="sheets-examples-heading-detailed">Detailed Research</h2>
                    <p className="sheets-examples-paragraph-detailed">Dive into real estate analytics in your desired area! This tool will allow you to conduct crucial real estate market research that will elevate your in sign on the market. More knowledge equals more power!</p>
                </div>
                <div className="sheets-examples-image-section sheets-examples-align-right">
                    <img src={exampleImage3} alt="Detailed Research Example" className="sheets-examples-image sheets-examples-image-3" />
                </div>
            </div>
        </div>
    );
};

export default SheetsExamples;