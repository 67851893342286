import React, { useRef, useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { motion, AnimatePresence } from 'framer-motion';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const StackedBarGraphComponent = ({ neighborhoods, startDate, endDate }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const containerRef = useRef(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const updateSize = () => {
            if (containerRef.current) {
                const { width, height } = containerRef.current.getBoundingClientRect();
                setDimensions({ width, height });
            }
        };

        updateSize();
        window.addEventListener('resize', updateSize);
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % neighborhoods.length);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + neighborhoods.length) % neighborhoods.length);
    };

    const generateData = (neighborhood) => {
        const currentYear = new Date().getFullYear();
        const years = Array.from({ length: currentYear - 2015 }, (_, i) => (2016 + i).toString());
        const ageGroups = ['5-25 yr', '25-45 yr', '45-65 yr', '65-85 yr', '85+ yr'];
        const colors = ['#0074D9', '#39CCCC', '#3D9970', '#85144b', '#FF4136'];

        const datasets = ageGroups.map((group, index) => ({
            label: group,
            data: years.map(() => Math.floor(Math.random() * 2000) + 500),
            backgroundColor: colors[index],
        }));

        return {
            labels: years,
            datasets: datasets
        };
    };

    const currentNeighborhood = neighborhoods[currentIndex];
    const data = generateData(currentNeighborhood);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
                beginAtZero: true,
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    title: (tooltipItems) => {
                        return `Year: ${tooltipItems[0].label}`;
                    },
                    label: (context) => {
                        const total = context.dataset.data.reduce((acc, current) => acc + current, 0);
                        const percentage = ((context.raw / total) * 100).toFixed(1);
                        return `${context.dataset.label}: ${context.formattedValue} (${percentage}%)`;
                    },
                    footer: (tooltipItems) => {
                        const total = tooltipItems.reduce((acc, item) => acc + item.parsed.y, 0);
                        return `Total Population: ${total}`;
                    },
                },
            },
        },
    };

    const arrowStyle = {
        background: 'none',
        border: 'none',
        color: '#888',
        fontSize: '16px',
        cursor: 'pointer',
        padding: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'color 0.3s',
    };

    return (
        <div ref={containerRef} style={{ position: 'relative', height: '100%', width: '100%', display: 'flex', alignItems: 'center' }}>
            <div onClick={handlePrev} style={{ ...arrowStyle, marginRight: '10px' }}>
                <FaChevronLeft />
            </div>
            <div style={{ width: '20%', padding: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <div style={{ width: '12px', height: '12px', backgroundColor: currentNeighborhood.color, marginRight: '5px' }}></div>
                    <span style={{ fontSize: '12px', fontWeight: 'bold' }}>{currentNeighborhood.name}</span>
                </div>
                <div style={{ fontSize: '10px', color: '#888', marginBottom: '5px' }}>Key</div>
                {data.datasets.map((dataset, index) => (
                    <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                        <div style={{ width: '8px', height: '8px', backgroundColor: dataset.backgroundColor, marginRight: '5px' }}></div>
                        <span style={{ fontSize: '10px' }}>{dataset.label}</span>
                    </div>
                ))}
            </div>
            <div style={{ width: '70%', height: '100%', position: 'relative' }}>
                <AnimatePresence>
                    <motion.div
                        key={currentIndex}
                        initial={{ opacity: 0, x: 300 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -300 }}
                        transition={{ duration: 0.5 }}
                        style={{ position: 'absolute', width: '100%', height: '100%' }}
                    >
                        <Bar data={data} options={options} />
                    </motion.div>
                </AnimatePresence>
            </div>
            <div onClick={handleNext} style={{ ...arrowStyle, marginLeft: '10px' }}>
                <FaChevronRight />
            </div>
        </div>
    );
};

export default StackedBarGraphComponent;