import React, { useState } from "react";
import { useTransition, animated } from "react-spring";
import "./FeaturedDataInsights.css";
import blogImage from "../assets/blogImage.svg";
import tempDataImage from "../assets/tempDataImage.png";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Link } from "react-router-dom"; // Add this import

const FeaturedDataInsights = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const insights = [
    {
      image: blogImage,
    },
    // Temporarily comment out second image for single view
    // {
    //     image: tempDataImage
    // }
  ];

  const hasMultipleImages = insights.length > 1;

  const transitions = useTransition(currentIndex, {
    from: { opacity: 0, transform: "translate3d(100%,0,0)" },
    enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
    leave: { opacity: 0, transform: "translate3d(-50%,0,0)" },
  });

  const handlePrevious = () => {
    if (!hasMultipleImages) return;
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? insights.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    if (!hasMultipleImages) return;
    setCurrentIndex((prevIndex) => (prevIndex + 1) % insights.length);
  };

  return (
    <div className="featured-data-insights">
      <div className="featured-data-insights__content">
        {hasMultipleImages && (
          <button
            className="featured-data-insights__arrow featured-data-insights__arrow--left"
            onClick={handlePrevious}
          >
            <FaChevronLeft />
          </button>
        )}
        <div
          className={`featured-data-insights__image-container ${
            !hasMultipleImages ? "single-image" : ""
          }`}
        >
          {transitions((style, index) => (
            <animated.div
              style={style}
              className="featured-data-insights__animated-container"
            >
              <img
                src={insights[index].image}
                alt={`Insight ${index + 1}`}
                className="featured-data-insights__image"
              />
            </animated.div>
          ))}
        </div>
        {hasMultipleImages && (
          <button
            className="featured-data-insights__arrow featured-data-insights__arrow--right"
            onClick={handleNext}
          >
            <FaChevronRight />
          </button>
        )}
      </div>
      <Link to="/blog/population-trends-2024">
        <button className="featured-data-insights__read-more">Read More</button>
      </Link>
    </div>
  );
};

export default FeaturedDataInsights;
