import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Account.css';
import generalIcon from '../assets/home-general-icon.svg';
import settingsIcon from '../assets/settings-icon.svg';
// import subscriptionIcon from '../assets/subscription-icon.svg';
import logoutIcon from '../assets/logout-icon.svg';
import SubscriptionPlans from './SubscriptionPlans';
import { get_current_user, updateUser, logout } from '../actions/auth';
import LoadingSpinner from './LoadingSpinner';
import dashboardIcon from "../assets/dashboardIcon.svg"
import UserDashboard from './UserDashboard';
import { signOut } from '@aws-amplify/auth';

const Account = () => {
    const [currentUser, setCurrentUser] = useState(null);
    const [selectedTab, setSelectedTab] = useState('Dashboard');
    const navigate = useNavigate();
    const location = useLocation();
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        company: '',
        website: '',
        residence: '',
    });
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [error, setError] = useState(null);

    const [firstNameError, setFirstNameError] = useState(null);
    const [lastNameError, setLastNameError] = useState(null);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                setIsLoading(true);
                const userData = await get_current_user();
                if (userData) {
                    setCurrentUser(userData);
                    setFormData({
                        firstName: userData.firstName || '',
                        lastName: userData.lastName || '',
                        email: userData.email || '',
                        phoneNumber: userData.phoneNumber || '',
                        residence: userData.residence || '',
                        website: userData.website || '',
                        company: userData.company || ''
                    });
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
                setError('Failed to load user data. You can still edit the form.');
            } finally {
                setIsLoading(false);
            }
        };

        fetchUserData();
    }, []);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const tabParam = searchParams.get('tab');
        if (tabParam) {
            setSelectedTab(tabParam);
        }
    }, [location]);

    const handleCancel = () => {
        setIsEditing(false);
        if (currentUser) {
            setFormData({
                firstName: currentUser.firstName || '',
                lastName: currentUser.lastName || '',
                email: currentUser.email || '',
                phoneNumber: currentUser.phoneNumber || '',
                residence: currentUser.residence || '',
            });
        }
        setFirstNameError(null);
        setLastNameError(null);
    };

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        if (e.target.name === 'firstName') setFirstNameError(null);
        if (e.target.name === 'lastName') setLastNameError(null);
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        let isValid = true;

        // Validate first name
        if (/\d/.test(formData.firstName)) {
            setFirstNameError('First name cannot contain numbers');
            isValid = false;
        } else if (formData.firstName.trim() === '') {
            setFirstNameError('First name is required');
            isValid = false;
        } else {
            setFirstNameError(null);
        }

        // Validate last name
        if (/\d/.test(formData.lastName)) {
            setLastNameError('Last name cannot contain numbers');
            isValid = false;
        } else if (formData.lastName.trim() === '') {
            setLastNameError('Last name is required');
            isValid = false;
        } else {
            setLastNameError(null);
        }

        if (isValid) {
            setIsSaving(true);
            try {
                const updatedUserData = {
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    email: formData.email,
                    phoneNumber: formData.phoneNumber,
                    company: formData.company,
                    website: formData.website,
                    residence: formData.residence,
                };
                const updatedUser = await updateUser(updatedUserData);
                console.log('User updated successfully:', updatedUser);
                setIsEditing(false);

                const refreshedUserData = await get_current_user();
                setCurrentUser(refreshedUserData);
                setFormData({
                    firstName: refreshedUserData.firstName,
                    lastName: refreshedUserData.lastName,
                    email: refreshedUserData.email,
                    phoneNumber: refreshedUserData.phoneNumber,
                    company: refreshedUserData.company,
                    website: refreshedUserData.website,
                    residence: refreshedUserData.residence,
                });
            } catch (error) {
                console.error('Failed to update user data:', error);
            } finally {
                setIsSaving(false);
            }
        }
    };

    const logoutUser = async () => {
        try {
            await signOut();
            navigate('/');
        } catch (error) {
            console.error('Failed to logout:', error);
        }
    };

    const renderUserInfo = () => {
        if (selectedTab === 'Settings') {
            return (
                <div className="form-container">
                    <h1 className="general-heading">
                        <img src={settingsIcon} alt="Settings" className="general-icon" />
                        Settings
                    </h1>
                    {error && <div className="error-message">{error}</div>}
                    <form onSubmit={onSubmit}>
                        <div className="form-group">
                            <label htmlFor="firstName">First Name</label>
                            <input
                                type="text"
                                id="firstName"
                                name="firstName"
                                value={formData.firstName}
                                onChange={onChange}
                                disabled={!isEditing || isSaving}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="lastName">Last Name</label>
                            <input
                                type="text"
                                id="lastName"
                                name="lastName"
                                value={formData.lastName}
                                onChange={onChange}
                                disabled={!isEditing || isSaving}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={onChange}
                                disabled={true}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="phoneNumber">Phone Number</label>
                            <input
                                type="text"
                                id="phoneNumber"
                                name="phoneNumber"
                                value={formData.phoneNumber}
                                onChange={onChange}
                                disabled={!isEditing || isSaving}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="company">Company</label>
                            <input
                                type="text"
                                id="company"
                                name="company"
                                value={formData.company}
                                onChange={onChange}
                                disabled={!isEditing || isSaving}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="website">Website</label>
                            <input
                                type="text"
                                id="website"
                                name="website"
                                value={formData.website}
                                onChange={onChange}
                                disabled={!isEditing || isSaving}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="residence">Residence</label>
                            <input
                                type="text"
                                id="residence"
                                name="residence"
                                value={formData.residence}
                                onChange={onChange}
                                disabled={!isEditing || isSaving}
                            />
                        </div>
                        {isEditing ? (
                            <>
                                <button type="button" className="cancel-button" onClick={handleCancel} disabled={isSaving}>
                                    Cancel
                                </button>
                                <button type="submit" className="edit-button" disabled={isSaving}>
                                    {isSaving ? 'Saving...' : 'Save'}
                                </button>
                            </>
                        ) : (
                            <button type="button" className="edit-button" onClick={() => setIsEditing(true)}>
                                Edit
                            </button>
                        )}
                    </form>
                </div>
            );
        } else if (selectedTab === 'Subscription') {
            return <SubscriptionPlans />;
        }
        else if (selectedTab === 'Dashboard') {
            return <UserDashboard user={currentUser} />;
        }
        return null;
    };

    if (isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <div className="account-container">
            {isSaving && <LoadingSpinner />}
            <div className="sidebar">
                <div className="profile-picture-container">
                    {currentUser && (
                        <div className="default-profile-picture">
                            {formData.firstName ? formData.firstName.charAt(0) : ''}
                        </div>
                    )}
                    <div className="user-name">
                        <p>{formData.firstName} {formData.lastName}</p>
                    </div>
                </div>
                <nav className="tabs">
                    <div
                        className={`tab ${selectedTab === 'Dashboard' ? 'active' : ''}`}
                        onClick={() => setSelectedTab('Dashboard')}
                    >
                        <img src={dashboardIcon} alt="Dashboard" />
                        Dashboard
                    </div>
                    <div
                        className={`tab ${selectedTab === 'Settings' ? 'active' : ''}`}
                        onClick={() => setSelectedTab('Settings')}
                    >
                        <img src={settingsIcon} alt="Settings" />
                        Settings
                    </div>
                    {/* <div
                        className={`tab ${selectedTab === 'Subscription' ? 'active' : ''}`}
                        onClick={() => setSelectedTab('Subscription')}
                    >
                        <img src={subscriptionIcon} alt="Subscription" />
                        Subscription
                    </div> */}
                    <div className={`tab ${selectedTab === 'Logout' ? 'active' : ''}`} onClick={logoutUser}>
                        <img src={logoutIcon} alt="Logout" />
                        Logout
                    </div>
                </nav>
            </div>
            <div className="content">{renderUserInfo()}</div>
        </div>
    );
};

export default Account;