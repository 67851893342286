import React from 'react';
import './SubscriptionPlans.css';
import subscriptionIcon from '../assets/subscription-icon.svg';
import buildingImage from '../assets/sub building 4 1.svg';

const SubscriptionPlans = () => {
    return (
        <div className="subscription-plans-wrapper">
            <h1 className="subscription-plans-heading">
                <img src={subscriptionIcon} alt="Subscription" className="subscription-plans-icon" />
                Subscriptions
            </h1>
            <div className="subscription-plans-container">
                <div className="subscription-plan-item">
                    <div className="subscription-plan-item-header">
                        <h3>Realtor</h3>
                    </div>
                    <div className="subscription-plan-item-image-container">
                        <img src={buildingImage} alt="Building" className="subscription-plan-item-image" />
                    </div>
                    <div className="subscription-plan-item-content">
                        <button className="subscription-plan-item-button">Current</button>
                        <div className="subscription-plan-item-features">
                            <h4>What's included:</h4>
                            <ul>
                                <li className="included">This</li>
                                <li className="included">That</li>
                                <li>Coupons</li>
                                <li>Access to X Content</li>
                                <li>Dashboard</li>
                                <li>Free Cotton Candy</li>
                                <li>Tech Support</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubscriptionPlans;