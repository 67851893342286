import React from 'react';
import './UserDashboardHomePage.css';
import accountPageImage from '../assets/account page.svg';

const UserDashboardHomePage = () => {
    return (
        <div className="user-dashboard-section">
            <div className="user-dashboard-content">
                <h2>Everything in One Place.</h2>
                <p>
                Access all the tools you need to succeed as a real estate professional. From building your personalized watchlist to conducting in depth neighborhood research, our dashboard simplifies the process. Customize your experience, track key metrics, and create impactful marketing materials in just a few clicks.
                </p>
            </div>
            <div className="user-dashboard-image-container">
                <img src={accountPageImage} alt="User Dashboard" className="user-dashboard-image" />
            </div>
        </div>
    );
};

export default UserDashboardHomePage;