import React, { useState, useEffect, useMemo, memo } from 'react';
import './ResearchPageCard.css';
import MapPreview from './MapPreview';
import ResearchPageGraphicBox from './ResearchPageGraphicBox';

const ResearchPageCard = memo(({
    neighborhood,
    globalSelectedDataType,
    startDate,
    endDate,
    color,
    locationData,
    rankingsData,
    isLoading,
    error,
    onDataTypeChange,
    setIsExpanded,
    isExpanded
}) => {
    const [cardDataType, setCardDataType] = useState(globalSelectedDataType);


    useEffect(() => {
        setCardDataType(globalSelectedDataType);
        setIsExpanded(true);
    }, [globalSelectedDataType]);

    const handleDataTypeChange = (newDataType) => {
        setCardDataType(newDataType);
        setIsExpanded(true);
        if (onDataTypeChange) {
            onDataTypeChange(newDataType, neighborhood);
        }
    };

    const memoizedMapPreview = useMemo(() => (
        <MapPreview location={neighborhood} />
    ), [neighborhood.id]);

    return (
        <div className="research-page-card">
            <div className="research-page-card-title">
                <h2>{neighborhood.name}</h2>
                <div className="color-indicator" style={{ backgroundColor: color }}></div>
            </div>
            <div className="research-page-card-map">
                {memoizedMapPreview}
            </div>
            {isLoading ? (
                <div className="research-page-card-loading">Loading data...</div>
            ) : error ? (
                <div className="research-page-card-error">{error}</div>
            ) : (
                <ResearchPageGraphicBox
                    selectedDataType={cardDataType}
                    onDataTypeChange={handleDataTypeChange}
                    neighborhood={{ ...neighborhood, color }}
                    locationData={locationData}
                    rankingsData={rankingsData}
                    startDate={startDate}
                    endDate={endDate}
                    isExpanded={isExpanded}
                    setIsExpanded={setIsExpanded}
                />
            )}
        </div>
    );
});

ResearchPageCard.displayName = 'ResearchPageCard';

export default ResearchPageCard;