import us_states from '../assets/us_states.json';
import ri_cities from '../assets/ri/Municipalities_1997_4186004077767655023.json';
import providence_neighborhoods from '../assets/ri/Providence Neighborhood Boundaries.json';
import { fetchLocationDataAction } from '../actions/auth';
import Fuse from 'fuse.js';

export const getLocationData = () => {
    const states = ['Rhode Island', 'Massachusetts'].map(name => ({ name, type: 'state' }));
    const cities = ri_cities.features.map(feature => ({
        name: feature.properties.NAME,
        type: 'city',
        state: 'Rhode Island'
    }));
    const neighborhoods = providence_neighborhoods.features.map(feature => ({
        name: feature.properties.NAME,
        type: 'neighborhood',
        city: 'Providence',
        state: 'Rhode Island'
    }));

    return [...states, ...cities, ...neighborhoods];
};

const fuseOptions = {
    includeScore: true,
    threshold: 0.3,
    keys: ['properties.NAME']
};

export const fetchLocationBoundary = async (location, allLocations) => {
    console.log(location);
    return new Promise((resolve) => {
        setTimeout(() => {
            let feature;

            const cleanLocationName = location.name.replace(/\s*\(City\)$/, '');

            if (location.type === 'neighborhood') {
                // Try to find the neighborhood boundary
                const neighborhoodFuse = new Fuse(providence_neighborhoods.features, fuseOptions);
                const neighborhoodResult = neighborhoodFuse.search(cleanLocationName);
                feature = neighborhoodResult.length > 0 ? neighborhoodResult[0].item : null;

                // If neighborhood not found, fall back to the correct city
                if (!feature && location.fullName) {
                    const cityName = location.fullName.split(',')[1].trim();
                    const cityFuse = new Fuse(ri_cities.features, fuseOptions);
                    const cityResult = cityFuse.search(cityName);
                    feature = cityResult.length > 0 ? cityResult[0].item : null;
                }
            } else if (location.type === 'city') {
                const cityFuse = new Fuse(ri_cities.features, fuseOptions);
                const cityResult = cityFuse.search(cleanLocationName);
                feature = cityResult.length > 0 ? cityResult[0].item : null;
            }

            // Final fallback: use Rhode Island state boundary
            if (!feature) {
                const stateFuse = new Fuse(us_states.features, fuseOptions);
                const stateResult = stateFuse.search('Rhode Island');
                feature = stateResult.length > 0 ? stateResult[0].item : null;
            }

            resolve(feature);
        }, 500);  // Simulate network delay
    });
};


export const fetchLocationDataFromAPI = async (locationType, limit, offset, query) => {
    try {
        const data = await fetchLocationDataAction(locationType, limit, offset, query);
        console.log(data);
        return data;
    } catch (error) {
        console.error("Error fetching location data:", error);
        throw error;
    }
};

export const fetchRhodeIslandLocations = async () => {
    try {
        const [neighborhoodsResponse, citiesResponse] = await Promise.all([
            fetchLocationDataFromAPI("neighborhood", 100, 0, "bd7ccc4f2052140835b9eb0ad094b7b7"),
            fetchLocationDataFromAPI("city", 100, 0, "bd7ccc4f2052140835b9eb0ad094b7b7")
        ]);

        let locations = [];

        if (neighborhoodsResponse.success && neighborhoodsResponse.data && neighborhoodsResponse.data.locations) {
            const neighborhoods = neighborhoodsResponse.data.locations.map(location => ({
                id: location.id.trim(),
                name: location.name.split(',')[0].trim(),
                fullName: location.name,
                type: 'neighborhood'
            }));
            locations = locations.concat(neighborhoods);
        }

        if (citiesResponse.success && citiesResponse.data && citiesResponse.data.locations) {
            const cities = citiesResponse.data.locations.map(location => ({
                id: location.id.trim(),
                name: location.name.split(',')[0].trim(),
                fullName: location.name,
                type: 'city'
            }));
            locations = locations.concat(cities);
        }

        locations.sort((a, b) => a.name.localeCompare(b.name));
        return locations;
    } catch (error) {
        console.error('Error fetching Rhode Island location data:', error);
        throw error;
    }
};