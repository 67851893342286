import React, { useContext, useState } from 'react';
import { MarketModeContext } from '../../contexts/MarketModeContext';
import { TextField, Collapse } from '@mui/material';
import { Search, ExpandMore, ExpandLess } from '@mui/icons-material';
import './SavedPresetsTab.css';
import trashIcon from '../../assets/trashIcon.svg'
import { FaSearch } from 'react-icons/fa';

const SavedPresetsTab = ({ ContentBox }) => {
    const { savedPresets, loadPreset, deletePreset } = useContext(MarketModeContext);
    const [searchTerm, setSearchTerm] = useState('');
    const [expandedSection, setExpandedSection] = useState('Recent');

    const sections = ['Recent'];

    const handleLoadPreset = (preset) => {
        loadPreset(preset);
        console.log(`Loaded preset: ${preset.name}`);
    };

    const handleExpand = (section) => {
        setExpandedSection(expandedSection === section ? null : section);
    };

    const renderDocumentList = (documents) => (
        <ul className="saved-presets-list">
            {documents.map((preset, index) => (
                <li key={index} className="saved-presets-list-item">
                    <span
                        className="saved-presets-list-item-text"
                        onClick={() => handleLoadPreset(preset)}
                    >
                        {preset.name}
                    </span>
                    <img
                        src={trashIcon}
                        alt="Delete"
                        className="saved-presets-delete-icon"
                        onClick={() => deletePreset(preset.name)}
                    />
                </li>
            ))}
        </ul>
    );

    const filteredPresets = savedPresets.filter(preset =>
        preset.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <ContentBox
            title="Saved Market Sheets"
            key="SavedMarketSheets"
            subtype="savedPresetsTab"
            contentType="SavedMarketSheets"
            flexibleHeight
            customHeaderStyle={{ display: 'none' }}
            hideHeaderButtons={true}
        >
            <div className="saved-presets-tab">
                <div className="saved-presets-search">
                    <button type="submit" className="saved-presets-search-icon">
                        <FaSearch />
                    </button>
                    <input
                        type="search"
                        placeholder="Search: saved file name"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="saved-presets-search-input"
                    />
                </div>
                <div className="saved-presets-sections">
                    {sections.map((section) => (
                        <div key={section} className="saved-presets-section">
                            <div
                                className="saved-presets-section-header"
                                onClick={() => handleExpand(section)}
                            >
                                <span>{section}</span>
                                {expandedSection === section ? <ExpandLess /> : <ExpandMore />}
                            </div>
                            <Collapse in={expandedSection === section}>
                                {renderDocumentList(filteredPresets)}
                            </Collapse>
                        </div>
                    ))}
                </div>
            </div>
        </ContentBox>
    );
};

export default SavedPresetsTab;