import React, { memo } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// Helper function to format currency
const formatCurrency = (amount) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount);
};

export const IncomeSummary = memo(({ locationData }) => {
  if (!locationData?.economics) return null;

  const { economics } = locationData;

  return (
    <div>
      <div className="summary-item">
        <span className="summary-label">Median Household Income:</span>
        <span className="summary-value">{formatCurrency(economics.medianHouseholdIncome)}</span>
      </div>
      <div className="summary-item">
        <span className="summary-label">Average Household Income:</span>
        <span className="summary-value">{formatCurrency(economics.avgHouseholdIncome)}</span>
      </div>
      <div className="summary-item">
        <span className="summary-label">Per Capita Income:</span>
        <span className="summary-value">{formatCurrency(economics.householdIncomePerCapita)}</span>
      </div>
      <div className="summary-item">
        <span className="summary-label">Population in Poverty:</span>
        <span className="summary-value">{economics.populationInPovertyPct.toFixed(1)}%</span>
      </div>
    </div>
  );
});

export const CostOfLiving = memo(({ locationData }) => {
  if (!locationData?.economics) return null;

  const { economics } = locationData;

  const data = {
    labels: ['Overall CPI', 'Housing', 'Food & Beverages'],
    datasets: [{
      label: 'Cost of Living Indices',
      data: [
        economics.cpi,
        economics.cpiHousing,
        economics.cpiFoodAndBeverages
      ],
      backgroundColor: [
        'rgba(75, 192, 192, 0.6)',
        'rgba(153, 102, 255, 0.6)',
        'rgba(255, 159, 64, 0.6)'
      ],
    }]
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: (context) => `Index: ${context.raw.toFixed(1)}`
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Index Value'
        }
      }
    }
  };

  return <Bar data={data} options={options} />;
});

export const HousingCosts = memo(({ locationData }) => {
  if (!locationData?.economics) return null;

  const { economics } = locationData;

  const data = {
    labels: ['Median Home Value', 'Median Monthly Rent'],
    datasets: [{
      label: 'Housing Costs',
      data: [
        parseInt(economics.housingOwnerHouseholdsMedianValue),
        parseInt(economics.housingMedianRent) * 12 // Annualized rent
      ],
      backgroundColor: [
        'rgba(54, 162, 235, 0.6)',
        'rgba(255, 99, 132, 0.6)'
      ],
    }]
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: (context) => `${context.label}: ${formatCurrency(context.raw)}`
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Amount (USD)'
        },
        ticks: {
          callback: (value) => formatCurrency(value)
        }
      }
    }
  };

  return <Bar data={data} options={options} />;
});

export const IncomeByAge = ({ data }) => (
  <div className="bg-gray-100 p-4 rounded-lg">
    <h3 className="text-xl font-semibold mb-2">Income by Age Bracket</h3>
    <div className="h-64">
      <Bar
        data={data}
        options={{
          maintainAspectRatio: false,
          responsive: true,
          scales: {
            x: { title: { display: true, text: 'Age Groups' } },
            y: { title: { display: true, text: 'Income (USD)' } },
          },
        }}
      />
    </div>
  </div>
);

export const IncomeTypes = ({ data }) => (
  <div className="bg-gray-100 p-4 rounded-lg col-span-1 md:col-span-2 lg:col-span-1">
    <h3 className="text-xl font-semibold mb-2">Income Types Comparison</h3>
    <div className="h-64">
      <Bar
        data={data}
        options={{
          maintainAspectRatio: false,
          responsive: true,
          scales: {
            x: { title: { display: true, text: 'Income Types' } },
            y: { title: { display: true, text: 'Income (USD)' } },
          },
        }}
      />
    </div>
  </div>
);

// Re-adding the previously exported components
export const MedianIncomeByAge = memo(() => {
  const data = {
    labels: ['18-24', '25-34', '35-44', '45-54', '55-64', '65+'],
    datasets: [
      {
        label: 'Median Income',
        data: [30000, 45000, 60000, 70000, 65000, 40000],
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Median Income by Age Bracket',
      },
    },
  };

  return <Bar data={data} options={options} />;
});

export const PerCapitaIncome = memo(() => (
  <div className="summary-item">
    <span className="summary-label">Per Capita Income:</span>
    <span className="summary-value">$34,500</span>
  </div>
));

export const HouseholdIncome = memo(() => (
  <div>
    <div className="summary-item">
      <span className="summary-label">Median Household Income:</span>
      <span className="summary-value">$65,000</span>
    </div>
    <div className="summary-item">
      <span className="summary-label">Average Household Income:</span>
      <span className="summary-value">$72,500</span>
    </div>
  </div>
));

export const MedianDisposableIncome = memo(() => (
  <div className="summary-item">
    <span className="summary-label">Median Disposable Income:</span>
    <span className="summary-value">$48,000</span>
  </div>
));

const IncomeSummaryResearch = ({ data }) => (
  <div className="bg-gray-100 p-4 rounded-lg">
    <ul className="space-y-2">
      {Object.entries(data).map(([key, value]) => (
        <li key={key} className="flex justify-between income-summary-item">
          <span className="font-medium">{key}:</span>
          <span className="income-summary-value">{value}</span>
        </li>
      ))}
    </ul>
  </div>
);

const CostOfLivingResearch = ({ data }) => (
  <div className="bg-gray-100 p-4 rounded-lg">
    <h3 className="text-xl font-semibold mb-2">Cost of Living Indices</h3>
    <div className="h-64">
      <Bar
        data={data}
        options={{
          maintainAspectRatio: false,
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: 'Index Value'
              }
            }
          }
        }}
      />
    </div>
  </div>
);

const HousingCostsResearch = ({ data }) => (
  <div className="bg-gray-100 p-4 rounded-lg">
    <h3 className="text-xl font-semibold mb-2">Housing Costs</h3>
    <div className="h-64">
      <Bar
        data={data}
        options={{
          maintainAspectRatio: false,
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: 'Cost (USD)'
              },
              ticks: {
                callback: (value) => `$${value.toLocaleString()}`
              }
            }
          }
        }}
      />
    </div>
  </div>
);

export const IncomeOverview = ({ locationData }) => {
  if (!locationData?.economics) {
    return <div>No economic data available</div>;
  }

  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <style jsx global>{`
        :root {
            --summary-title-size: 1.5vh;
            --summary-item-size: 1.5vh;
            --summary-value-size: 1.5vh;
        }
        
        @media (min-width: 768px) {
          :root {
            --summary-title-size: 1.5vh;
            --summary-item-size: 1.5vh;
            --summary-value-size: 1.5vh;
          }
        }
      `}</style>

      <div className="grid grid-cols-1 gap-4">
        {/* Quick Stats */}
        <div className="bg-gray-100 p-4 rounded-lg">
          <h3 className="text-xl font-semibold mb-2">Income Statistics</h3>
          <IncomeQuickStats locationData={locationData} />
        </div>

        {/* Household Income Distribution */}
        <div className="bg-gray-100 p-4 rounded-lg">
          <h3 className="text-xl font-semibold mb-2">Household Income Distribution</h3>
          <IncomeHsldPct locationData={locationData} />
        </div>
      </div>
    </div>
  );
};

export const IncomeQuickStats = memo(({ locationData }) => {
  if (!locationData?.economics) {
    return <div>No economic data available</div>;
  }

  const { economics, transportation } = locationData;

  // Helper function for formatting currency values
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  };

  // Helper function for formatting time
  const formatTime = (minutes) => {
    if (minutes < 60) {
      return `${minutes} min`;
    }
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return remainingMinutes > 0 ? `${hours}h ${remainingMinutes}m` : `${hours}h`;
  };

  return (
    <div>
      <div className="summary-item">
        <span className="summary-label">Avg. Commute Time:</span>
        <span className="summary-value">{formatTime(transportation.medianTravelTimeToWorkMin)}</span>
      </div>

      <div className="summary-item">
        <span className="summary-label">Median Household:</span>
        <span className="summary-value">{formatCurrency(economics.medianHouseholdIncome)}</span>
      </div>

      <div className="summary-item">
        <span className="summary-label">Mean Household:</span>
        <span className="summary-value">{formatCurrency(economics.avgHouseholdIncome)}</span>
      </div>

      <div className="summary-item">
        <span className="summary-label">Per Capita:</span>
        <span className="summary-value">{formatCurrency(economics.householdIncomePerCapita)}</span>
      </div>

      <div className="summary-item">
        <span className="summary-label">High Income Avg:</span>
        <span className="summary-value">{formatCurrency(economics.householdIncomeHighIncomeAvg)}</span>
      </div>
    </div>
  );
});

export const IncomeHsldPct = memo(({ locationData }) => {
  if (!locationData?.economics) return null;

  const { economics } = locationData;

  // Helper function to format currency ranges
  const formatRange = (min, max) => {
    const formatNum = (num) => {
      if (num >= 1000) {
        return `${(num / 1000)}K`;
      }
      return num;
    };

    if (!max) return `$${formatNum(min)}k+`;
    return `$${formatNum(min)}-${formatNum(max)}k`;
  };

  // Define the income brackets and their labels
  const incomeBrackets = [
    { label: formatRange(0, 15), value: economics.householdsIncomeLessThan15000Pct },
    { label: formatRange(15, 25), value: economics.householdsIncome15000To24999Pct },
    { label: formatRange(25, 35), value: economics.householdsIncome25000To34999Pct },
    { label: formatRange(35, 50), value: economics.householdsIncome35000To49999Pct },
    { label: formatRange(50, 75), value: economics.householdsIncome50000To74999Pct },
    { label: formatRange(75, 100), value: economics.householdsIncome75000To99999Pct },
    { label: formatRange(100, 125), value: economics.householdsIncome100000To124999Pct },
    { label: formatRange(125, 150), value: economics.householdsIncome125000To149999Pct },
    { label: formatRange(150, 200), value: economics.householdsIncome150000To199999Pct },
    { label: formatRange(200), value: economics.householdsIncome200000AndOverPct }
  ];

  const data = {
    labels: incomeBrackets.map(bracket => bracket.label),
    datasets: [{
      label: 'Household Income Distribution',
      data: incomeBrackets.map(bracket => bracket.value),
      backgroundColor: 'rgba(75, 192, 192, 0.6)',
      borderColor: 'rgba(75, 192, 192, 1)',
      borderWidth: 1
    }]
  };

  return (
    <Bar
      data={data}
      options={{
        responsive: true,
        scales: {
          x: {
            title: {
              display: true,
              text: 'Income Brackets',
              font: {
                size: 12
              }
            },
            ticks: {
              font: {
                size: 10
              },
              maxRotation: 45,
              minRotation: 45
            }
          },
          y: {
            title: {
              display: true,
              text: '% of Households',
              font: {
                size: 12
              }
            },
            beginAtZero: true,
            ticks: {
              callback: (value) => `${value}%`,
              font: {
                size: 10
              }
            }
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: (context) => `${context.raw.toFixed(1)}% of households`
            }
          },
          legend: {
            display: false
          },
          datalabels: {
            display: false, // Explicitly disable data labels
          },
        },
        animation: false,
        maintainAspectRatio: true,
        aspectRatio: 1.5
      }}
    />
  );
});
// SVG-based bar chart component for income data
export const MarketModeIncomeHsldPct = memo(({ locationData }) => {
  if (!locationData?.economics) return null;

  const { economics } = locationData;

  // Helper function to format currency ranges
  const formatRange = (min, max) => {
    const formatNum = (num) => {
      if (num >= 1000) {
        return `${(num / 1000)}K`;
      }
      return num;
    };

    if (!max) return `$${formatNum(min)}k+`;
    return `$${formatNum(min)}-${formatNum(max)}k`;
  };

  // Define the income brackets and their labels
  const incomeBrackets = [
    { label: formatRange(0, 15), value: economics.householdsIncomeLessThan15000Pct },
    { label: formatRange(15, 25), value: economics.householdsIncome15000To24999Pct },
    { label: formatRange(25, 35), value: economics.householdsIncome25000To34999Pct },
    { label: formatRange(35, 50), value: economics.householdsIncome35000To49999Pct },
    { label: formatRange(50, 75), value: economics.householdsIncome50000To74999Pct },
    { label: formatRange(75, 100), value: economics.householdsIncome75000To99999Pct },
    { label: formatRange(100, 125), value: economics.householdsIncome100000To124999Pct },
    { label: formatRange(125, 150), value: economics.householdsIncome125000To149999Pct },
    { label: formatRange(150, 200), value: economics.householdsIncome150000To199999Pct },
    { label: formatRange(200), value: economics.householdsIncome200000AndOverPct }
  ];

  const data = {
    labels: incomeBrackets.map(bracket => bracket.label),
    datasets: [{
      data: incomeBrackets.map(bracket => bracket.value),
      backgroundColor: 'rgba(75, 192, 192, 0.6)',
    }]
  };

  return <MarketModeBarChart data={data} />;
});

const MarketModeBarChart = memo(({ data }) => {
  const chartWidth = 400;
  const chartHeight = 200;
  const margins = { top: 20, right: 20, bottom: 50, left: 40 };
  const width = chartWidth - margins.left - margins.right;
  const height = chartHeight - margins.top - margins.bottom;

  const values = data.datasets[0].data;
  const maxValue = Math.max(...values);
  const barWidth = width / values.length * 0.8;
  const barSpacing = width / values.length * 0.2;

  return (
    <div className="flex flex-col items-center w-full">
      <div className="mt-6 flex justify-center" style={{ marginTop: '12px', display: 'flex', justifyContent: 'center' }}>
        <svg viewBox={`0 0 ${chartWidth} ${chartHeight}`}>
          <g transform={`translate(${margins.left}, ${margins.top})`}>
            {/* Y-axis */}
            <line x1="0" y1="0" x2="0" y2={height} stroke="#333" />
            {[0, 25, 50, 75, 100].map((tick, i) => (
              <g key={tick} transform={`translate(-10, ${height - (height * tick / 100)})`}>
                <line x1="0" x2="10" y1="0" y2="0" stroke="#333" />
                <text x="-5" y="5" textAnchor="end" fontSize="9">{tick}%</text>
              </g>
            ))}

            {/* Bars */}
            {values.map((value, i) => (
              <g key={i} transform={`translate(${i * (barWidth + barSpacing)}, 0)`}>
                <rect
                  x="0"
                  y={height - (height * value / maxValue)}
                  width={barWidth}
                  height={(height * value / maxValue)}
                  fill={data.datasets[0].backgroundColor}
                />
                <text
                  x={barWidth / 2}
                  y={height + 20}
                  textAnchor="middle"
                  transform={`rotate(45, ${barWidth / 2}, ${height + 20})`}
                  fontSize="9"
                >
                  {data.labels[i]}
                </text>
              </g>
            ))}
          </g>
        </svg>
      </div>
    </div>
  );
});


const IncomeTab = ({ ContentBox, locationData }) => {
  console.log('IncomeTab rendering with data:', locationData);

  return (
    <>
      <ContentBox
        title="Quick Income Statistics"
        key="IncomeQuickStats"
        subtype="income"
        contentType="IncomeQuickStats"
      >
        <IncomeQuickStats locationData={locationData} />
      </ContentBox>
      <ContentBox
        title="Household Income Distribution"
        key="IncomeHsldPct"
        subtype="income"
        contentType="IncomeHsldPct"
      >
        <IncomeHsldPct locationData={locationData} />
      </ContentBox>
    </>
  );
};

export default IncomeTab;