// TabularNeighborhoodComparisonTab.js
import React, { memo } from 'react';
import './TabularNeighborhoodComparisonTab.css';

const mockData = {
    'Nghb. 1': { appreciation: '5.2%', crimeRating: 'B', testScores: 85, medianIncome: '$65K' },
    'Nghb. 2': { appreciation: '3.8%', crimeRating: 'A-', testScores: 92, medianIncome: '$85K' },
    'Nghb. 3': { appreciation: '4.5%', crimeRating: 'B+', testScores: 88, medianIncome: '$72K' },
    'Nghb. 4': { appreciation: '6.1%', crimeRating: 'A', testScores: 95, medianIncome: '$95K' },
};

const dataPoints = [
    { id: 'appreciation', label: 'Appreciation' },
    { id: 'crimeRating', label: 'Crime rating' },
    { id: 'testScores', label: 'Test Scores' },
    { id: 'medianIncome', label: 'Median Income' },
];

export const NeighborhoodComparisonTable = memo(() => (
    <table className="comparison-table">
        <thead>
            <tr>
                <th></th>
                {Object.keys(mockData).map(neighborhood => (
                    <th key={neighborhood}>{neighborhood}</th>
                ))}
            </tr>
        </thead>
        <tbody>
            {dataPoints.map(dataPoint => (
                <tr key={dataPoint.id}>
                    <td>{dataPoint.label}</td>
                    {Object.keys(mockData).map(neighborhood => (
                        <td key={`${neighborhood}-${dataPoint.id}`}>
                            {mockData[neighborhood][dataPoint.id]}
                        </td>
                    ))}
                </tr>
            ))}
        </tbody>
    </table>
));

const TabularNeighborhoodComparisonTab = ({ ContentBox }) => {
    return (
        <ContentBox title="Neighborhood Comparison" key="NeighborhoodComparison" subtype="tabularNeighborhoodComparison" contentType="NeighborhoodComparison">
            <NeighborhoodComparisonTable />
        </ContentBox>
    );
};

export default TabularNeighborhoodComparisonTab;