import React from 'react';
import './MarketingSection.css';
import roiImage from '../assets/coin icon.svg';
import coverageImage from '../assets/nation wide icon.svg';
import dataImage from '../assets/graph icon.svg';

const MarketingSection = () => {
    return (
        <div className="marketing-section">
            <div className="marketing-content">
                <h2>Why Choose RealAnalytica</h2>
                <p>
                    With a fully customizable dashboard, you can have
                    access to crucial data to elevate your Real Estate
                    marketing.
                </p>
            </div>
            <div className="marketing-points">
                <div className="marketing-point">
                    <img src={roiImage} alt="100% ROI" />
                    <h3>100% ROI</h3>
                    <p>Harness hyper-local insights and customizable tools to position yourself as the go to expert in any unique or evolving market, delivering data driven strategies that set you apart</p>
                </div>
                <div className="marketing-point">
                    <img src={coverageImage} alt="Nation Coverage" />
                    <h3>Nation Coverage (Soon)</h3>
                    <p> Access reliable, comprehensive data for neighborhoods across the U.S., ensuring you stay informed no matter where your clients are looking.</p>
                </div>
                <div className="marketing-point">
                    <img src={dataImage} alt="Accurate Data" />
                    <h3>Accurate Data</h3>
                    <p>Get accurate, segmented analytics that help you identify opportunities and market trends with confidence, tailored to your client’s needs.</p>
                </div>
            </div>
        </div>
    );
};

export default MarketingSection;