import React, { useEffect, useState, useRef, useMemo } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./AutoMarketPage.css";
import plusIcon from "../assets/Plus_Black.svg";
import minusIcon from "../assets/Minus_Black.svg";
import arrowIcon from "../assets/Arrow_Right_Black.png";
import Fuse from "fuse.js";
import DataCategoriesSection from "./DataCategoriesSection";
import FirstOnePagerTemplate from "./FirstOnePagerTemplate";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { fetchLocationStatistics, fetchMultipleRankings } from '../actions/auth';

// Print configuration constants
const PRINT_CONFIG = {
  PAGE_WIDTH: 816,
  PAGE_HEIGHT: 1056,
  SCALE: 2,  // Keep this at 2 for the actual PDF/PNG generation
  MARGIN: {
    top: 0,
    right: 0,
    bottom: 40,
    left: 0,
  },
};

const PrintPreview = ({ isOpen, onClose, selectedCategories, selectedNeighborhoods, dateRanges }) => {
  const [fileName, setFileName] = useState("market_sheet");
  const contentRef = useRef(null);
  const previewRef = useRef(null);
  const templateRef = useRef(null);

  // Add useEffect for logging dimensions
  useEffect(() => {
    if (!templateRef.current) return;

    // Get all relevant elements
    const scalerElement = templateRef.current;
    const scaledContent = scalerElement.closest('.print-preview-scaled-content');
    const canvas = scalerElement.closest('.print-preview-canvas');
    const contentInline = scalerElement.closest('.print-preview-content-inline');
    const previewInline = scalerElement.closest('.print-preview-inline');

    // Create helper function to get full dimensions
    const getDimensions = (element) => {
      if (!element) return null;
      const rect = element.getBoundingClientRect();
      const computedStyle = window.getComputedStyle(element);
      return {
        width: rect.width,
        height: rect.height,
        padding: {
          top: computedStyle.paddingTop,
          right: computedStyle.paddingRight,
          bottom: computedStyle.paddingBottom,
          left: computedStyle.paddingLeft
        },
        margin: {
          top: computedStyle.marginTop,
          right: computedStyle.marginRight,
          bottom: computedStyle.marginBottom,
          left: computedStyle.marginLeft
        }
      };
    };

    // Log dimensions of each element
    console.group('Print Preview Dimensions');
    console.log('Scaler:', getDimensions(scalerElement));
    console.log('Scaled Content:', getDimensions(scaledContent));
    console.log('Canvas:', getDimensions(canvas));
    console.log('Content Inline:', getDimensions(contentInline));
    console.log('Preview Inline:', getDimensions(previewInline));
    console.groupEnd();

    // Add resize observer to track size changes
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        console.log('Element resized:', entry.target.className, getDimensions(entry.target));
      }
    });

    // Observe all elements
    [scalerElement, scaledContent, canvas, contentInline, previewInline].forEach(element => {
      if (element) resizeObserver.observe(element);
    });

    return () => resizeObserver.disconnect();
  }, []);

  const prepareForPrint = async (element) => {
    await new Promise(resolve => setTimeout(resolve, 500));
    const canvases = element.querySelectorAll('canvas');
    canvases.forEach(canvas => {
      const parent = canvas.parentElement;
      if (parent) {
        const width = parent.offsetWidth;
        canvas.style.width = '100%';
        canvas.style.height = 'auto';
        const ctx = canvas.getContext('2d');
        if (ctx) ctx.restore();
      }
    });

    const charts = element.querySelectorAll('.recharts-wrapper');
    charts.forEach(chart => {
      const width = chart.parentElement?.offsetWidth || chart.offsetWidth;
      const height = Math.min(250, width * 0.75);
      chart.style.width = `${width}px`;
      chart.style.height = `${height}px`;
    });
  };

  const handleSave = async (format) => {
    if (!templateRef.current) return;
  
    // Create a clone of the template for capturing
    const captureElement = templateRef.current.cloneNode(true);
    captureElement.style.position = 'absolute';
    captureElement.style.left = '-9999px';
    captureElement.style.transform = 'none';
    document.body.appendChild(captureElement);
  
    try {
      await prepareForPrint(captureElement);
  
      const canvas = await html2canvas(captureElement, {
        scale: PRINT_CONFIG.SCALE,
        useCORS: true,
        allowTaint: true,
        logging: false,
        width: PRINT_CONFIG.PAGE_WIDTH,
        height: PRINT_CONFIG.PAGE_HEIGHT,
        backgroundColor: "#FFFFFF",
      });
  
      if (format === "pdf") {
        const pdf = new jsPDF({
          unit: 'px',
          format: 'letter',
          orientation: 'portrait',
        });
  
        const imgData = canvas.toDataURL('image/jpeg', 1.0);
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const ratio = Math.min(pageWidth / canvas.width, pageHeight / canvas.height);
        const centerX = (pageWidth - canvas.width * ratio) / 2;
  
        pdf.addImage(imgData, 'JPEG', centerX, 0, canvas.width * ratio, canvas.height * ratio);
        pdf.save(`market_sheet.pdf`);
      } else {
        const mimeType = format === "jpeg" ? "image/jpeg" : "image/png";
        const link = document.createElement('a');
        link.download = `market_sheet.${format}`;
        link.href = canvas.toDataURL(mimeType, 1.0);
        
        // Handle mobile devices
        if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
          const blob = await (await fetch(link.href)).blob();
          const file = new File([blob], link.download, { type: mimeType });
          
          if (navigator.share) {
            await navigator.share({
              files: [file],
              title: 'Market Sheet',
            });
          } else {
            link.click();
          }
        } else {
          link.click();
        }
      }
    } finally {
      document.body.removeChild(captureElement);
    }
  };

  if (!isOpen) return null;

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="print-preview-inline"
    >
      <div className="print-preview-content-inline">
        <div className="print-preview-canvas">
          <div ref={previewRef} className="print-preview-scaled-content">
            <div ref={templateRef} className="print-preview-scaler">
              <FirstOnePagerTemplate
                selectedCategories={selectedCategories}
                selectedNeighborhoods={selectedNeighborhoods}
                dateRanges={dateRanges}
              />
            </div>
          </div>
        </div>
        <div className="print-preview-actions-inline">
          <div className="print-preview-buttons">
            <button onClick={() => handleSave("pdf")} className="preview-action-button">
              Save PDF
            </button>
            <button onClick={() => handleSave("png")} className="preview-action-button">
              Save PNG
            </button>
            <button onClick={() => handleSave("jpeg")} className="preview-action-button">
              Save JPG
            </button>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const AutoMarketPage = ({
  searchQuery1,
  setSearchQuery1,
  searchQuery2,
  setSearchQuery2,
  selectedLocation1,
  setSelectedLocation1,
  selectedLocation2,
  setSelectedLocation2,
  showSecondSearchBox,
  setShowSecondSearchBox,
  selectedCategories,
  setSelectedCategories,
  selectedDateRanges,
  setSelectedDateRanges,
  isGenerateButtonEnabled,
  setIsGenerateButtonEnabled,
  showDataCategories,
  setShowDataCategories,
  isDataCategoriesExpanded,
  setIsDataCategoriesExpanded,
  showMinusButton,
  setShowMinusButton,
  memoizedMapPreview1,
  memoizedMapPreview2,
  allLocations
}) => {
  // State declarations
  const [searchResults1, setSearchResults1] = useState([]);
  const [searchResults2, setSearchResults2] = useState([]);
  const [showSearchResults1, setShowSearchResults1] = useState(false);
  const [showSearchResults2, setShowSearchResults2] = useState(false);
  const [showPrintPreview, setShowPrintPreview] = useState(false);
  const [locationData, setLocationData] = useState({});
  const [rankingsData, setRankingsData] = useState({});
  const [dataLoadingStates, setDataLoadingStates] = useState({});
  const [isLoadingLocations, setIsLoadingLocations] = useState(true);
  const [locationError, setLocationError] = useState(null);

  // Effects
  useEffect(() => {
    if (allLocations.length > 0) {
      setIsLoadingLocations(false);
    } else {
      setLocationError("Unable to load locations");
    }
  }, [allLocations]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (selectedLocation1) {
      fetchLocationData(selectedLocation1);
    }
    if (selectedLocation2) {
      fetchLocationData(selectedLocation2);
    }
    setIsDataCategoriesExpanded(true);
  }, [selectedLocation1, selectedLocation2]);

  useEffect(() => {
    const currentIds = [selectedLocation1?.id, selectedLocation2?.id].filter(Boolean);

    setLocationData(prev => {
      const newData = { ...prev };
      Object.keys(newData).forEach(id => {
        if (!currentIds.includes(id)) {
          delete newData[id];
        }
      });
      return newData;
    });

    setDataLoadingStates(prev => {
      const newStates = { ...prev };
      Object.keys(newStates).forEach(id => {
        if (!currentIds.includes(id)) {
          delete newStates[id];
        }
      });
      return newStates;
    });
  }, [selectedLocation1, selectedLocation2]);

  // Helper functions and handlers
  const fetchLocationData = async (location) => {
    if (!location || locationData[location.id]) return;

    setDataLoadingStates(prev => ({
      ...prev,
      [location.id]: true
    }));

    try {
      const data = await fetchLocationStatistics(location.id);
      const rankings = await fetchMultipleRankings(
        location.id,
        data.geoType,
        data.geoType === 'NEIGHBORHOOD' ? data.cityId?.trim() : data.stateId?.trim()
      );

      setLocationData(prev => ({
        ...prev,
        [location.id]: data
      }));

      setRankingsData(prev => ({
        ...prev,
        [location.id]: rankings
      }));
    } catch (err) {
      console.error(`Error fetching data for ${location.name}:`, err);
    } finally {
      setDataLoadingStates(prev => ({
        ...prev,
        [location.id]: false
      }));
    }
  };

  const handleSearch = (
    query,
    setSearchQuery,
    setSearchResults,
    setShowSearchResults
  ) => {
    setSearchQuery(query);

    if (query.trim() !== "") {
      const fuse = new Fuse(allLocations, {
        keys: ["name", "fullName"],
        includeScore: true,
        threshold: 0.5,
      });

      const results = fuse.search(query);
      const topFourResults = results.slice(0, 4).map((result) => result.item);
      setSearchResults(topFourResults);
      setShowSearchResults(true);
    } else {
      setSearchResults([]);
      setShowSearchResults(false);
    }
  };

  const handleSearchResultClick = (
    result,
    setSearchQuery,
    setShowSearchResults,
    setSelectedLocation
  ) => {
    setSearchQuery(formatSearchResult(result));
    setShowSearchResults(false);
    setSelectedLocation(result);
    setShowDataCategories(true);
    setIsGenerateButtonEnabled(false);
  };

  const formatSearchResult = (result) => {
    if (result.type === "neighborhood") {
      return `${result.name}, Providence, RI`;
    } else if (result.type === "city") {
      return `${result.name}, RI`;
    }
    return result.fullName || result.name;
  };

  const handleClickOutside = (e) => {
    if (e.target.closest(".search-results") === null) {
      setShowSearchResults1(false);
      setShowSearchResults2(false);
    }
  };

  const handleAddCompareArea = () => {
    setShowSecondSearchBox(true);
    setShowMinusButton(true);
  };

  const handleRemoveCompareArea = () => {
    setShowSecondSearchBox(false);
    setShowMinusButton(false);
    setSearchQuery2("");
    if (selectedLocation2?.id) {
      setLocationData(prev => {
        const newData = { ...prev };
        delete newData[selectedLocation2.id];
        return newData;
      });
    }
    setSelectedLocation2(null);
  };

  const handleGenerateMarketSheet = () => {
    const locations = [selectedLocation1, selectedLocation2].filter(Boolean);
    const isAnyLocationLoading = locations.some(loc => dataLoadingStates[loc.id]);

    if (isAnyLocationLoading) {
      console.log("Still loading location data...");
      return;
    }

    setShowPrintPreview(true);
  };

  const formattedNeighborhoods = useMemo(() => {
    const neighborhoodColors = ['#1352E2', '#A3DBE1'];
    return [selectedLocation1, selectedLocation2]
      .filter(Boolean)
      .map((location, index) => ({
        ...location,
        color: neighborhoodColors[index],
        data: locationData[location.id]
      }));
  }, [selectedLocation1, selectedLocation2, locationData]);

  return (
    <div className="auto-market-page">
      <main className="auto-market-main">
        <motion.h1 layout className="auto-market-title">Welcome to AI Mode</motion.h1>

        <motion.div layout className="auto-market-info-boxes">
          <div className="auto-market-info-box">
            <p>
              With this unique tool you are able to create custom market sheets
              that presents detailed real estate data about any neighborhood in
              the United States.
            </p>
          </div>
          <div className="auto-market-info-box">
            <p>
              These market sheets can display data such as housing valuation,
              local population, average income statistics, school districts, and
              crime just to list a few!
            </p>
          </div>
        </motion.div>
        <motion.p layout className="auto-market-instruction">
          Start by searching a State, City, Neighborhood or Zip Code that you
          would like to explore.
        </motion.p>

        <motion.div layout className="auto-market-search-boxes">
          <motion.div layout className="auto-market-search-box">
            <input
              type="text"
              placeholder={
                isLoadingLocations
                  ? "Fetching data, please wait a moment..."
                  : allLocations.length === 0
                    ? locationError
                    : "Search City, Neighborhood, or Zip Code"
              }
              value={searchQuery1}
              onChange={(e) =>
                handleSearch(
                  e.target.value,
                  setSearchQuery1,
                  setSearchResults1,
                  setShowSearchResults1
                )
              }
              disabled={isLoadingLocations || allLocations.length === 0}
            />
            <AnimatePresence>
              {!showMinusButton && (
                <motion.button
                  className="auto-market-plus-button"
                  onClick={handleAddCompareArea}
                  initial={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0 }}
                >
                  <img src={plusIcon} alt="Add" />
                </motion.button>
              )}
            </AnimatePresence>
            <button className="auto-market-arrow-button">
              <img src={arrowIcon} alt="Search" />
            </button>
            <AnimatePresence>
              {showSearchResults1 && (
                <motion.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  className="auto-market-search-results"
                >
                  <ul>
                    {searchResults1.map((result, index) => (
                      <li
                        key={index}
                        onClick={() =>
                          handleSearchResultClick(
                            result,
                            setSearchQuery1,
                            setShowSearchResults1,
                            setSelectedLocation1
                          )
                        }
                      >
                        {formatSearchResult(result)}
                      </li>
                    ))}
                  </ul>
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>

          <AnimatePresence>
            {showSecondSearchBox && (
              <motion.div
                layout
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                className="auto-market-search-box second-search-box"
              >
                <input
                  type="text"
                  placeholder="Search City, Neighborhood, or Zip Code you would like to compare"
                  value={searchQuery2}
                  onChange={(e) =>
                    handleSearch(
                      e.target.value,
                      setSearchQuery2,
                      setSearchResults2,
                      setShowSearchResults2
                    )
                  }
                />
                <motion.button
                  className="minus-button-auto-market"
                  onClick={handleRemoveCompareArea}
                  initial={{ opacity: 0, scale: 0 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0 }}
                >
                  <img src={minusIcon} alt="Remove" />
                </motion.button>
                <button className="auto-market-arrow-button">
                  <img src={arrowIcon} alt="Search" />
                </button>
                <AnimatePresence>
                  {showSearchResults2 && (
                    <motion.div
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                      className="auto-market-search-results"
                    >
                      <ul>
                        {searchResults2.map((result, index) => (
                          <li
                            key={index}
                            onClick={() =>
                              handleSearchResultClick(
                                result,
                                setSearchQuery2,
                                setShowSearchResults2,
                                setSelectedLocation2
                              )
                            }
                          >
                            {formatSearchResult(result)}
                          </li>
                        ))}
                      </ul>
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>

        <motion.div layout className="auto-market-map-previews">
          <AnimatePresence>
            {selectedLocation1 && (
              <motion.div
                layout
                className="auto-market-map-preview-wrapper"
                initial={{ opacity: 0, scale: 0.8, x: "0%" }}
                animate={{
                  opacity: 1,
                  scale: 1,
                  x: selectedLocation2 ? "-5%" : "0%",
                }}
                exit={{ opacity: 0, scale: 0.8 }}
                transition={{
                  duration: 2,
                  type: "spring",
                  stiffness: 100,
                  damping: 15,
                }}
              >
                <div className="auto-market-map-preview">
                  {memoizedMapPreview1}
                </div>
              </motion.div>
            )}
            {selectedLocation2 && (
              <motion.div
                layout
                className="auto-market-map-preview-wrapper"
                initial={{ opacity: 0, scale: 0.8, x: "0%" }}
                animate={{
                  opacity: 1,
                  scale: 1,
                  x: "5%",
                }}
                exit={{ opacity: 0, scale: 0.8 }}
                transition={{
                  duration: 1.5,
                  type: "spring",
                  stiffness: 100,
                  damping: 15,
                }}
              >
                <div className="auto-market-map-preview">
                  {memoizedMapPreview2}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>

        <AnimatePresence>
          {showDataCategories && (
            <motion.div
              layout
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              <DataCategoriesSection
                onSubmit={(categories) => {
                  setSelectedCategories(categories);
                  setIsGenerateButtonEnabled(categories.length > 0);
                }}
                initialSelectedCategories={selectedCategories}
                isExpanded={isDataCategoriesExpanded}
                setIsExpanded={setIsDataCategoriesExpanded}
                selectedLocations={[selectedLocation1, selectedLocation2]}
              />
            </motion.div>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {(selectedLocation1 || selectedLocation2) && (
            <motion.button
              className={`generate-market-sheet-button ${isGenerateButtonEnabled ? "" : "disabled"}`}
              onClick={handleGenerateMarketSheet}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5 }}
              disabled={!isGenerateButtonEnabled}
            >
              Generate Market Sheet
            </motion.button>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {showPrintPreview && (
            <PrintPreview
              isOpen={showPrintPreview}
              onClose={() => setShowPrintPreview(false)}
              selectedCategories={selectedCategories.slice(0, 8)}
              selectedNeighborhoods={[selectedLocation1, selectedLocation2]
                .filter(Boolean)
                .map((location, index) => ({
                  ...location,
                  data: locationData[location.id],
                  rankings: rankingsData[location.id]
                }))}
              dateRanges={selectedDateRanges}
            />
          )}
        </AnimatePresence>
      </main>
    </div>
  );
};

export default AutoMarketPage;