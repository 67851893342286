import React, { useRef, useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const BarGraphComponent = ({ neighborhoods, startDate, endDate }) => {
    const containerRef = useRef(null);
    const chartRef = useRef(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const [isChartReady, setIsChartReady] = useState(false);

    useEffect(() => {
        const updateSize = () => {
            if (containerRef.current) {
                const { width, height } = containerRef.current.getBoundingClientRect();
                setDimensions({ width, height });
            }
        };

        updateSize();
        window.addEventListener('resize', updateSize);

        // Force initial render of chart
        if (chartRef.current) {
            const chart = chartRef.current;
            chart.update();
            setIsChartReady(true);
        }

        return () => window.removeEventListener('resize', updateSize);
    }, []);

    // Generate mock data
    const generateData = () => {
        const years = ['2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024'];
        const datasets = neighborhoods.map(neighborhood => ({
            label: neighborhood.name,
            data: years.map(() => Math.floor(Math.random() * 6000) + 2000),
            backgroundColor: neighborhood.color,
        }));

        return {
            labels: years,
            datasets: datasets
        };
    };

    const data = generateData();

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        animation: false, // Disable animations for better print support
        scales: {
            x: {
                stacked: false,
            },
            y: {
                stacked: false,
                beginAtZero: true,
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false // Disable tooltips for print
            }
        },
        events: [], // Disable all events for better print support
    };

    // Function to handle chart initialization
    const handleChartInit = (chart) => {
        if (chart && chart.canvas) {
            // Force immediate render
            chart.render();
            setIsChartReady(true);
        }
    };

    return (
        <div 
            ref={containerRef} 
            style={{ 
                display: 'flex', 
                height: '400px', // Fixed height for print
                width: '100%',
                breakInside: 'avoid',
                pageBreakInside: 'avoid'
            }}
            data-html2canvas-render="true"
        >
            <div style={{ 
                width: '20%', 
                padding: '10px', 
                overflowY: 'auto'
            }}>
                {neighborhoods.map((neighborhood, index) => (
                    <div 
                        key={index} 
                        style={{ 
                            display: 'flex', 
                            alignItems: 'center', 
                            marginBottom: '5px'
                        }}
                    >
                        <div 
                            style={{ 
                                width: '12px', 
                                height: '12px', 
                                backgroundColor: neighborhood.color, 
                                marginRight: '5px' 
                            }}
                        />
                        <span style={{ fontSize: '0.75rem' }}>{neighborhood.name}</span>
                    </div>
                ))}
            </div>
            <div 
                style={{ 
                    width: '80%', 
                    height: '100%',
                    minHeight: '300px' // Ensure minimum height for print
                }}
                data-html2canvas-render="true"
            >
                <Bar 
                    ref={chartRef}
                    data={data} 
                    options={options}
                    onAfterDraw={() => setIsChartReady(true)}
                    redraw={true}
                />
            </div>
        </div>
    );
};

export default BarGraphComponent;