import React, { useMemo, useState, useEffect, memo } from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { Button, Box, } from '@mui/material';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

// Helper function to parse dates from the format "M/1/YY"
const parseIndexDate = (dateStr) => {
    const [month, day, year] = dateStr.split('/');
    const fullYear = parseInt(year) < 50 ? 2000 + parseInt(year) : 1900 + parseInt(year);
    return new Date(fullYear, parseInt(month) - 1, parseInt(day));
};

// Process data once and memoize it
const processIndexData = (indexData) => {
    if (!indexData) return [];

    return Object.entries(indexData)
        .map(([dateStr, value]) => ({
            date: parseIndexDate(dateStr),
            value: value
        }))
        .sort((a, b) => a.date - b.date);
};

const commonButtonStyle = {
    borderRadius: '6px',
    background: '#ECECEC',
    color: 'black',
};

export const ValuationTab = ({ locationData, isMarketMode = false }) => {
    // Local state for dates
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [quickFilter, setQuickFilter] = useState('5Y');

    // Generate mock data if no location data
    const generateMockData = () => {
        const mockData = {};
        const endDate = new Date();
        const startDate = new Date();
        startDate.setFullYear(endDate.getFullYear() - 5);  // 5 years of data

        for (let date = new Date(startDate); date <= endDate; date.setMonth(date.getMonth() + 1)) {
            const dateStr = `${date.getMonth() + 1}/1/${date.getFullYear().toString().slice(-2)}`;
            // Generate values from 100,000 to 500,000 using a sine wave pattern for natural variation
            const progress = (date - startDate) / (endDate - startDate);
            const baseValue = 100000 + (400000 * progress);
            const variation = Math.sin(progress * Math.PI * 4) * 50000;
            mockData[dateStr] = Math.round(baseValue + variation);
        }
        return mockData;
    };

    // Process data once when component mounts or locationData changes
    const processedData = useMemo(() => {
        const dataToProcess = locationData?.indexData || generateMockData();
        return processIndexData(dataToProcess);
    }, [locationData?.indexData]);

    // Set initial dates based on available data
    useEffect(() => {
        if (processedData.length > 0) {
            const dates = processedData.map(d => d.date);
            const latestDate = new Date(Math.max(...dates.map(d => d.getTime())));
            const fiveYearsAgo = new Date(latestDate);
            fiveYearsAgo.setFullYear(latestDate.getFullYear() - 5);

            setStartDate(fiveYearsAgo);
            setEndDate(latestDate);
            setQuickFilter('5Y');
        }
    }, [processedData]);

    const handleQuickFilterChange = (filter) => {
        setQuickFilter(filter);
        const end = new Date(endDate);
        let start = new Date(end);
        switch (filter) {
            case '1M':
                start.setMonth(end.getMonth() - 1);
                break;
            case '3M':
                start.setMonth(end.getMonth() - 3);
                break;
            case '6M':
                start.setMonth(end.getMonth() - 6);
                break;
            case '1Y':
                start.setFullYear(end.getFullYear() - 1);
                break;
            case '5Y':
                start.setFullYear(end.getFullYear() - 5);
                break;
            default:
                break;
        }
        setStartDate(start);
        setEndDate(end);
    };

    // Filter data for chart display
    const filteredData = useMemo(() => {
        if (!processedData.length) return [];
        return processedData.filter(d => d.date >= startDate && d.date <= endDate);
    }, [processedData, startDate, endDate]);

    const renderChart = () => {
        if (!filteredData.length) return null;

        // Get min and max values for proper y-axis scaling
        const values = filteredData.map(d => d.value);
        const minValue = Math.floor(Math.min(...values));
        const maxValue = Math.ceil(Math.max(...values));

        const chartSeries = [{
            data: filteredData.map(d => d.value),
            curve: "linear",
            showMark: false,
            color: '#1352e2',
        }];


        return (
            <div className="chart-container">
                <LineChart
                    className="valuation-chart"
                    xAxis={[{
                        data: filteredData.map(d => d.date.getTime()),
                        scaleType: 'time',
                        valueFormatter: (value) => new Date(value).toLocaleDateString(),
                        min: filteredData[0].date.getTime(),
                        max: filteredData[filteredData.length - 1].date.getTime()
                    }]}
                    yAxis={[{
                        min: minValue,
                        max: maxValue,
                        tickNumber: 5,
                        valueFormatter: (value) => `$${value.toLocaleString()}`,
                    }]}
                    series={chartSeries}
                    height={150}
                    margin={{ left: 60, right: 20, top: 20, bottom: 20 }}
                    sx={{ fontSize: '0.75rem' }}
                    grid={{ vertical: true, horizontal: true }}
                    tooltip={{
                        formatter: (params) => {
                            const date = new Date(params.x).toLocaleDateString();
                            return `${date}: $${params.y.toLocaleString()}`;
                        }
                    }}
                />
                {!locationData?.indexData && (
                    <div style={{
                        fontSize: '0.5rem',
                        color: '#666',
                        marginTop: '8px',
                        textAlign: 'center',
                        fontStyle: 'italic'
                    }}>
                        * Showing mock data for demonstration purposes
                    </div>
                )}
            </div>
        );
    };

    if (isMarketMode) {
        return renderChart();
    }

    return (
        <Box sx={{
            border: '1px solid #ececec',
            borderRadius: '0.9vh',
            overflow: 'hidden'
        }}>
            <Box sx={{ p: 1 }}>
                <Box className="date-filters" sx={{
                    display: 'flex',
                    gap: '2px',
                    mb: 1,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <Box sx={{ display: 'flex', gap: '2px' }}>
                        {['1M', '3M', '6M', '1Y', '5Y'].map(filter => (
                            <Button
                                key={filter}
                                onClick={() => handleQuickFilterChange(filter)}
                                variant={quickFilter === filter ? 'contained' : 'outlined'}
                                size="small"
                                sx={{
                                    ...commonButtonStyle,
                                    minWidth: '30px',
                                    padding: '2px 4px',
                                    fontSize: '0.7rem',
                                    ...(quickFilter === filter && { backgroundColor: '#1352e2', color: 'white' }),
                                    marginBottom: '-.8rem'
                                }}
                            >
                                {filter}
                            </Button>
                        ))}
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px',
                        marginBottom: '-.8rem'
                    }}>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            customInput={
                                <Button variant="outlined" size="small" sx={{
                                    ...commonButtonStyle,
                                    fontSize: '0.7rem',
                                    padding: '2px 4px',
                                    minWidth: 'auto'
                                }}>
                                    {startDate.toLocaleDateString()}
                                </Button>
                            }
                        />
                        <span style={{
                            color: '#666',
                            fontSize: '0.7rem',
                            margin: '0 2px'
                        }}>-</span>
                        <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            customInput={
                                <Button variant="outlined" size="small" sx={{
                                    ...commonButtonStyle,
                                    fontSize: '0.7rem',
                                    padding: '2px 4px',
                                    minWidth: 'auto'
                                }}>
                                    {endDate.toLocaleDateString()}
                                </Button>
                            }
                        />
                    </Box>
                </Box>
                {renderChart()}
            </Box>
        </Box>
    );
};

export const AlgorithmOverview = ({ locationData, startDate, endDate }) => {
    // Process and memoize data
    const processedData = useMemo(() => {
        const processed = processIndexData(locationData?.indexData);
        if (!processed.length) return [];

        const start = new Date(startDate);
        const end = new Date(endDate);

        // Try to get data for selected range
        let filteredData = processed.filter(d => {
            const date = new Date(d.date);
            return date >= start && date <= end;
        });

        // If we don't have enough data in the selected range,
        // use the 3 most recent data points
        if (filteredData.length < 3) {
            filteredData = processed
                .sort((a, b) => b.date - a.date)
                .slice(0, 3)
                .sort((a, b) => a.date - b.date);
        }

        return filteredData;
    }, [locationData?.indexData, startDate, endDate]);

    if (!processedData.length) {
        return <div>No valuation data available</div>;
    }

    // Get min and max values for y-axis scaling
    const values = processedData.map(d => d.value);
    const minValue = Math.floor(Math.min(...values));
    const maxValue = Math.ceil(Math.max(...values));

    const chartSeries = [{
        data: processedData.map(d => d.value),
        curve: "linear",
        showMark: false,
        color: '#1352e2',
    }];

    const actualDateRange = {
        start: processedData[0].date,
        end: processedData[processedData.length - 1].date
    };

    const isShowingDifferentRange =
        actualDateRange.start > startDate ||
        actualDateRange.end < endDate;

    return (
        <div className="chart-container">
            <LineChart
                className="valuation-chart"
                xAxis={[{
                    data: processedData.map(d => d.date.getTime()),
                    scaleType: 'time',
                    valueFormatter: (value) => new Date(value).toLocaleDateString(),
                    min: processedData[0].date.getTime(),
                    max: processedData[processedData.length - 1].date.getTime()
                }]}
                yAxis={[{
                    min: minValue,
                    max: maxValue,
                    tickNumber: 5,
                    valueFormatter: (value) => `$${value.toLocaleString()}`,
                }]}
                series={chartSeries}
                height={175}
                margin={{ left: 60, right: 20, top: 20, bottom: 20 }}
                sx={{ fontSize: '0.75rem' }}
                grid={{ vertical: true, horizontal: true }}
                tooltip={{
                    formatter: (params) => {
                        const date = new Date(params.x).toLocaleDateString();
                        return `${date}: $${params.y.toLocaleString()}`;
                    }
                }}
            />
            {isShowingDifferentRange && (
                <div style={{
                    fontSize: '0.7rem',
                    color: '#666',
                    marginTop: '8px',
                    fontStyle: 'italic'
                }}>
                    * Showing available data from {actualDateRange.start.toLocaleDateString()} to {actualDateRange.end.toLocaleDateString()}
                </div>
            )}
        </div>
    );
};

const AlgoTab = ({ ContentBox, locationData }) => {
    return (
        <>
            <ContentBox
                title="Valuation Index"
                key="ValuationTab"
                subtype="algo"
                contentType="ValuationTab"
            >
                <ValuationTab locationData={locationData} />
            </ContentBox>
        </>
    );
};

export default AlgoTab;