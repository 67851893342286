import React from 'react';
import './TrashModal.css';

const TrashModal = ({ isOpen, onClose, onConfirm }) => {
    if (!isOpen) return null;

    return (
        <div className="trash-modal-overlay">
            <div className="trash-modal-container">
                <h2 className="trash-modal-title">
                    Clear Market Sheet
                </h2>
                <p className="trash-modal-text">
                    Are you sure you want to clear your market sheet?
                </p>
                <div className="trash-modal-buttons">
                    <button
                        onClick={onConfirm}
                        className="trash-modal-button-delete"
                    >
                        Delete
                    </button>
                    <button
                        onClick={onClose}
                        className="trash-modal-button-keep"
                    >
                        Keep
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TrashModal;