import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchUsers, createUser, createGroup, addUserToGroup, fetchGroups, removeUserFromGroup } from '../actions/auth';
import './AdminPage.css';

const AdminPage = ({ fetchUsers, createUser, createGroup, addUserToGroup, removeUserFromGroup, fetchGroups, users }) => {
    const [newUser, setNewUser] = useState({ email: '', password: '' });
    const [newGroup, setNewGroup] = useState('');
    const [selectedUser, setSelectedUser] = useState('');
    const [selectedGroup, setSelectedGroup] = useState('');
    const [selectedUserForRemoval, setSelectedUserForRemoval] = useState('');
    const [selectedGroupForRemoval, setSelectedGroupForRemoval] = useState('');

    useEffect(() => {
        fetchUsers();
        fetchGroups(); // Fetch groups
    }, [fetchUsers, fetchGroups]);

    console.log('Users state:', users);

    const handleCreateUser = (e) => {
        e.preventDefault();
        createUser(newUser)
            .then(() => {
                fetchUsers(); // Refetch users after a new user is created successfully
                setNewUser({ email: '', password: '' }); // Reset form
            })
            .catch(error => {
                console.error("Failed to create user:", error);
                // Handle error (e.g., show a notification to the user)
            });
    };

    const handleCreateGroup = (e) => {
        e.preventDefault();
        createGroup(newGroup)
            .then(() => {
                fetchGroups(); // Refetch groups after a new group is created successfully
                setNewGroup(''); // Reset form
            })
            .catch(error => {
                console.error("Failed to create group:", error);
                // Handle error
            });
    };

    const handleAddUserToGroup = (e) => {
        e.preventDefault();
        addUserToGroup(selectedUser, selectedGroup)
            .then(() => {
                fetchUsers(); // Assuming adding a user to a group might change the users' list
                fetchGroups(); // Optional: if you also need to update group information
            })
            .catch(error => {
                console.error("Failed to add user to group:", error);
                // Handle error
            });
    };

    const handleRemoveUserFromGroup = (e) => {
        e.preventDefault();
        removeUserFromGroup(selectedUserForRemoval, selectedGroupForRemoval)
            .then(() => {
                fetchUsers(); // Refetch users to update the state
            })
            .catch(error => {
                console.error("Failed to remove user from group:", error);
                // Handle error
            });
    };


    return (
        <div className="admin-page">
            <h1>Admin Page</h1>
            <ul>
                {users.users.map(user => (
                    <li key={user.id}>
                        {user.email} - Groups: {(user.groups || []).join(', ')}
                    </li>
                ))}
            </ul>
            <div>
                <h2>Create New User</h2>
                <form onSubmit={handleCreateUser}>
                    <input
                        type="email"
                        placeholder="Email"
                        value={newUser.email}
                        onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={newUser.password}
                        onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
                    />
                    <button type="submit">Create User</button>
                </form>
            </div>
            <div>
                <h2>Create New Group</h2>
                <form onSubmit={handleCreateGroup}>
                    <input
                        type="text"
                        placeholder="Group Name"
                        value={newGroup}
                        onChange={(e) => setNewGroup(e.target.value)}
                    />
                    <button type="submit">Create Group</button>
                </form>
            </div>
            <div>
                <h2>Add User to Group</h2>
                <form onSubmit={handleAddUserToGroup}>
                    <select
                        value={selectedUser}
                        onChange={(e) => setSelectedUser(e.target.value)}
                    >
                        <option value="">Select User</option>
                        {users.users.map(user => (
                            <option key={user.id} value={user.id}>
                                {user.email}
                            </option>
                        ))}
                    </select>
                    <select
                        value={selectedGroup}
                        onChange={(e) => setSelectedGroup(e.target.value)}
                    >
                        <option value="">Select Group</option>
                        {users.groups.map(group => (
                            <option key={group.id} value={group.name}>
                                {group.name}
                            </option>
                        ))}
                    </select>
                    <button type="submit">Add User to Group</button>
                </form>
            </div>
            <div>
                <h2>Remove User from Group</h2>
                <form onSubmit={handleRemoveUserFromGroup}>
                    <select
                        value={selectedUserForRemoval}
                        onChange={(e) => setSelectedUserForRemoval(e.target.value)}
                    >
                        <option value="">Select User</option>
                        {users.users.map(user => (
                            <option key={user.id} value={user.id}>{user.email}</option>
                        ))}
                    </select>
                    <select
                        value={selectedGroupForRemoval}
                        onChange={(e) => setSelectedGroupForRemoval(e.target.value)}
                    >
                        <option value="">Select Group</option>
                        {users.groups.map(group => (
                            <option key={group.id} value={group.name}>{group.name}</option>
                        ))}
                    </select>
                    <button type="submit">Remove User from Group</button>
                </form>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    users: state.users,
});

export default connect(mapStateToProps, { fetchUsers, createUser, createGroup, addUserToGroup, removeUserFromGroup, fetchGroups })(AdminPage);
