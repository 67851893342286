import React from 'react';
import { useNavigate } from 'react-router-dom';
import './VisitOtherProducts.css';
import simpleSheets from '../assets/simple-sheets.svg';
import customSheets from '../assets/custom-sheets.svg';
import detailedResearch from '../assets/detailed-research.svg';

const productData = {
    simpleSheets: {
        image: simpleSheets,
        title: "SnapSheets",
        description: "Generate professional, one-page summaries with hyper-local insights, made ready to share.",
        mode: "simple"
    },
    customSheets: {
        image: customSheets,
        title: "CustomCanvas",
        description: "Customize reports to match your brand, refine data, and dive deeper into neighborhood trends.",
        mode: "custom"
    },
    detailedResearch: {
        image: detailedResearch,
        title: "DeepDive",
        description: "Compare neighborhoods side by side with detailed analytics to enhance your recommendations.",
        mode: "research"
    }
};

const VisitOtherProducts = ({ productType }) => {
    const navigate = useNavigate();
    const product = productData[productType];

    const handleClick = () => {
        navigate(`/dashboard?mode=${product.mode}`);
    };

    return (
        <div className="visit-other-products">
            <div className="visit-other-products__image-container">
                <img
                    src={product.image}
                    alt={product.title}
                    className="visit-other-products__image"
                />
            </div>
            <div className="visit-other-products__content">
                <p className="visit-other-products__description">{product.description}</p>
                <button className="visit-other-products__button" onClick={handleClick}>
                    {product.title}
                </button>
            </div>
        </div>
    );
};

export default VisitOtherProducts;