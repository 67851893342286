import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// src/index.js or wherever your root component is defined
import { Provider } from 'react-redux';
import store from './store'; // Adjust the import path as necessary // Adjust the import path as necessary

const container = document.getElementById('root');
const root = createRoot(container);

root.render( // Use the root to render your app component.
  <Provider store={store}>
    <App />
  </Provider>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
