import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './BlogPost.css';
import BlogPostSidebar from './BlogPostSidebar';

function BlogPost() {
    const { slug } = useParams();
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [showSidebar, setShowSidebar] = useState(true);

    useEffect(() => {
        const handleResize = () => {
            const mobile = window.innerWidth <= 768;
            setIsMobile(mobile);
            setShowSidebar(!mobile);
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const loadPost = async () => {
            try {
                // First try JSON
                const response = await fetch(`/static/blog/${slug}.json`);
                if (response.ok) {
                    const postData = await response.json();
                    setPost({
                        title: postData.title,
                        content: postData.htmlContent,
                        description: postData.description,
                        image: postData.thumbnail,
                        date: postData.date,
                        category: postData.category,
                        author: postData.author
                    });
                    return;
                }

                // Fall back to HTML if JSON fails
                const htmlResponse = await fetch(`/static/blog/${slug}.html`);
                if (!htmlResponse.ok) {
                    throw new Error('Failed to load blog post');
                }
                const html = await htmlResponse.text();
                const parser = new DOMParser();
                const doc = parser.parseFromString(html, 'text/html');

                setPost({
                    title: doc.querySelector('title')?.textContent.replace(' | RealAnalytica', '') || '',
                    content: doc.querySelector('article')?.innerHTML || '',
                    description: doc.querySelector('meta[name="description"]')?.getAttribute('content') || '',
                    image: doc.querySelector('meta[property="og:image"]')?.getAttribute('content') || '',
                    date: doc.querySelector('.post-date')?.textContent || '',
                    category: doc.querySelector('.post-category')?.textContent || '',
                    author: doc.querySelector('.post-author')?.textContent || ''
                });
            } catch (err) {
                console.error('Error loading blog post:', err);
                setError('Failed to load blog post');
            } finally {
                setLoading(false);
            }
        };

        if (slug) {
            loadPost();
        }
    }, [slug]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;
    if (!post) return <div>Post not found</div>;

    return (
        <>
            <Helmet>
                <title>{post.title}</title>
                <meta name="description" content={post.description} />
                <meta property="og:image" content={post.image} />
            </Helmet>
            <div className="blog-post-page">
                {isMobile && (
                    <Link to="/blog" className="back-to-blogs-button">
                        ← Back to Blogs
                    </Link>
                )}
                {showSidebar && <BlogPostSidebar />}
                <div className="blog-post-content">
                    <div className="post-header">
                        {post.image && (
                            <img
                                src={post.image}
                                alt={post.title}
                                onError={(e) => {
                                    console.error('Image failed to load:', e.target.src);
                                    // Optionally set a fallback image
                                    // e.target.src = '/fallback-image.jpg';
                                }}
                            />
                        )}
                        <p className="post-category">{post.category}</p>
                        <h1>{post.title}</h1>
                        <p className="post-date">{post.date}</p>
                        <p className="post-author">{post.author}</p>
                    </div>
                    <div
                        className="post-body"
                        dangerouslySetInnerHTML={{ __html: post.content }}
                    />
                </div>
            </div>
        </>
    );
}

export default BlogPost;