import React, { memo } from 'react';
import { Pie, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { LineChart } from '@mui/x-charts/LineChart';
import { Box } from '@mui/material';


ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// Shared SVG pie chart component
const MarketModePieChart = memo(({ data, height = 125, showPercentages = true }) => {
  const total = data.datasets[0].data.reduce((sum, value) => sum + value, 0);
  let currentAngle = 0;
  const size = 125;
  const radius = size / 2;
  const centerX = radius;
  const centerY = radius;
  const donutWidth = radius * 0.6;

  const legendItemHeight = 20;
  const legendWidth = 165;
  const legendX = size + 20;
  const totalHeight = Math.max(size, data.labels.length * legendItemHeight);

  return (
    <div className="flex flex-col items-center w-full">
      <div className="mt-6 flex justify-center" style={{ marginTop: '24px', display: 'flex', justifyContent: 'center' }}>
        <svg viewBox={`0 0 ${size + legendWidth} ${totalHeight}`} style={{ width: `${size + legendWidth}px`, height: `${totalHeight}px` }}>
          {/* Main chart group */}
          <g transform={`translate(0, ${totalHeight / 2 - size / 2})`}>
            {data.datasets[0].data.map((value, i) => {
              const percentage = (value / total) * 100;
              const angle = (percentage / 100) * Math.PI * 2;
              const largeArcFlag = percentage > 50 ? 1 : 0;

              const startX = centerX + radius * Math.cos(currentAngle);
              const startY = centerY + radius * Math.sin(currentAngle);
              const endX = centerX + radius * Math.cos(currentAngle + angle);
              const endY = centerY + radius * Math.sin(currentAngle + angle);

              const innerStartX = centerX + (radius - donutWidth) * Math.cos(currentAngle);
              const innerStartY = centerY + (radius - donutWidth) * Math.sin(currentAngle);
              const innerEndX = centerX + (radius - donutWidth) * Math.cos(currentAngle + angle);
              const innerEndY = centerY + (radius - donutWidth) * Math.sin(currentAngle + angle);

              const path = [
                `M ${startX} ${startY}`,
                `A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endX} ${endY}`,
                `L ${innerEndX} ${innerEndY}`,
                `A ${radius - donutWidth} ${radius - donutWidth} 0 ${largeArcFlag} 0 ${innerStartX} ${innerStartY}`,
                'Z'
              ].join(' ');

              const thisAngle = currentAngle;
              currentAngle += angle;

              return (
                <path 
                  key={data.labels[i]}
                  d={path} 
                  fill={data.datasets[0].backgroundColor[i]} 
                />
              );
            })}
          </g>

          {/* Legend */}
          <g transform={`translate(${legendX}, 0)`}>
            {data.labels.map((label, i) => (
              <g key={label} transform={`translate(0, ${i * legendItemHeight})`}>
                {/* Legend color box */}
                <rect
                  x="0"
                  y="4"
                  width="12"
                  height="12"
                  fill={data.datasets[0].backgroundColor[i]}
                />
                {/* Legend text */}
                <text
                  x="20"
                  y="14"
                  fontSize="9"
                  fontFamily="sans-serif"
                  fill="#333"
                >
                  {`${label} (${Math.round(data.datasets[0].data[i])}%)`}
                </text>
              </g>
            ))}
          </g>
        </svg>
      </div>
    </div>
  );
});
// SVG-based bar chart component
const MarketModeBarChart = memo(({ data, options = {} }) => {
  const chartWidth = 400;
  const chartHeight = 200;
  const margins = { top: 20, right: 20, bottom: 50, left: 40 };
  const width = chartWidth - margins.left - margins.right;
  const height = chartHeight - margins.top - margins.bottom;

  const values = data.datasets[0].data;
  const maxValue = Math.max(...values);
  const barWidth = width / values.length * 0.8;
  const barSpacing = width / values.length * 0.2;

  return (
    <div className="flex flex-col items-center w-full">
      <div className="mt-6 flex justify-center" style={{ marginTop: '12px', display: 'flex', justifyContent: 'center' }}>
        <svg viewBox={`0 0 ${chartWidth} ${chartHeight}`}>
          <g transform={`translate(${margins.left}, ${margins.top})`}>
            {/* Y-axis */}
            <line x1="0" y1="0" x2="0" y2={height} stroke="#333" />
            {[0, 25, 50, 75, 100].map((tick, i) => (
              <g key={tick} transform={`translate(-10, ${height - (height * tick / 100)})`}>
                <line x1="0" x2="10" y1="0" y2="0" stroke="#333" />
                <text x="-5" y="5" textAnchor="end" fontSize="9">{tick}%</text>
              </g>
            ))}

            {/* Bars */}
            {values.map((value, i) => (
              <g key={i} transform={`translate(${i * (barWidth + barSpacing)}, 0)`}>
                <rect
                  x="0"
                  y={height - (height * value / maxValue)}
                  width={barWidth}
                  height={(height * value / maxValue)}
                  fill={data.datasets[0].backgroundColor}
                />
                <text
                  x={barWidth / 2}
                  y={height + 20}
                  textAnchor="middle"
                  transform={`rotate(45, ${barWidth / 2}, ${height + 20})`}
                  fontSize="9"
                >
                  {data.labels[i]}
                </text>
              </g>
            ))}
          </g>
        </svg>
      </div>
    </div>
  );
});
export { MarketModePieChart, MarketModeBarChart };

// Market mode versions of population charts
export const MarketModePopStatRacialDem = memo(({ locationData }) => {
  if (!locationData?.demographics) return null;
  
  const { demographics } = locationData;
  
  // Use the exact same data structure as the regular component
  const data = {
    labels: [
      'White Non-Hispanic',
      'Hispanic (Any Race)',
      'Black',
      'Asian',
      'Multiracial',
      'Other'
    ],
    datasets: [{
      data: [
        Math.round(demographics.populationWhiteNonHispanicPct),
        Math.round(demographics.populationHispanicPct),
        Math.round(demographics.populationBlackPct),
        Math.round(demographics.populationAsianPct),
        Math.round(demographics.populationMultiracialPct),
        Math.round(demographics.populationAmIndianPct + demographics.populationOtherRacePct)
      ],
      backgroundColor: [
        '#4e79a7',
        '#f28e2c',
        '#e15759',
        '#76b7b2',
        '#59a14f',
        '#b07aa1'
      ],
    }]
  };

  return <MarketModePieChart data={data} />;
});

export const MarketModePopStatTransport = memo(({ locationData }) => {
  if (!locationData?.transportation) return null;

  const { transportation } = locationData;

  const data = {
    labels: [
      'Drive Alone',
      'Carpool',
      'Public Transit',
      'Work from Home',
      'Walk',
      'Other'
    ],
    datasets: [{
      data: [
        Math.round(transportation.transportationCarAlonePct),
        Math.round(transportation.transportationCarCarpoolPct),
        Math.round(transportation.transportationPublicPct),
        Math.round(transportation.transportationWorkFromHomePct),
        Math.round(transportation.transportationWalkPct),
        Math.round(
          transportation.transportationBicyclePct +
          transportation.transportationMotorcyclePct +
          transportation.transportationOtherPct
        )
      ],
      backgroundColor: [
        '#4e79a7',
        '#76b7b2',
        '#f28e2c',
        '#59a14f',
        '#e15759',
        '#b07aa1'
      ],
    }]
  };

  return <MarketModePieChart data={data} />;
});

export const MarketModePopStatHsngOcpd = memo(({ locationData }) => {
  if (!locationData?.housing) return null;

  const { housing } = locationData;
  
  const totalUnits = housing.housingUnits;
  const occupiedPct = Math.round((housing.housingUnitsOccupied / totalUnits) * 100);
  const vacantPct = Math.round((housing.housingUnitsVacant / totalUnits) * 100);

  const data = {
    labels: ['Occupied', 'Vacant'],
    datasets: [{
      data: [occupiedPct, vacantPct],
      backgroundColor: ['#4e79a7', '#e15759'],
    }]
  };

  return <MarketModePieChart data={data} />;
});

export const MarketModePopStatEducPct = memo(({ locationData }) => {
  if (!locationData?.education) return null;

  const { education } = locationData;

  const data = {
    labels: [
      'Some HS',
      'HS',
      'Some College',
      'Associate',
      'Bachelor',
      'Master',
      'Professional',
      'Doctorate'
    ],
    datasets: [{
      label: 'Education Distribution',
      data: [
        Math.round(education.educationSomeHighSchoolPct),
        Math.round(education.educationHighSchoolPct),
        Math.round(education.educationSomeCollegePct),
        Math.round(education.educationAssociateDegreePct),
        Math.round(education.educationBachelorDegreePct),
        Math.round(education.educationMasterDegreePct),
        Math.round(education.educationProfessionalDegreePct),
        Math.round(education.educationDoctorateDegreePct)
      ],
      backgroundColor: 'rgba(75, 192, 192, 0.6)',
    }]
  };

  return <MarketModeBarChart data={data} />;
});

export const MarketModePopStatAgeDem = memo(({ locationData }) => {
  if (!locationData?.demographics) return null;

  const { demographics } = locationData;

  const data = {
    labels: ['0-5', '6-11', '12-17', '18-24', '25-34', '35-44', '45-54', '55-64', '65-74', '75-84', '85+'],
    datasets: [{
      label: 'Age Distribution',
      data: [
        Math.round(demographics.populationAged0_5Pct),
        Math.round(demographics.populationAged6_11Pct),
        Math.round(demographics.populationAged12_17Pct),
        Math.round(demographics.populationAged18_24Pct),
        Math.round(demographics.populationAged25_34Pct),
        Math.round(demographics.populationAged35_44Pct),
        Math.round(demographics.populationAged45_54Pct),
        Math.round(demographics.populationAged55_64Pct),
        Math.round(demographics.populationAged65_74Pct),
        Math.round(demographics.populationAged75_84Pct),
        Math.round(demographics.populationAged85pPct)
      ],
      backgroundColor: 'rgba(75, 192, 192, 0.6)',
    }]
  };

  return <MarketModeBarChart data={data} />;
});
// Helper function to calculate average age from demographics data
const calculateAverageAge = (demographics) => {
  const ageGroups = {
    '0-5': { midpoint: 2.5, pct: demographics.populationAged0_5Pct },
    '6-11': { midpoint: 8.5, pct: demographics.populationAged6_11Pct },
    '12-17': { midpoint: 14.5, pct: demographics.populationAged12_17Pct },
    '18-24': { midpoint: 21, pct: demographics.populationAged18_24Pct },
    '25-34': { midpoint: 29.5, pct: demographics.populationAged25_34Pct },
    '35-44': { midpoint: 39.5, pct: demographics.populationAged35_44Pct },
    '45-54': { midpoint: 49.5, pct: demographics.populationAged45_54Pct },
    '55-64': { midpoint: 59.5, pct: demographics.populationAged55_64Pct },
    '65-74': { midpoint: 69.5, pct: demographics.populationAged65_74Pct },
    '75-84': { midpoint: 79.5, pct: demographics.populationAged75_84Pct },
    '85+': { midpoint: 90, pct: demographics.populationAged85pPct }
  };

  const totalWeight = Object.values(ageGroups).reduce((acc, { midpoint, pct }) => acc + (midpoint * pct), 0);
  const totalPct = Object.values(ageGroups).reduce((acc, { pct }) => acc + pct, 0);

  return Math.round(totalWeight / totalPct);
};

export const PopulationSummary = memo(({ locationData }) => {
  if (!locationData) return null;

  const { demographics } = locationData;
  const avgAge = calculateAverageAge(demographics);
  const populationDensity = Math.round(demographics.population2020 / parseFloat(locationData.areaSquareMi)).toLocaleString();
  const recentGrowth = demographics.populationChgPct2020.toFixed(1);

  return (
    <div>
      <div className="summary-item">
        <span className="summary-label">Population Density:</span>
        <span className="summary-value">{populationDensity} ppl/mile²</span>
      </div>
      <div className="summary-item">
        <span className="summary-label">Median Household Size:</span>
        <span className="summary-value">{demographics.householdSizeAvg.toFixed(1)} ppl</span>
      </div>
      <div className="summary-item">
        <span className="summary-label">Average Age:</span>
        <span className="summary-value">{avgAge} yrs</span>
      </div>
      <div className="summary-item">
        <span className="summary-label">Recent Population Change:</span>
        <span className="summary-value">{recentGrowth}%</span>
      </div>
    </div>
  );
});

export const ResidentialDwellings = memo(({ locationData }) => {
  if (!locationData) return null;

  const { demographics } = locationData;
  const data = {
    labels: ['Rented', 'Owned'],
    datasets: [{
      data: [
        demographics.housingUnitsRenterOccupiedPct,
        demographics.housingUnitsOwnerOccupiedPct
      ],
      backgroundColor: ['#808080', '#0000FF'],
    }]
  };

  return (
    <Pie
      data={data}
      options={{
        responsive: true,
        plugins: {
          legend: {
            position: 'left',
          },
          tooltip: {
            callbacks: {
              label: (context) => `${context.label}: ${context.raw.toFixed(1)}%`
            }
          }
        },
        animation: false,
      }}
    />
  );
});

export const PopulationDemographics = memo(({ locationData }) => {
  if (!locationData) return null;

  const { demographics } = locationData;
  const data = {
    labels: ['0-17', '18-34', '35-54', '55-74', '75+'],
    datasets: [{
      label: 'Age Distribution',
      data: [
        demographics.populationAged0_5Pct + demographics.populationAged6_11Pct + demographics.populationAged12_17Pct,
        demographics.populationAged18_24Pct + demographics.populationAged25_34Pct,
        demographics.populationAged35_44Pct + demographics.populationAged45_54Pct,
        demographics.populationAged55_64Pct + demographics.populationAged65_74Pct,
        demographics.populationAged75_84Pct + demographics.populationAged85pPct
      ],
      backgroundColor: 'rgba(75, 192, 192, 0.6)',
    }]
  };

  return (
    <Bar
      data={data}
      options={{
        responsive: true,
        scales: {
          x: {
            title: {
              display: true,
              text: 'Age Groups',
            },
          },
          y: {
            title: {
              display: true,
              text: 'Percentage',
            },
            beginAtZero: true,
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: (context) => `${context.raw.toFixed(1)}%`
            }
          }
        },
        animation: false,
      }}
    />
  );
});

export const PopulationSummaryResearch = ({ data }) => (
  <div className="bg-gray-100 p-4 rounded-lg">
    <ul className="space-y-2">
      {Object.entries(data).map(([key, value]) => (
        <li key={key} className="flex justify-between population-summary-item">
          <span className="font-medium">{key.replace(/([A-Z])/g, ' $1').trim()}:</span>
          <span className="population-summary-value">{value}</span>
        </li>
      ))}
    </ul>
  </div>
);

export const ResidentialDwellingsResearch = ({ data }) => (
  <div className="bg-gray-100 p-4 rounded-lg">
    <h3 className="text-xl font-semibold mb-2">Residential Dwellings</h3>
    <div className="h-64">
      <Pie data={data} options={{ maintainAspectRatio: false, responsive: true }} />
    </div>
  </div>
);

export const PopulationDemographicsResearch = ({ data }) => (
  <div className="bg-gray-100 p-4 rounded-lg col-span-1 md:col-span-2 lg:col-span-1">
    <h3 className="text-xl font-semibold mb-2">Population Demographics</h3>
    <div className="h-64">
      <Bar
        data={data}
        options={{
          maintainAspectRatio: false,
          responsive: true,
          scales: {
            x: { title: { display: true, text: 'Age Groups' } },
            y: { title: { display: true, text: 'Percentage' } },
          },
        }}
      />
    </div>
  </div>
);

export const PopulationOverview = ({ locationData }) => {
  if (!locationData?.demographics) {
    return <div>No demographic data available</div>;
  }

  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <style jsx global>{`
        :root {
            --summary-title-size: 1.5vh;
            --summary-item-size: 1.5vh;
            --summary-value-size: 1.5vh;
        }
        
        @media (min-width: 768px) {
          :root {
            --summary-title-size: 1.5vh;
            --summary-item-size: 1.5vh;
            --summary-value-size: 1.5vh;
          }
        }
        
        .population-summary-title {
          font-size: var(--summary-title-size);
        }
        
        .population-summary-item {
          font-size: var(--summary-item-size);
        }
        
        .population-summary-value {
          font-size: var(--summary-value-size);
          font-weight: 100;
        }
      `}</style>

      <div className="grid grid-cols-1 gap-4">
        {/* Quick Stats */}
        <div className="bg-gray-100 p-4 rounded-lg">
          <h3 className="text-xl font-semibold mb-2">Population Statistics</h3>
          <PopStatQuickStats locationData={locationData} />
        </div>

        {/* Population Growth Line Chart */}
        <div className="bg-gray-100 p-4 rounded-lg">
          <h3 className="text-xl font-semibold mb-2">Population Growth</h3>
          <PopStatPopAgg locationData={locationData} />
        </div>

        {/* Household Growth Line Chart */}
        <div className="bg-gray-100 p-4 rounded-lg">
          <h3 className="text-xl font-semibold mb-2">Household Growth</h3>
          <PopStatHsldAgg locationData={locationData} />
        </div>

        {/* Racial Demographics Pie Chart */}
        <div className="bg-gray-100 p-4 rounded-lg">
          <h3 className="text-xl font-semibold mb-2">Racial Demographics</h3>
          <PopStatRacialDem locationData={locationData} />
        </div>

        {/* Age Demographics Bar Chart */}
        <div className="bg-gray-100 p-4 rounded-lg">
          <h3 className="text-xl font-semibold mb-2">Age Demographics</h3>
          <PopStatAgeDem locationData={locationData} />
        </div>

        {/* Transportation Pie Chart */}
        <div className="bg-gray-100 p-4 rounded-lg">
          <h3 className="text-xl font-semibold mb-2">Transportation Methods</h3>
          <PopStatTransport locationData={locationData} />
        </div>

        {/* Housing Occupancy Pie Chart */}
        <div className="bg-gray-100 p-4 rounded-lg">
          <h3 className="text-xl font-semibold mb-2">Housing Occupancy</h3>
          <PopStatHsngOcpd locationData={locationData} />
        </div>

        {/* Education Bar Chart */}
        <div className="bg-gray-100 p-4 rounded-lg">
          <h3 className="text-xl font-semibold mb-2">Education Distribution</h3>
          <PopStatEducPct locationData={locationData} />
        </div>
      </div>
    </div>
  );
};

// Helper function to determine appropriate scale increments
const calculateScaleIncrements = (minValue, maxValue) => {
  const range = maxValue - minValue;
  const roughIncrement = range / 4; // Aim for about 4-5 ticks

  // Find the most significant digit
  const magnitude = Math.floor(Math.log10(roughIncrement));
  const pow10 = Math.pow(10, magnitude);
  
  // Round to a nice number
  let niceIncrement;
  const normalizedIncrement = roughIncrement / pow10;
  
  if (normalizedIncrement < 1.5) niceIncrement = 1;
  else if (normalizedIncrement < 3) niceIncrement = 2;
  else if (normalizedIncrement < 7) niceIncrement = 5;
  else niceIncrement = 10;
  
  niceIncrement *= pow10;

  // Calculate nice min and max values
  const niceMin = Math.floor(minValue / niceIncrement) * niceIncrement;
  const niceMax = Math.ceil(maxValue / niceIncrement) * niceIncrement;

  return {
    increment: niceIncrement,
    min: niceMin,
    max: niceMax
  };
};

// Helper function to format axis values
const formatAxisValue = (value) => {
  if (value >= 1000000) {
    return `${(value / 1000000).toFixed(1)}M`;
  }
  if (value >= 1000) {
    return `${(value / 1000).toFixed(1)}K`;
  }
  return value.toString();
};
const pieChartOptions = {
  responsive: true,
  maintainAspectRatio: true,
  aspectRatio: 1.75,
  layout: {
    padding: {
      top: 10,
      right: 0,
      bottom: 10,
      left: 0
    }
  },
  plugins: {
    legend: {
      position: 'right',
      align: 'center',
      labels: {
        boxWidth: 12,
        padding: 10,
        font: {
          size: 9,
          family: 'system-ui'
        }
      }
    },
    tooltip: {
      callbacks: {
        label: (context) => `${context.label}: ${Math.round(context.raw)}%`
      },
      bodyFont: {
        size: 11
      }
    },
    datalabels: {
      display: false
    }
  },
  animation: false
};

const chartContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 'fit-content',
  marginTop: '30px',
  maxHeight: '125px',
  width: '100%'
};

export const PopStatPopAgg = memo(({ locationData }) => {
  if (!locationData?.demographics) return null;

  const { demographics } = locationData;

  const populationData = [
    { year: 2000, value: demographics.population2000 },
    { year: 2010, value: demographics.population2010 },
    { year: 2020, value: demographics.population2020 },
    { year: 2024, value: demographics.population }
  ].filter(point => point.value !== undefined && point.value !== null);

  const chartData = populationData.map(point => ({
    timestamp: new Date(point.year, 0, 1).getTime(),
    value: point.value
  }));

  // Calculate y-axis scale
  const values = chartData.map(d => d.value);
  const minValue = Math.min(...values);
  const maxValue = Math.max(...values);
  const scale = calculateScaleIncrements(minValue, maxValue);

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <LineChart
        className="population-chart"
        xAxis={[{
          data: chartData.map(d => d.timestamp),
          scaleType: 'time',
          valueFormatter: (value) => new Date(value).getFullYear().toString(),
        }]}
        yAxis={[{
          min: scale.min,
          max: scale.max,
          valueFormatter: formatAxisValue,
        }]}
        series={[{
          data: chartData.map(d => d.value),
          curve: "linear",
          showMark: true,
          label: 'Population',
          color: '#1976d2',
        }]}
        height={150}
        margin={{ left: 50, right: 20, top: 20, bottom: 20 }}
        sx={{ fontSize: '0.5rem' }}
        grid={{ vertical: true, horizontal: true }}
        slotProps={{ legend: { hidden: true } }}
        tooltip={{
          formatter: (params) => {
            const year = new Date(params.x).getFullYear();
            return `${year}: ${params.y.toLocaleString()} residents`;
          }
        }}
      />
    </Box>
  );
});

export const PopStatHsldAgg = memo(({ locationData }) => {
  if (!locationData?.demographics) return null;

  const { demographics } = locationData;

  const householdData = [
    { year: 2000, value: demographics.households2000 },
    { year: 2010, value: demographics.households2010 },
    { year: 2020, value: demographics.households2020 },
    { year: 2024, value: demographics.households },
    { year: 2029, value: demographics.households5YrProjection }
  ].filter(point => point.value !== undefined && point.value !== null);

  const chartData = householdData.map(point => ({
    timestamp: new Date(point.year, 0, 1).getTime(),
    value: point.value
  }));

  // Calculate y-axis scale
  const values = chartData.map(d => d.value);
  const minValue = Math.min(...values);
  const maxValue = Math.max(...values);
  const scale = calculateScaleIncrements(minValue, maxValue);

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <LineChart
        className="household-chart"
        xAxis={[{
          data: chartData.map(d => d.timestamp),
          scaleType: 'time',
          valueFormatter: (value) => new Date(value).getFullYear().toString(),
        }]}
        yAxis={[{
          min: scale.min,
          max: scale.max,
          valueFormatter: formatAxisValue,
        }]}
        series={[{
          data: chartData.map(d => d.value),
          curve: "linear",
          showMark: true,
          label: 'Households',
          color: '#1976d2',
        }]}
        height={150}
        margin={{ left: 50, right: 20, top: 20, bottom: 20 }}
        sx={{ fontSize: '0.75rem' }}
        grid={{ vertical: true, horizontal: true }}
        slotProps={{ legend: { hidden: true } }}
        tooltip={{
          formatter: (params) => {
            const year = new Date(params.x).getFullYear();
            const value = params.y.toLocaleString();
            return year === 2029
              ? `${year} (Projected): ${value} households`
              : `${year}: ${value} households`;
          }
        }}
      />
    </Box>
  );
});

export const PopStatQuickStats = memo(({ locationData }) => {
  if (!locationData?.demographics || !locationData?.housing) {
    return <div>No demographic data available</div>;
  }

  const { demographics, housing } = locationData;

  // Helper function for formatting decimal values
  const formatDecimal = (value) => {
    return value.toFixed(1);
  };

  // Helper function for formatting large numbers
  const formatNumber = (value) => {
    return new Intl.NumberFormat('en-US').format(value);
  };

  return (
    <div>
      <div className="summary-item">
        <span className="summary-label">Average Household Size:</span>
        <span className="summary-value">{formatDecimal(demographics.householdSizeAvg)} people</span>
      </div>
      <div className="summary-item">
        <span className="summary-label">Median Age:</span>
        <span className="summary-value">{formatDecimal(demographics.medianAge)} years</span>
      </div>
      <div className="summary-item">
        <span className="summary-label">Total Housing Units:</span>
        <span className="summary-value">{formatNumber(housing.housingUnits)} units</span>
      </div>
    </div>
  );
});

export const PopStatRacialDem = memo(({ locationData }) => {
  if (!locationData?.demographics) return null;

  const { demographics } = locationData;

  const data = {
    labels: [
      'White Non-Hispanic',
      'Hispanic (Any Race)',
      'Black',
      'Asian',
      'Multiracial',
      'Other'
    ],
    datasets: [{
      data: [
        Math.round(demographics.populationWhiteNonHispanicPct),
        Math.round(demographics.populationHispanicPct),
        Math.round(demographics.populationBlackPct),
        Math.round(demographics.populationAsianPct),
        Math.round(demographics.populationMultiracialPct),
        Math.round(demographics.populationAmIndianPct + demographics.populationOtherRacePct)
      ],
      backgroundColor: [
        '#4e79a7',
        '#f28e2c',
        '#e15759',
        '#76b7b2',
        '#59a14f',
        '#b07aa1'
      ],
    }]
  };

  return (
    <div style={chartContainerStyle}>
      <Pie data={data} options={pieChartOptions} />
    </div>
  );
});

export const PopStatAgeDem = memo(({ locationData }) => {
  if (!locationData?.demographics) return null;

  const { demographics } = locationData;

  // Define the age brackets and their labels
  const ageBrackets = [
    { label: '0-5', value: demographics.populationAged0_5Pct },
    { label: '6-11', value: demographics.populationAged6_11Pct },
    { label: '12-17', value: demographics.populationAged12_17Pct },
    { label: '18-24', value: demographics.populationAged18_24Pct },
    { label: '25-34', value: demographics.populationAged25_34Pct },
    { label: '35-44', value: demographics.populationAged35_44Pct },
    { label: '45-54', value: demographics.populationAged45_54Pct },
    { label: '55-64', value: demographics.populationAged55_64Pct },
    { label: '65-74', value: demographics.populationAged65_74Pct },
    { label: '75-84', value: demographics.populationAged75_84Pct },
    { label: '85+', value: demographics.populationAged85pPct }
  ];

  const data = {
    labels: ageBrackets.map(bracket => bracket.label),
    datasets: [{
      label: 'Age Distribution',
      data: ageBrackets.map(bracket => Math.round(bracket.value)),
      backgroundColor: 'rgba(75, 192, 192, 0.6)',
      borderColor: 'rgba(75, 192, 192, 1)',
      borderWidth: 1
    }]
  };

  return (
    <Bar
      data={data}
      options={{
        responsive: true,
        scales: {
          x: {
            title: {
              display: true,
              text: 'Age Groups',
              font: {
                size: 12
              }
            },
            ticks: {
              font: {
                size: 10
              },
              maxRotation: 45,
              minRotation: 45
            }
          },
          y: {
            title: {
              display: true,
              text: '% of Population',
              font: {
                size: 12
              }
            },
            beginAtZero: true,
            ticks: {
              callback: (value) => `${value}%`,
              font: {
                size: 10
              }
            }
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: (context) => {
                const value = Math.round(context.raw);
                return `${value}% of population`;
              }
            }
          },
          legend: {
            display: false
          }
        },
        animation: false,
        maintainAspectRatio: true,
        aspectRatio: 1.5
      }}
    />
  );
});

export const PopStatTransport = memo(({ locationData }) => {
  if (!locationData?.transportation) return null;

  const { transportation } = locationData;

  const data = {
    labels: [
      'Drive Alone',
      'Carpool',
      'Public Transit',
      'Work from Home',
      'Walk',
      'Other'
    ],
    datasets: [{
      data: [
        Math.round(transportation.transportationCarAlonePct),
        Math.round(transportation.transportationCarCarpoolPct),
        Math.round(transportation.transportationPublicPct),
        Math.round(transportation.transportationWorkFromHomePct),
        Math.round(transportation.transportationWalkPct),
        Math.round(
          transportation.transportationBicyclePct +
          transportation.transportationMotorcyclePct +
          transportation.transportationOtherPct
        )
      ],
      backgroundColor: [
        '#4e79a7',
        '#76b7b2',
        '#f28e2c',
        '#59a14f',
        '#e15759',
        '#b07aa1'
      ],
    }]
  };

  const extendedOptions = {
    ...pieChartOptions,
    plugins: {
      ...pieChartOptions.plugins,
      tooltip: {
        callbacks: {
          label: (context) => {
            const value = Math.round(context.raw);
            if (context.label === 'Public Transit') {
              const busPercent = Math.round(transportation.transportationPublicBusPct);
              const railPercent = Math.round(
                transportation.transportationPublicLightRailPct +
                transportation.transportationPublicRailroadPct
              );
              return [
                `Public Transit Total: ${value}%`,
                `  • Bus: ${busPercent}%`,
                `  • Rail: ${railPercent}%`
              ];
            }
            return `${context.label}: ${value}%`;
          }
        }
      }
    }
  };

  return (
    <div style={chartContainerStyle}>
      <Pie data={data} options={extendedOptions} />
    </div>
  );
});

export const PopStatHsngOcpd = memo(({ locationData }) => {
  if (!locationData?.housing) return null;

  const { housing } = locationData;

  const totalUnits = housing.housingUnits;
  const occupiedPct = Math.round((housing.housingUnitsOccupied / totalUnits) * 100);
  const vacantPct = Math.round((housing.housingUnitsVacant / totalUnits) * 100);

  const data = {
    labels: ['Occupied', 'Vacant'],
    datasets: [{
      data: [occupiedPct, vacantPct],
      backgroundColor: ['#4e79a7', '#e15759'],
    }]
  };

  const extendedOptions = {
    ...pieChartOptions,
    plugins: {
      ...pieChartOptions.plugins,
      tooltip: {
        callbacks: {
          label: (context) => {
            const value = context.raw;
            const actualValue = context.label === 'Occupied'
              ? housing.housingUnitsOccupied
              : housing.housingUnitsVacant;
            return [
              `${context.label}: ${value}%`,
              `Units: ${actualValue.toLocaleString()}`
            ];
          }
        }
      }
    }
  };

  return (
    <div style={chartContainerStyle}>
      <Pie data={data} options={extendedOptions} />
    </div>
  );
});

export const PopStatEducPct = memo(({ locationData }) => {
  if (!locationData?.education) return null;

  const { education } = locationData;

  // Define the education levels and their labels
  const educationLevels = [
    { label: 'Some High School', value: education.educationSomeHighSchoolPct },
    { label: 'High School', value: education.educationHighSchoolPct },
    { label: 'Some College', value: education.educationSomeCollegePct },
    { label: 'Associate', value: education.educationAssociateDegreePct },
    { label: 'Bachelor', value: education.educationBachelorDegreePct },
    { label: 'Master', value: education.educationMasterDegreePct },
    { label: 'Professional', value: education.educationProfessionalDegreePct },
    { label: 'Doctorate', value: education.educationDoctorateDegreePct }
  ];

  const data = {
    labels: educationLevels.map(level => level.label),
    datasets: [{
      label: 'Education Distribution',
      data: educationLevels.map(level => Math.round(level.value)),
      backgroundColor: 'rgba(75, 192, 192, 0.6)',
      borderColor: 'rgba(75, 192, 192, 1)',
      borderWidth: 1
    }]
  };

  return (
    <Bar
      data={data}
      options={{
        responsive: true,
        scales: {
          x: {
            title: {
              display: true,
              text: 'Education Level',
              font: {
                size: 12
              }
            },
            ticks: {
              font: {
                size: 10
              },
              maxRotation: 45,
              minRotation: 45
            }
          },
          y: {
            title: {
              display: true,
              text: '% of Population',
              font: {
                size: 12
              }
            },
            beginAtZero: true,
            ticks: {
              callback: (value) => `${value}%`,
              font: {
                size: 10
              }
            }
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: (context) => {
                const value = Math.round(context.raw);
                return `${value}% of population`;
              }
            }
          },
          legend: {
            display: false
          }
        },
        animation: false,
        maintainAspectRatio: true,
        aspectRatio: 1.5
      }}
    />
  );
});

export const PopulationTab = ({ ContentBox, locationData }) => {
  return (
    <>
      <ContentBox
        title="Population Statistics"
        key="PopStatQuickStats"
        subtype="population"
        contentType="PopStatQuickStats"
      >
        <PopStatQuickStats locationData={locationData} />
      </ContentBox>
      <ContentBox
        title="Population Aggregates"
        key="PopStatPopAgg"
        subtype="population"
        contentType="PopStatPopAgg"
      >
        <PopStatPopAgg locationData={locationData} />
      </ContentBox>
      <ContentBox
        title="Household Aggregates"
        key="PopStatHsldAgg"
        subtype="population"
        contentType="PopStatHsldAgg"
      >
        <PopStatHsldAgg locationData={locationData} />
      </ContentBox>
      <ContentBox
        title="Racial Demographics"
        key="PopStatRacialDem"
        subtype="population"
        contentType="PopStatRacialDem"
      >
        <PopStatRacialDem locationData={locationData} />
      </ContentBox>
      <ContentBox
        title="Age Demographics"
        key="PopStatAgeDem"
        subtype="population"
        contentType="PopStatAgeDem"
      >
        <PopStatAgeDem locationData={locationData} />
      </ContentBox>
      <ContentBox
        title="Transportation"
        key="PopStatTransport"
        subtype="population"
        contentType="PopStatTransport"
      >
        <PopStatTransport locationData={locationData} />
      </ContentBox>
      <ContentBox
        title="Housing Occupancy"
        key="PopStatHsngOcpd"
        subtype="population"
        contentType="PopStatHsngOcpd"
      >
        <PopStatHsngOcpd locationData={locationData} />
      </ContentBox>
      <ContentBox
        title="Education Statistics"
        key="PopStatEducPct"
        subtype="population"
        contentType="PopStatEducPct"
      >
        <PopStatEducPct locationData={locationData} />
      </ContentBox>
    </>
  );
};

export default PopulationTab;