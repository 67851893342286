import {
    FETCH_USERS_SUCCESS,
    FETCH_USERS_FAIL,
    FETCH_GROUPS_SUCCESS,
    FETCH_GROUPS_FAIL,
    REMOVE_USER_GROUP_SUCCESS,
    REMOVE_USER_GROUP_FAIL
} from '../actions/types';

const initialState = {
    users: [],
    error: null,
    groups: [],
};

export default function usersReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case FETCH_USERS_SUCCESS:
            return {
                ...state,
                users: payload,
                error: null,
            }
        case FETCH_USERS_FAIL:
            return {
                ...state,
                error: payload,
            }
        case FETCH_GROUPS_SUCCESS:
            console.log('Groups payload:', payload); // Log the payload
            return {
                ...state,
                groups: payload,
                error: null,
            }
        case FETCH_GROUPS_FAIL:
            return {
                ...state,
                error: payload,
            }
        case REMOVE_USER_GROUP_SUCCESS:
            // Logic to update the state after removing a user from a group
            return {
                ...state,
                // Update state appropriately
            };
        case REMOVE_USER_GROUP_FAIL:
            return {
                ...state,
                error: payload,
            };
        default:
            return state;
    }
}
