import React, { useEffect } from "react";
import styled from "styled-components";
import logoImage from "../assets/SVG-realanalytica-icon-blue.svg";
import {
  PopStatAgeDem,
  PopStatEducPct,
  PopStatHsldAgg,
  PopStatHsngOcpd,
  PopStatPopAgg,
  PopStatQuickStats,
  PopStatRacialDem,
  PopStatTransport,
  MarketModePopStatRacialDem,
  MarketModePopStatTransport,
  MarketModePopStatHsngOcpd,
  MarketModePopStatEducPct,
  MarketModePopStatAgeDem,
} from "./contentTabs/PopulationTab";

import {
  SchoolEnrlmtLevelPcts,
  SchoolEnrlmtTypePcts,
  MarketModeSchoolEnrlmtLevelPcts,
  MarketModeSchoolEnrlmtTypePcts,
} from "./contentTabs/SchoolsTab";
import { ViolentCrimeBar, PropertyCrimeBar } from "./contentTabs/CrimeTab";
import {
  IncomeQuickStats,
  IncomeHsldPct,
  MarketModeIncomeHsldPct,
} from "./contentTabs/IncomeTab";
import {
  RecentHomeSalesSummary,
  RecentHomeSalesList,
} from "./contentTabs/RecentHomeSalesTab";
import {
  TopGrowthComparison,
  MedianIncomeComparison,
  MarketModeTopGrowthComparison,
  MarketModeMedianIncomeComparison,
} from "./contentTabs/DynamicNeighborhoodComparisonTab";
import { NeighborhoodComparisonTable } from "./contentTabs/TabularNeighborhoodComparisonTab";
import { ValuationTab } from "./contentTabs/AlgoTab";
import {
  LocMetQuickStats,
  LocMetHsngValue,
  LocMetHsngBltYr,
  MarketModeLocMetHsngValue,
  MarketModeLocMetHsngBltYr,
} from "./contentTabs/LocationMetricsTab";
import { marketModeComponents } from "./contentBoxRegistry";

const PRINT_CONFIG = {
  PAGE_WIDTH: 816,
  PAGE_HEIGHT: 1056,
  SCALE: 2,
  MARGIN: {
    top: 0,
    right: 30,
    bottom: 40,
    left: 30,
  },
};

const OnePagerContentBox = styled.div`
  width: 100%;
  height: 233px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid #000;
  display: flex;
  flex-direction: column;
  page-break-inside: avoid;
  break-inside: avoid;
  overflow: hidden; // Add this to prevent content overflow

  @media print {
    width: calc(50% - 7.5px);
    height: 311px; // Force consistent height
    min-height: 311px;
  }
`;

const Header = styled.div`
  width: 100%;
  height: 34px;
  flex-shrink: 0;
  border-radius: 6px 6px 0px 0px;
  background: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
`;

const Title = styled.span`
  color: white;
  font-weight: 500;
`;

const TemplateContainer = styled.div`
  width: ${PRINT_CONFIG.PAGE_WIDTH}px;
  min-height: ${PRINT_CONFIG.PAGE_HEIGHT}px;
  background-color: white;
  padding: 0;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;

  @media print {
    width: 100%;
    height: 100%;
  }
`;

const Banner = styled.div`
  width: 100%;
  height: 26px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  padding: 0 30px; // Match ContentGrid padding
  box-sizing: border-box;
`;

const BannerText = styled.span`
  font-family: "Aneba Neue", sans-serif;
  font-size: 18px;
  font-style: normal;
  line-height: normal;
`;

const RealAnalytica = styled(BannerText)`
  color: #1352e2;
  font-weight: 600;
`;

const SimpleSheet = styled(BannerText)`
  color: #1352e2;
  font-weight: 400;
`;

const NeighborhoodIndicator = styled.div`
  display: flex;
  align-items: center;
`;

const ColorSphere = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  margin-right: 5px;
`;

const Content = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 12px;
  height: calc(100% - 34px); // Subtract header height
  min-height: 277px; // 311px - 34px header

  // Add specific styling for charts
  .recharts-wrapper {
    width: 100% !important;
    height: 100% !important;
    max-height: 250px;
  }

  svg {
    width: 100% !important;
    height: 100% !important;
    max-height: 250px;
  }

  canvas {
    width: 100% !important;
    height: 100% !important;
    max-height: 250px;
  }

  @media print {
    height: 277px;
    min-height: 277px;
  }
`;

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  width: 100%;
  padding: 15px;
  max-width: ${PRINT_CONFIG.PAGE_WIDTH - 30}px;
  margin: 0 auto;
  box-sizing: border-box;

  @media print {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 15px;
    padding: 15px 30px;
  }
`;

const Logo = styled.img`
  height: 20px;
`;

const componentMapping = {
  "popstat-quickstats": PopStatQuickStats, // No market mode version
  "popstat-popagg": PopStatPopAgg, // No market mode version
  "popstat-hsldagg": PopStatHsldAgg, // No market mode version
  "popstat-racialdem": MarketModePopStatRacialDem,
  "popstat-agedem": MarketModePopStatAgeDem,
  "popstat-transport": MarketModePopStatTransport,
  "popstat-hsngocpd": MarketModePopStatHsngOcpd,
  "popstat-educpct": MarketModePopStatEducPct,
  "income-quickstats": IncomeQuickStats, // No market mode version
  "income-hsldpct": MarketModeIncomeHsldPct,
  "locmet-quickstats": LocMetQuickStats, // No market mode version
  "locmet-hsngvalue": MarketModeLocMetHsngValue,
  "locmet-hsngbltyr": MarketModeLocMetHsngBltYr,
  "school-enrlmtlevel": MarketModeSchoolEnrlmtLevelPcts,
  "school-enrlmttype": MarketModeSchoolEnrlmtTypePcts,
  "crime-violent": ViolentCrimeBar, // No market mode version
  "crime-property": PropertyCrimeBar, // No market mode version
  "dynamic-population": MarketModeTopGrowthComparison,
  "dynamic-income": MarketModeMedianIncomeComparison,
  "recent-sales-summary": RecentHomeSalesSummary, // No market mode version
  "recent-sales-list": RecentHomeSalesList, // No market mode version
  "valuation-index": ValuationTab, // No market mode version
};

const FirstOnePagerTemplate = ({
  selectedCategories,
  selectedNeighborhoods,
  dateRanges,
}) => {
  const neighborhoodColors = ["#1352E2", "#A3DBE1"];
  const formattedNeighborhoods = selectedNeighborhoods.map(
    (neighborhood, index) => ({
      ...neighborhood,
      color: neighborhoodColors[index],
    })
  );

  const isDualMode = formattedNeighborhoods.length === 2;
  const limitedCategories = selectedCategories.slice(0, 8);

  // Add both useEffect hooks inside the component
  useEffect(() => {
    if (typeof window !== "undefined") {
      // Original chart rendering effect
      const charts = document.querySelectorAll(".recharts-wrapper");
      charts.forEach((chart) => {
        const width = chart.parentElement.offsetWidth;
        const height = Math.min(250, width * 0.75); // Maintain aspect ratio
        chart.style.width = `${width}px`;
        chart.style.height = `${height}px`;
      });
    }
  }, [limitedCategories, selectedNeighborhoods]);

  // Add the new resize effect
  useEffect(() => {
    if (typeof window !== "undefined") {
      const resizeCharts = () => {
        const contentBoxes = document.querySelectorAll(".recharts-wrapper");
        contentBoxes.forEach((chart) => {
          const parent = chart.closest('div[class^="Content"]');
          if (parent) {
            const parentHeight = parent.offsetHeight;
            const parentWidth = parent.offsetWidth;
            const height = Math.min(250, parentHeight - 24); // Account for padding
            chart.style.width = `${parentWidth - 24}px`; // Account for padding
            chart.style.height = `${height}px`;
          }
        });
      };

      resizeCharts();
      window.addEventListener("resize", resizeCharts);
      return () => window.removeEventListener("resize", resizeCharts);
    }
  }, [limitedCategories, selectedNeighborhoods]);

  // Add useEffect to handle chart rendering
  useEffect(() => {
    if (typeof window !== "undefined") {
      // Force charts to re-render after mount
      const charts = document.querySelectorAll(".recharts-wrapper");
      charts.forEach((chart) => {
        const width = chart.parentElement.offsetWidth;
        const height = Math.min(250, width * 0.75); // Maintain aspect ratio
        chart.style.width = `${width}px`;
        chart.style.height = `${height}px`;
      });
    }
  }, [limitedCategories, selectedNeighborhoods]);
  const renderComponent = (Component, props, categoryItem) => {
    // Check if a market mode version exists
    const componentName = Component.displayName || Component.name;
    const MarketModeComponent = marketModeComponents[componentName];

    if (MarketModeComponent) {
      return <MarketModeComponent {...props} />;
    }

    return <Component {...props} />;
  };

  return (
    <TemplateContainer>
      <div className="first-one-pager-template">
        <Banner>
          <div>
            <RealAnalytica>RealAnalytica</RealAnalytica>{" "}
            <SimpleSheet>SnapSheets</SimpleSheet>
          </div>
          <div>
            {formattedNeighborhoods.map((neighborhood, index) => (
              <NeighborhoodIndicator key={index}>
                <ColorSphere color={neighborhood.color} />
                {neighborhood.name}
              </NeighborhoodIndicator>
            ))}
          </div>
          <Logo src={logoImage} alt="Company Logo" />
        </Banner>

        <ContentGrid>
          {limitedCategories.map((categoryItem) => {
            const Component = componentMapping[categoryItem.id];
            if (!Component) return null;

            if (isDualMode) {
              return (
                <React.Fragment key={categoryItem.id}>
                  {selectedNeighborhoods.map((neighborhood, index) => (
                    <OnePagerContentBox
                      key={`${categoryItem.id}-${neighborhood.id}`}
                    >
                      <Header>
                        <Title>{`${categoryItem.name} - ${neighborhood.name}`}</Title>
                      </Header>
                      <Content>
                        {categoryItem.id.startsWith("valuation-")
                          ? renderComponent(
                              Component,
                              {
                                locationData: neighborhood?.data, // Use current neighborhood
                                isMarketMode: true,
                              },
                              categoryItem
                            )
                          : categoryItem.id.startsWith("dynamic-")
                          ? renderComponent(
                              Component,
                              {
                                rankingsData: neighborhood?.rankings, // Use current neighborhood
                                selectedLocationId: neighborhood?.id, // Use current neighborhood
                                dateRange: dateRanges[categoryItem.id],
                              },
                              categoryItem
                            )
                          : renderComponent(
                              Component,
                              {
                                locationData: neighborhood?.data, // Use current neighborhood
                                dateRange: dateRanges[categoryItem.id],
                              },
                              categoryItem
                            )}
                      </Content>
                    </OnePagerContentBox>
                  ))}
                </React.Fragment>
              );
            }

            // Single mode
            return (
              <OnePagerContentBox key={categoryItem.id}>
                <Header>
                  <Title>{categoryItem.name}</Title>
                </Header>
                <Content>
                  {categoryItem.id.startsWith("valuation-")
                    ? renderComponent(
                        Component,
                        {
                          locationData: selectedNeighborhoods[0]?.data,
                          isMarketMode: true,
                        },
                        categoryItem
                      )
                    : categoryItem.id.startsWith("dynamic-")
                    ? renderComponent(
                        Component,
                        {
                          rankingsData: selectedNeighborhoods[0]?.rankings,
                          selectedLocationId: selectedNeighborhoods[0]?.id,
                          dateRange: dateRanges[categoryItem.id],
                        },
                        categoryItem
                      )
                    : renderComponent(
                        Component,
                        {
                          locationData: selectedNeighborhoods[0]?.data,
                          dateRange: dateRanges[categoryItem.id],
                        },
                        categoryItem
                      )}
                </Content>
              </OnePagerContentBox>
            );
          })}
        </ContentGrid>
      </div>
    </TemplateContainer>
  );
};
export default FirstOnePagerTemplate;
