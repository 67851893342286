import React, { useState, useEffect, useRef } from 'react';
import './TopBrokeragesComponent.css';

const TopBrokeragesComponent = () => {
    const [brokerages, setBrokerages] = useState([]);
    const scrollRef = useRef(null);
    const [scrollDirection, setScrollDirection] = useState(1); // 1 for down, -1 for up
    const justDisplayText = true; // Toggle this to switch between versions

    useEffect(() => {
        // Mock API call
        const fetchTopBrokerages = async () => {
            await new Promise(resolve => setTimeout(resolve, 1));

            // Extended mock data
            const mockData = Array.from({ length: 7 }, (_, i) => ({
                name: `Brokerage ${i + 1}`,
                referrals: Math.floor(Math.random() * 30) + 10
            })).sort((a, b) => b.referrals - a.referrals);

            setBrokerages(mockData);
        };

        fetchTopBrokerages();
    }, []);

    useEffect(() => {
        if (!scrollRef.current || brokerages.length === 0) return;

        const scrollableElement = scrollRef.current;
        const scrollHeight = scrollableElement.scrollHeight;
        const clientHeight = scrollableElement.clientHeight;

        let animationFrameId;
        let startTime;

        const animate = (timestamp) => {
            if (!startTime) startTime = timestamp;
            const elapsed = timestamp - startTime;

            if (elapsed < 3000) {
                // Wait for 3 seconds before starting the animation
                animationFrameId = requestAnimationFrame(animate);
                return;
            }

            const scrollPosition = scrollableElement.scrollTop;
            const maxScroll = scrollHeight - clientHeight;

            // Check if we've reached the bottom or top
            if (scrollDirection === 1 && Math.ceil(scrollPosition) >= maxScroll) {
                setScrollDirection(-1);
            } else if (scrollDirection === -1 && scrollPosition <= 0) {
                setScrollDirection(1);
            }

            // Scroll by a small amount in the current direction
            scrollableElement.scrollTop += scrollDirection * 0.5; // Adjust speed here

            animationFrameId = requestAnimationFrame(animate);
        };

        animationFrameId = requestAnimationFrame(animate);

        return () => cancelAnimationFrame(animationFrameId);
    }, [brokerages, scrollDirection]);

    return (
        <div className="top-brokerages">
            {justDisplayText ? (
                <div className="top-brokerages__message">
                    Want to see your brokerage featured here? It’s simple: join our referral program and encourage new users to sign up for RealAnalytica. The brokerage with the highest number of referrals at the end of each month will be featured prominently for all users to see boosting your visibility and showcasing your success.
                </div>
            ) : (
                <>
                    <div className="top-brokerages__list" ref={scrollRef}>
                        {brokerages.map((brokerage, index) => (
                            <div key={index} className="top-brokerages__item">
                                <span className="top-brokerages__rank">{index + 1}.</span>
                                <div className="top-brokerages__card">
                                    <div className="top-brokerages__card-header">
                                        {brokerage.name}
                                    </div>
                                    <div className="top-brokerages__card-content">
                                        <span className="top-brokerages__referrals">
                                            {brokerage.referrals} New Referrals
                                        </span>
                                        <div className="top-brokerages__progress-bar">
                                            <div
                                                className="top-brokerages__progress"
                                                style={{ width: `${(brokerage.referrals / 40) * 100}%` }}
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <a href="#" className="top-brokerages__learn-more">Learn More</a>
                </>
            )}
        </div>
    );
};

export default TopBrokeragesComponent;