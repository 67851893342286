import React, { useState, useEffect } from "react";
import dashboardIcon from "../assets/dashboardIcon.svg";
import WatchlistComponent from "./WatchlistComponent";
import TopBrokeragesComponent from "./TopBrokeragesComponent";
import VisitOtherProducts from "./VisitOtherProducts";
import FeaturedDataInsights from "./FeaturedDataInsights";
import { FaPlus, FaTimes, FaSearch } from "react-icons/fa";
import "./UserDashboard.css";
import { fetchLocationDataFromAPI } from "../utils/locationData";
import { updateUser, get_current_user, fetchLocationStatistics } from '../actions/auth';
import addIcon from "../assets/add_btn.svg";

const WATCHLIST_COLORS = ["#1352E2", "#A3DBE1", "#FFE600"];


const EmptyWatchlistBox = ({ onAdd }) => {
  return (
    <div className="watchlist watchlist--empty">
      <div className="watchlist__empty-content">
        <div className="watchlist__search-prompt">
          <p>
            Start your watchlist by searching a neighborhood of interest and
            clicking "Add" below.
          </p>
        </div>
        <button className="watchlist__add-initial" onClick={onAdd}>
          Add
        </button>
      </div>
    </div>
  );
};

const UserDashboard = ({ user }) => {
  const [watchlistItems, setWatchlistItems] = useState([]);
  const [tempWatchlistItems, setTempWatchlistItems] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [locationDataMap, setLocationDataMap] = useState({});

  // Load watchlist items on mount
  useEffect(() => {
    const initializeData = async () => {
      try {
        setIsLoading(true);

        // Fetch all available locations
        const [neighborhoodsResponse, citiesResponse] = await Promise.all([
          fetchLocationDataFromAPI(
            "neighborhood",
            100,
            0,
            "bd7ccc4f2052140835b9eb0ad094b7b7"
          ),
          fetchLocationDataFromAPI(
            "city",
            100,
            0,
            "bd7ccc4f2052140835b9eb0ad094b7b7"
          ),
        ]);

        let locations = [];

        if (neighborhoodsResponse.success && neighborhoodsResponse.data?.locations) {
          const neighborhoods = neighborhoodsResponse.data.locations.map(location => ({
            id: location.id.trim(),
            name: location.name.split(",")[0].trim(),
            fullName: location.name,
            type: "neighborhood",
          }));
          locations = locations.concat(neighborhoods);
        }

        if (citiesResponse.success && citiesResponse.data?.locations) {
          const cities = citiesResponse.data.locations.map(location => ({
            id: location.id.trim(),
            name: location.name.split(",")[0].trim(),
            fullName: location.name,
            type: "city",
          }));
          locations = locations.concat(cities);
        }

        setAllLocations(locations);

        // Map favorite location IDs to full location data
        let favoriteLocationIds = [];
        if (user?.userFavoriteLocations) {
          // Handle both array of strings and array of objects formats
          favoriteLocationIds = Array.isArray(user.userFavoriteLocations)
            ? user.userFavoriteLocations.map(loc => typeof loc === 'string' ? loc : loc.locationId)
            : [];
        }

        // Fetch location statistics for each favorite location
        if (favoriteLocationIds.length > 0) {
          const locationDataPromises = favoriteLocationIds.map(locationId =>
            fetchLocationStatistics(locationId)
          );

          const locationDataResults = await Promise.all(locationDataPromises);

          const dataMap = {};
          locationDataResults.forEach((data, index) => {
            if (data) {
              dataMap[favoriteLocationIds[index]] = data;
            }
          });

          setLocationDataMap(dataMap);
        }

        const favoriteItems = favoriteLocationIds
          .map((locationId, index) => {
            const location = locations.find(loc => loc.id === locationId);
            return location ? {
              id: location.id,
              name: location.name,
              color: WATCHLIST_COLORS[index]
            } : null;
          })
          .filter(item => item !== null);

        setWatchlistItems(favoriteItems);
        setTempWatchlistItems(favoriteItems);
      } catch (error) {
        console.error("Error initializing dashboard:", error);
      } finally {
        setIsLoading(false);
      }
    };

    initializeData();
  }, [user]);

  const saveFavoriteLocations = async (locations) => {
    try {
      setIsSaving(true);
      // Only save the location IDs
      const locationIds = locations.map(item => item.id);

      await updateUser({
        favoriteLocationIds: locationIds
      });

      // Fetch data for any new locations that we don't have data for yet
      const newLocationPromises = locations.map(async (location) => {
        if (!locationDataMap[location.id]) {
          // Fetch data for this new location
          const data = await fetchLocationStatistics(location.id);
          return { id: location.id, data };
        }
        return null;
      });

      const newLocationResults = await Promise.all(newLocationPromises);

      // Update locationDataMap with any new data
      const updatedDataMap = { ...locationDataMap };
      newLocationResults.forEach(result => {
        if (result && result.data) {
          updatedDataMap[result.id] = result.data;
        }
      });

      setLocationDataMap(updatedDataMap);
      setWatchlistItems(locations);
      setIsModalOpen(false);
      setSearchTerm("");
      setSearchResults([]);
    } catch (error) {
      console.error("Error saving favorite locations:", error);
      alert("Failed to save watchlist changes. Please try again.");
    } finally {
      setIsSaving(false);
    }
  };

  const handleDelete = (id) => {
    // Update temporary state instead of making immediate API call
    setTempWatchlistItems(prevItems => {
      const newItems = prevItems.filter(item => item.id !== id)
        .map((item, i) => ({
          ...item,
          color: WATCHLIST_COLORS[i]
        }));
      return newItems;
    });
  };

  const handleEdit = (id, newName, newId) => {
    if (
      watchlistItems.some(
        (item) =>
          item.id !== id && item.name.toLowerCase() === newName.toLowerCase()
      )
    ) {
      alert(
        "This location is already in your watchlist. Please choose a different one."
      );
      return;
    }

    setWatchlistItems((prevItems) => {
      if (id === "new") {
        return [
          ...prevItems,
          {
            id: newId,
            name: newName,
            color: WATCHLIST_COLORS[prevItems.length],
          },
        ];
      } else {
        return prevItems.map((item) =>
          item.id === id
            ? { ...item, name: newName, id: newId || item.id }
            : item
        );
      }
    });
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
    const filtered = allLocations
      .filter(
        (location) =>
          location.name.toLowerCase().includes(term.toLowerCase()) &&
          !watchlistItems.some(
            (item) => item.name.toLowerCase() === location.name.toLowerCase()
          )
      )
      .slice(0, 4);
    setSearchResults(filtered);
  };

  const handleSelectLocation = (location) => {
    if (tempWatchlistItems.length >= 3) return;

    setTempWatchlistItems(prevItems => [
      ...prevItems,
      {
        id: location.id,
        name: location.name,
        color: WATCHLIST_COLORS[prevItems.length]
      }
    ]);

    setSearchTerm("");
    setSearchResults([]);
  };

  const handleOpenWatchlist = () => {
    // Initialize temporary state with current watchlist
    setTempWatchlistItems([...watchlistItems]);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSearchTerm("");
    setSearchResults([]);
    setTempWatchlistItems([]);  // Clear temporary state
  };

  const handleSaveWatchlist = async () => {
    await saveFavoriteLocations(tempWatchlistItems);
  };

  const WatchlistPreview = ({ items }) => {
    return (
      <div className="modal-watchlist-preview">
        {items.map((item) => (
          <div key={item.id} className="modal-preview-item">
            <WatchlistComponent
              id={item.id}
              name={item.name}
              color={item.color}
              onDelete={handleDelete}
              onEditClick={handleOpenWatchlist}
              existingNames={items.map((i) => i.name)}
              allLocations={allLocations}
              isPreview={true}
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="user-dashboard">
      <h1 className="user-dashboard__heading">
        <img
          src={dashboardIcon}
          alt="Dashboard"
          className="user-dashboard__icon"
        />
        Dashboard
      </h1>
      <div className="user-dashboard__content">
        <div className="user-dashboard__left-column">
          <div className="user-dashboard__watchlist-section">
            <h2 className="user-dashboard__section-heading">Watchlist</h2>
            <div className="user-dashboard__watchlist-container">
              {watchlistItems.length === 0 ? (
                <EmptyWatchlistBox onAdd={handleOpenWatchlist} />
              ) : (
                <>
                  {watchlistItems.map((item) => (
                    <WatchlistComponent
                      key={item.id}
                      id={item.id}
                      name={item.name}
                      color={item.color}
                      onDelete={handleDelete}
                      onEditClick={handleOpenWatchlist}
                      existingNames={watchlistItems.map((i) => i.name)}
                      allLocations={allLocations}
                      locationData={locationDataMap[item.id]}
                    />
                  ))}
                  {watchlistItems.length < 3 && (
                    <button
                      className="watchlist-add-btn"
                      onClick={handleOpenWatchlist}
                    >
                      <img src={addIcon} alt="Add to watchlist" />
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="user-dashboard__explore-create-section">
            <h2 className="user-dashboard__section-heading">Explore/Create</h2>
            <div className="user-dashboard__visit-other-products-container">
              <VisitOtherProducts productType="simpleSheets" />
              <VisitOtherProducts productType="customSheets" />
              <VisitOtherProducts productType="detailedResearch" />
            </div>
          </div>
        </div>
        <div className="user-dashboard__right-column">
          <div className="user-dashboard__top-brokerages-section">
            <h2 className="user-dashboard__section-heading">Top Brokerages</h2>
            <TopBrokeragesComponent />
          </div>
          <div className="user-dashboard__featured-data-insights-section">
            <h2 className="user-dashboard__section-heading">
              Featured Data Insights
            </h2>
            <FeaturedDataInsights />
          </div>
        </div>
      </div>

      {isModalOpen && (
        <>
          <div className="modal-overlay" onClick={handleCloseModal} />
          <div className="modal-container-watchlist">
            <div className="modal-header">
              <h2>Manage Watchlist</h2>
              <button className="modal-close-btn" onClick={handleCloseModal}>
                <FaTimes />
              </button>
            </div>
            <div className="modal-content-watchlist">
              <div
                className={`modal-add-section ${watchlistItems.length >= 3
                  ? "modal-add-section--disabled"
                  : ""
                  }`}
              >
                <h3>Add New Location</h3>
                <div className="modal-search">
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => handleSearch(e.target.value)}
                    placeholder="Search: city or neighborhood"
                    autoFocus
                    disabled={tempWatchlistItems.length >= 3}
                  />
                  <FaSearch className="modal-search-icon" />
                </div>

                {searchResults.length > 0 && (
                  <ul className="modal-search-results">
                    {searchResults.map((result) => (
                      <li
                        key={result.id}
                        onClick={() =>
                          tempWatchlistItems.length < 3
                            ? handleSelectLocation(result)
                            : null
                        }
                        className="modal-search-result-item"
                      >
                        <span className="location-name">{result.name}</span>
                        <span className="location-type">{result.type}</span>
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              {tempWatchlistItems.length >= 0 && (
                <div className="modal-current-watchlist">
                  <h3>Current Watchlist ({tempWatchlistItems.length}/3)</h3>
                  <WatchlistPreview items={tempWatchlistItems} />
                </div>
              )}

              <div className="modal-actions">
                <button
                  className="modal-save-btn"
                  onClick={handleSaveWatchlist}
                  disabled={isSaving}
                >
                  {isSaving ? 'Saving...' : 'Save'}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default UserDashboard;