// src/components/Navbar.js
import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { signOut } from '@aws-amplify/auth';
import { get_current_user, checkAuthStatus } from '../actions/auth';
import './Navbar.css';
import whiteLogo from '../assets/SVG-realanalytica-primary-logo-white.svg';
import blueLogo from '../assets/SVG-realanalytica-primary-logo-blue.svg';
import blueIcon from '../assets/SVG-realanalytica-icon-blue.svg';
import blueBetaLogo from '../assets/RA-logo-1-beta.svg';
import blueBetaIcon from '../assets/RA-logo-2-beta.svg'

const Navbar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isHamburgerVisible, setIsHamburgerVisible] = useState(false);

    const isAccountPage = location.pathname === '/account';
    const isDashboardPage = location.pathname === '/dashboard';
    const isSignupPage = location.pathname === '/signup';

    useEffect(() => {
        const checkAuth = async () => {
            try {
                const status = await checkAuthStatus();
                setIsAuthenticated(status.isAuthenticated);
            } catch (error) {
                setIsAuthenticated(false);
            }
        };

        checkAuth();
    }, [location.pathname]); // Re-check when route changes

    const logoutUser = async () => {
        try {
            await signOut();
            setIsAuthenticated(false);
            navigate('/');
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsHamburgerVisible(window.innerWidth <= 900);
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const guestLinks = () => (
        <>
            <Link to="/" onClick={toggleSidebar}>Home</Link>
            {/* <Link to="/about" onClick={toggleSidebar}>About</Link> */}
            <Link to="/blog" onClick={toggleSidebar}>Blog</Link>
            <Link to="/signup" className="signup-btn" onClick={toggleSidebar}>
                <strong>Sign Up</strong> - It's Free
            </Link>
            <Link to="/login" onClick={toggleSidebar}>Login</Link>
        </>
    );

    const authLinks = () => (
        <>
            <Link to="/" onClick={toggleSidebar}>Home</Link>
            <Link to="/account?tab=Dashboard" onClick={toggleSidebar}>Dashboard</Link>
            {/* <Link to="/about" onClick={toggleSidebar}>About</Link> */}
            <Link to="/blog" onClick={toggleSidebar}>Blog</Link>
            <Link to="/account?tab=Settings" onClick={toggleSidebar}>Account</Link>
            <Link to="/" onClick={logoutUser}>Logout</Link>
        </>
    );

    if (isDashboardPage) {
        return null;
    }

    return (
        <nav className={`navbar ${isAccountPage ? 'account-page' : ''} ${isSignupPage ? 'signup-page' : ''}`}>
            <div className="navbar-container">
                <div className="navbar-logo">
                    <Link to="/">
                        {isHamburgerVisible ? (
                            <img src={blueBetaIcon} alt="Hamburger Logo" className="logoIcon" />
                        ) : (
                            <img src={blueBetaLogo} alt="RealAnalytica Logo" className="logo" />
                        )}
                    </Link>
                </div>
                <div className={`navbar-links ${isSidebarOpen ? 'open' : ''}`}>
                    {isAuthenticated ? authLinks() : guestLinks()}
                </div>
                <div className="hamburger" onClick={toggleSidebar}>
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;