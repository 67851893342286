// src/utils/contentTransformer.js
import React from 'react';

const VALUATION_REMOVAL_SELECTORS = {
  dateControls: [
    '.date-filters',  // Add this class to the date filters Box in ValuationTab
    'button[size="small"]', // Date selector buttons
    '.MuiButton-root',  // MUI buttons
    '.DatePicker',  // Date picker components
    'input',  // Search input
  ],
  searchControls: [
    '.search-container', // Add this class to the search Box in ValuationTab
  ],
  chipControls: [
    '.city-chips'  // Add this class to the city chips Box in ValuationTab
  ]
};

const shouldRemoveElement = (element) => {
  const removalSelectors = [
    ...VALUATION_REMOVAL_SELECTORS.dateControls,
    ...VALUATION_REMOVAL_SELECTORS.searchControls,
    ...VALUATION_REMOVAL_SELECTORS.chipControls,
    '.customization-controls',
    '.search-bar',
    '.edit-button',
    '.settings-button',
    '.control-panel',
    '.filter-options',
    '.interactive-elements',
    'button:not(.essential-button)',
    'input[type="text"]',
    'input[type="search"]',
    '.dropdown-menu',
    '.toolbar'
  ];

  if (!element.props) return false;
  
  // Check className against removal selectors
  const classNames = (element.props.className || '').split(' ');
  const elementType = element.type?.muiName || element.type;

  // Check for MUI components and specific elements
  if (elementType === 'Button' || 
      elementType === 'TextField' || 
      elementType === 'DatePicker' ||
      elementType === 'Chip') {
    return true;
  }

  return removalSelectors.some(selector => {
    if (selector.startsWith('.')) {
      return classNames.includes(selector.substring(1));
    }
    return false;
  });
};

const shouldPreserveElement = (element) => {
  const preserveSelectors = [
    '.chart-container',
    '.LineChart',
    '.MuiLineChart-root',
    '.CustomLegend',
    '.legend-content'
  ];

  if (!element.props) return false;

  const classNames = (element.props.className || '').split(' ');
  return preserveSelectors.some(selector => 
    classNames.includes(selector.substring(1))
  );
};

const transformContentBox = (contentElement) => {
  if (!React.isValidElement(contentElement)) {
    return contentElement;
  }

  // Special handling for ValuationTab
  if (contentElement.type?.name === 'ValuationTab') {
    return React.cloneElement(contentElement, {
      ...contentElement.props,
      isMarketMode: true, // This will trigger the simplified render
      hideControls: true
    });
  }

  // If element should be removed and not preserved, don't include it
  if (shouldRemoveElement(contentElement) && !shouldPreserveElement(contentElement)) {
    return null;
  }

  // Filter children to remove unwanted elements while keeping the chart
  if (contentElement.props.children) {
    let children;
    if (Array.isArray(contentElement.props.children)) {
      children = contentElement.props.children
        .filter(child => {
          if (!React.isValidElement(child)) return true;
          return !shouldRemoveElement(child) || shouldPreserveElement(child);
        })
        .map(child => transformContentBox(child));
    } else {
      children = transformContentBox(contentElement.props.children);
    }
    
    return React.cloneElement(contentElement, {
      ...contentElement.props,
      children
    });
  }

  return contentElement;
};

export const cleanContentBoxForDisplay = (contentBox) => {
  // Create a clean version of the content box
  const cleanContent = transformContentBox(contentBox.content);
  
  return {
    ...contentBox,
    content: cleanContent,
    // Remove any interactive or customization properties
    interactiveFeatures: undefined,
    customizationOptions: undefined,
    controlStates: undefined,
  };
};